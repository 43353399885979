define('checkout/manager/TrackingManager',["sprd/manager/TrackingManager", "underscore", "checkout/manager/CustomTrackings"], function(TrackingManager, _, CustomTrackings) {

    return TrackingManager.inherit("checkout.manager.TrackingManager", {
        inject: {
            custom: CustomTrackings
        },

        handleUserAsGuest: function () {

        },

        handleUserAsRegisteredUser: function () {

        },

        trackStartApplication: function () {
            // event 60 for start loading of the main application
            // event 62 for start loading of the success application

            // omniture event is send from opossum
        },

        trackApplicationError: function (err, stack) {
            // TODO: send this error to raygun
        },

        /***
         * @param startTime - in milliseconds
         */
        trackMainApplicationStarted: function (startTime) {
            // event 61
            // prop50: send time as 0.5 seconds
        },

        trackStartApplicationError: function (err) {
            // eVar35

            // Checkout2:Loading:UnknownError
            // Checkout2:Loading:BasketNotAvailable
        },

        trackTiming: function (what, time) {
            // prop50: send time as 0.5 seconds
        },


        trackCheckoutStep: function(application, step, root) {

            var user = root && root.$.user;

            this.$.custom.track("checkoutStep", {
                step: step,
                application: application,
                basket: this._getBasketPayload(),
                newsletter: root ? root.$.newsletter : undefined,
                paymentType: this.getPaymentTypeObject(root),
                user: user ? {
                    id: user.$.id
                } : null
            });

        },

        getPaymentTypeObject: function(root) {

            if (!root) {
                return;
            }

            var paymentType = this.get(root, "checkout.paymentType");

            if (!paymentType) {
                return;
            }

            return {
                category: paymentType.$.categoryCode,
                id: paymentType.$.id,
                method: paymentType.$.methodCode
            };

        },

        trackCompleteShippingStep: function () {
        },
        /**
         *
         * @param valid
         * @param couponCode
         */
        trackCouponApply: function (valid, couponCode) {

        },

        trackCompletePaymentStep: function () {

        },

        trackBuy: function (root, repayment, callback) {
            callback && callback();
        },

        trackFeature: function(featureName, data) {
            this.$.custom.track(featureName, data);
        },

        /***
         *
         * @param startTime
         * @param order
         */
        trackCheckoutSuccess: function (startTime, order) {
            var orderPayload = this._getOrderPayload(order);

            const encoder = new TextEncoder();
            const promise = crypto.subtle.digest("SHA-256", encoder.encode(order.$.email));

            promise.then(buffer => {
                const hashArray = Array.from(new Uint8Array(buffer));
                const hashedEmail = hashArray
                    .map((b) => b.toString(16).padStart(2, "0"))
                    .join("");

                this.$.custom.track("orderSuccess", {
                    order: orderPayload,
                    details: this.PARAMETER().order || {},
                    hashedEmail,
                    email: order.$.email,
                    paymentType: this.PARAMETER().paymentType || {}
                });
            }).catch(err => {
                console.warn(err);
                this.$.custom.track("orderSuccess", {
                    order: orderPayload,
                    details: this.PARAMETER().order || {},
                    email: order.$.email,
                    paymentType: this.PARAMETER().paymentType || {}
                });
            })
        },

        trackError: function (category, label, information) {
        },

        trackFirstClick: function () {
        },

        trackEmptyBasket: function (basket) {
        },

        getLocaleInOmnitureFormat: function () {
            return (this.PARAMETER().locale || "").replace("_", " - ");
        },

        trackUncheckAutoCompletion: function() {
        },

        trackBasketChange: function() {
            this.$.custom.track("basketChange", {
                basket: this._getBasketPayload()
            });
        },

        _getBasketPayload: function() {
            var basket = this.get("basket"),
                api    = this.$.api;
            if (api && basket) {
                var basketPayload = api.getProcessorForModel(basket).compose(basket);

                delete basketPayload.checkout;
                delete basketPayload.delivery;

                return basketPayload;

            }
        },

        _getOrderPayload: function(order) {

            var api = this.$.api;
            if (api && order) {
                var orderPayload = api.getProcessorForModel(order).compose(order) || {};

                orderPayload.orderItems = [];

                var orderItems = order.$.orderItems;
                orderItems && orderItems.each(function(orderItem) {
                    var item = api.getProcessorForModel(orderItem).compose(orderItem);
                    item.sellableId = orderItem.$.sellableId;
                    item.ideaId = orderItem.$.ideaId;
                    orderPayload.orderItems.push(item);
                });


                function sanitizeAddress(deliver) {
                    if (deliver && deliver.address) {
                        var address = deliver.address;

                        deliver.address = {
                            country: deliver.address.country
                        };

                        if (address.person) {
                            deliver.address.person = {
                                salutation: address.person.salutation
                            }
                        }
                    }
                }

                delete orderPayload.checkout;
                delete orderPayload.user;


                sanitizeAddress(orderPayload.billing);
                sanitizeAddress(orderPayload.shipping);

                return orderPayload;

            }

        }

        // TODO:

        // time for register a user
        // time for get basket from api
        // time fro payment methods from api

    });

});
