define('checkout/view/ZoomClass',["js/ui/View", "underscore"], function(View, _) {
    return View.inherit({
        defaults: {
            componentClass: "zoom {loadedClass()}",

            posX: 0,
            posY: 0,
            posWidth: 100,
            posHeight: 100,

            zoomContainer: null,

            zoomImageUrl: null,

            loading: false

        },

        events: ['on:up', 'on:down'],

        $over: false,

        loadedClass: function() {
            return this.$.loading ? "loading" : "";
        }.onChange("loading"),

        $defaultContentName: "content",

        _down: function(e) {

            e.preventDefault();
            if (!this.$over) {
                this._startZoom();
            }

            this.trigger("on:down")
        },

        _up: function(e) {

            e.preventDefault();

            if (!this.$over) {
                this._stopZoom();
            }
            
            this.trigger("on:up");
        },

        _move: function(e) {
            e.preventDefault();

            var rect = this.rect,
                image = this.image;

            if (!(rect && image && e)) {
                return;
            }

            var posWidth = this.$.posWidth,
                posHeight = this.$.posHeight,
                cx = (e.domEvent.changedTouches ? e.domEvent.changedTouches[0].clientX : e.domEvent.clientX) - rect.left,
                cy = (e.domEvent.changedTouches ? e.domEvent.changedTouches[0].clientY : e.domEvent.clientY) - rect.top,
                x = Math.min(Math.max(0, cx - posWidth / 2), rect.width - posWidth),
                y = Math.min(Math.max(0, cy - posHeight / 2), rect.height - posHeight),
                transX = x / rect.width * image.naturalWidth,
                transY = y / rect.height * image.naturalHeight;

            this.set({
                posX: x,
                posY: y
            });

            _.forEach(["", "-webkit-", "-moz-", "-ms-"], function(key) {
                image.style[key + "transform"] = "translate(-" + transX + "px, -" + transY + "px)";

            });

        },

        _over: function() {
            this.$over = true;
            this._startZoom();
        },

        _startZoom: function() {

            var zoomContainer = this.$.zoomContainer,
                self = this;


            if (!zoomContainer) {
                return;
            }

            zoomContainer.set("selected", false);

            var url = this.$.zoomImageUrl;

            if (url) {
                var img = new Image();

                zoomContainer.zoom = this;
                this.image = img;

                self.set({
                    loading: true,
                    selected: true
                });

                zoomContainer.set("selected", true);
                zoomContainer.addClass("loading");

                img.onload = function() {
                    if (zoomContainer.zoom == self) {

                        zoomContainer.removeClass("loading");
                        self.set("loading", false);

                        self.removeChildrenFromContainer();

                        zoomContainer.$el.appendChild(img);

                        var container = zoomContainer.rect = zoomContainer.$el.getBoundingClientRect();
                        var me = self.rect = self.$el.getBoundingClientRect();

                        var body = document.body.getBoundingClientRect();

                        zoomContainer.$el.style.top = ((window.innerHeight / 2) - (container.height / 2) - body.top - 200) + "px";

                        self.set({
                            posWidth: container.width / img.naturalWidth * me.width,
                            posHeight: container.height / img.naturalHeight * me.height
                        });
                    }
                };
                img.onerror = function() {
                    self._out();
                };

                img.src = url;
            }
        },

        removeChildrenFromContainer: function() {

            var zoomContainer = this.$.zoomContainer;

            if (!(zoomContainer && zoomContainer.$el)) {
                return;
            }

            while (zoomContainer.$el.childNodes.length) {
                zoomContainer.$el.removeChild(zoomContainer.$el.childNodes[0]);
            }
        },

        _out: function() {
            this.$over = false;
            this._stopZoom();
        },

        _stopZoom: function() {

            var zoomContainer = this.$.zoomContainer;
            if (zoomContainer && zoomContainer.zoom == this)  {
                this.removeChildrenFromContainer();
                zoomContainer.set("selected", false);
                zoomContainer.removeClass("loading");

                zoomContainer.zoom = null;
            }

            this.set({
                loading: false,
                selected: false
            });
        }
    });
});
