define('checkout/manager/AdyenManager',[
    "js/core/Component",
    "flow",
    "checkout/manager/PaymentTypeManager",
    "xaml!sprd/data/SprdApiDataSource",
    "checkout/manager/UrlManager",
    "checkout/manager/PurchaseManager",
    "sprd/model/AdyenWebComponentPayment",
    "sprd/model/PendingPayment",
    "underscore",
    "require",
    "js/core/Bus",
    "checkout/manager/FeatureManager"
], function (Component, flow, PaymentTypeManager, SprdApiDataSource, UrlManager, PurchaseManager, AdyenWebComponentPayment, PendingPayment, _, require, Bus, FeatureManager) {
    var AdyenWebComponent = Component.inherit({
        defaults: {
            checkout: null,
            uiElement: null,
            paymentComponent: null,
            pendingPaymentId: null,
            repayment: false
        },

        setPaymentComponent: function (paymentComponent) {
            this.set("paymentComponent", paymentComponent);
        },

        setPendingPaymentId: function (pendingPaymentId) {
            this.set("pendingPaymentId", pendingPaymentId);
        }
    });

    return Component.inherit("checkout.manager.AdyenManager", {

        defaults: {
            basket: "{root.basket}"
        },

        inject: {
            paymentTypeManager: PaymentTypeManager,
            api: SprdApiDataSource,
            urlManager: UrlManager,
            purchaseManager: PurchaseManager,
            bus: Bus,
            featureManager: FeatureManager
        },

        initializeWebComponent: function (payment, baseConfig, options, mountId, loadingCallback, handleCreditCardCallback) {
            var self = this;
            var webComponentConfiguration = payment.getWebComponentConfiguration();
            if (!webComponentConfiguration) {
                return;
            }
            var component = new AdyenWebComponent();
            var config = self.getConfig(payment, component, baseConfig, handleCreditCardCallback);

            flow()
                .seq(function (cb) {
                    require(["checkout/lib/adyen"], function () {
                        cb();
                    }, cb);
                })
                .seq("adyen", function (cb) {
                    AdyenCheckout(config).then(function (result) {
                        cb(null, result);
                    });
                })
                .seq("init", function (cb) {
                    var checkout = this.vars.adyen;
                    var uiElement = checkout.create(webComponentConfiguration.$, options);
                    component.set({
                        checkout: checkout,
                        uiElement: uiElement
                    });
                    uiElement.mount(mountId);
                    self.set(payment.$.paymentType.$.categoryCode.toLowerCase(), component);
                    cb();
                })
                .exec(function (err, results) {
                    if (err) {
                        console.log(err);
                    }
                });
        },

        updateComponent: function (payment) {
            var currentComponent = this.getComponent(payment);
            if (currentComponent && currentComponent.$.checkout) {
                currentComponent.$.uiElement.update();
            }
        },

        getConfig: function (payment, component, baseConfig, handleCreditCardCallback) {
            var self = this;
            var handleSubmit = function (state, paymentComponent) {
                self.handleSubmit(state, paymentComponent, payment, handleCreditCardCallback);
            };

            var handleAdditionalDetails = function (state, paymentComponent) {
                self.handleAdditionalDetails(state, paymentComponent, component);
            };

            var handleCancel = function () {
                self.handleCancel(component);
            };

            var handleError = function (error, paymentComponent) {
                if (error.name === "CANCEL") {
                    _.defer(handleCancel);
                } else {
                    self.handleError(error, paymentComponent);
                }
            };

            var adyenConfig = this.PARAMETER().adyen;
            var isoLocale = this.PARAMETER().isoLocale;

            return Object.assign({}, baseConfig, {
                clientKey: adyenConfig.clientKey,
                environment: adyenConfig.environment,
                locale: isoLocale && isoLocale.replace("nb", "no"),
                onError: handleError,
                onCancel: handleCancel,
                onAdditionalDetails: handleAdditionalDetails,
                onSubmit: handleSubmit
            });
        },

        submit: function (payment) {
            var component = this.getComponent(payment),
                uiElement = component && component.$.uiElement;

            uiElement && uiElement.submit !== undefined && uiElement.submit();
        },

        validate: function (payment) {
            var component = this.getComponent(payment),
                uiElement = component && component.$.uiElement,
                isValid = true;

            if (uiElement) {
                if (uiElement.isValid !== undefined) {
                    isValid = uiElement.isValid;
                }

                var componentRef = uiElement.componentRef;
                if (!isValid && componentRef && componentRef.showValidation) {
                    componentRef.showValidation();
                }
            }

            return isValid;
        },

        handleCancel: function (component) {
            var self = this;
            flow()
                .seq("cancellationResult", function (cb) {
                    var pendingPayment = self.$.api.createEntity(PendingPayment, component.$.pendingPaymentId);
                    var cancelPayment = pendingPayment.createEntity(PendingPayment.CancelPendingPayment);
                    cancelPayment.save(null, function (err, result) {
                        cb(null, self.extractResult(result, err, PendingPayment.Result));
                    });
                })
                .seq(function (cb) {
                    self.handleResponse(this.vars.cancellationResult, component);
                    cb();
                })
                .exec(function (err) {
                    if (err) {
                        console.log(err);
                    }
                });
        },

        handleError: function (error, component) {
            console.error(error.name, error.message, error.stack, component);
        },

        getComponent: function (payment) {
            return this.get(payment.$.paymentType.$.categoryCode.toLowerCase());
        },

        handleSubmit: function (state, paymentComponent, payment, handleCreditCardCallback) {
            if (state.isValid) {

                var self = this,
                    component = this.getComponent(payment),
                    repayment = this.$.repayment,
                    initialSession = self.$.api.session,
                    paymentType = payment.$.paymentType;

                component.setPaymentComponent(paymentComponent);
                self.$.bus.trigger('Application.ShowLoader');

                flow()
                    .seq(function (cb) {
                        self.$.purchaseManager.saveAddress(repayment, true, cb);
                    })
                    .seq("session", function (cb) {
                        self.$.purchaseManager.createSession(repayment, cb);
                    })
                    .seq(function () {
                        self.$.purchaseManager.saveSession(this.vars.session);
                    })
                    .seq("initiatePaymentResult", function (cb) {
                        self.initiatePayment(paymentType, state, cb);
                    })
                    .seq(function (cb) {
                        var initiatePaymentResult = this.vars.initiatePaymentResult;
                        component.setPendingPaymentId(initiatePaymentResult.$.pendingPaymentId);
                        self.handleResponse(initiatePaymentResult, component, handleCreditCardCallback);
                        cb();
                    })
                    .exec(function (err, results) {
                        if (err) {
                            console.log(err);
                        }
                        self.$.bus.trigger('Application.HideLoader');
                        self.$.api.$.session = initialSession;
                    });
            }
        },

        initiatePayment: function (paymentType, state, cb) {
            var returnUrl = this.$.urlManager.createReturnUrl(this.$.basket, paymentType);

            var initiatePayment = this.$.basket.createEntity(AdyenWebComponentPayment.InitiatePayment);
            initiatePayment.set({
                data: Object.assign({}, state.data, {
                    origin: window.location.origin
                }),
                paymentTypeId: paymentType.$.id,
                returnUrl: returnUrl
            });

            var self = this;
            initiatePayment.save({
                headers: {
                    'X-A38': self.$.featureManager.$.featureA38 ? btoa('A38') : btoa('A39')
                }
            }, function (err, result) {
                cb(null, self.extractResult(result, err, AdyenWebComponentPayment.InitiatePaymentResult));
            });
        },

        handleAdditionalDetails: function (state, paymentComponent, component) {
            component.setPaymentComponent(paymentComponent);

            var self = this, api = self.$.api;
            flow()
                .seq("additionalDetailsResult", function (cb) {
                    var additionalDetails = api.createEntity(AdyenWebComponentPayment.AdditonalDetails),
                        pendingPaymentId = component.$.pendingPaymentId;

                    additionalDetails.set({data: state.data, pendingPaymentId: pendingPaymentId});

                    additionalDetails.save(null, function (err, result) {
                        cb(null, self.extractResult(result, err, AdyenWebComponentPayment.PaymentResult));
                    });
                })
                .seq(function (cb) {
                    self.handleResponse(this.vars.additionalDetailsResult, component);
                    cb();
                })
                .exec(function (err, result) {
                    if (err) {
                        console.log(err);
                    }
                });
        },

        extractResult: function (result, err, type) {
            var data = result;

            if (err && err.xhr && err.xhr.responses) {
                var parsedData = JSON.parse(err.xhr.responses.text);
                data = this.$.api.createEntity(type);
                data.set(parsedData);
            }

            return data;
        },

        handleResponse: function (result, component, handleCreditCardCallback) {
            var action = result.$.action;
            if (action) {
                if (action.paymentMethodType === "scheme") {
                    handleCreditCardCallback && handleCreditCardCallback(component, action);
                } else {
                    component.$.paymentComponent.handleAction(action);
                }
            } else if (result.$.redirectUrl) {
                try {
                    var url = new URL(result.$.redirectUrl);
                    var params = new URLSearchParams(url.search);

                    params.append("f38", this.$.featureManager.$.featureA38 ? btoa('A38') : btoa('A39'));
                    url.search = '?' + params.toString();

                    window.top.location = url.toString();
                } catch (e) {
                    console.warn("Failed to create redirect url", e);
                    window.top.location = result.$.redirectUrl;
                }
            }
        }
    });
});
