define('checkout/view/ViewBase',["js/ui/View", "js/core/I18n"], function (View, I18n) {
    return View.inherit("checkout.view.ViewBase", {

        inject: {
            i18n: I18n
        },

        inputType: function (what) {
            what = what || "text";
            return this.$stage.$browser.hasTouch ? what : "text";
        }

    });
});
