define('checkout/view/SpreadshirtCheckoutClass',["checkout/view/CheckoutViewBase", "sprd/lib/ScrollIntoView", "flow", "sprd/model/UserAddress", "js/error/ValidationError", "js/data/validator/Validator", "underscore"], function (CheckoutViewBase, ScrollIntoView, flow, UserAddress, ValidationError, Validator, _) {

    var undefined;

    return CheckoutViewBase.inherit("checkout.view.SpreadshirtCheckoutClass", {

        name: "spreadshirt",

        stepIds: ["checkout", "invoice", "review"],

        defaults: {
            selectedShippingType: "{delivery.shipping.type}",
            paymentNeeded: true,
            componentClass: "spreadshirt-checkout",
            sessionTimeoutError: false,
            review: null,
            paymentTypesAvailable: true,

            showChallengeContainer: false,

            /**
             * @codeBehind
             */
            billingAddress: null,

            /**
             * @codeBehind
             */
            billingAddressView: null
        },

        ctor: function () {
            this.callBase();
            this.bind("basketManager", "on:basketUpdated", this._checkPaymentNeeded, this);
            this.bind("basketManager", "on:basketUpdated", function () {
                this.loadPaymentTypes();
            }, this);
            this.bind("basketManager", "on:basketInitialized", this._checkPaymentNeeded, this);
        },

        basketOrder: function () {
            return this.$.order || this.$.basket;
        }.onChange("order", "basket"),

        goToStep: function (step) {
            if (step == 0 && this.$.repayment) {
                // not allowed to jump to first step
                return;
            }

            this.callBase();
        },

        _commitUser: function (user) {
            if (user) {
                this.$.userAddressManager.set({
                    user: user,
                    delivery: this.$.delivery
                });
                var self = this;
                this.set('loadingAddresses', true);
                this.$.userAddressManager.loadUserAddressesAndSetDeliveryAddress(function () {
                    self.set('loadingAddresses', false);
                });
            }
        },

        performNextAction: function (e, toStep) {

            if (toStep == this.$.currentStep + 1) {
                // the click was a valid next step click

                switch (toStep) {
                    case 1:
                        this.completeShippingStep(e);
                        break;
                    case 2:
                        var paymentTypeManager = this.$.paymentTypeManager,
                            paymentType = this.$.root.$.paymentType;

                        if (paymentTypeManager && paymentType) {
                            var payment = paymentTypeManager.getPaymentForPaymentType(paymentType);

                            if (payment) {
                                if (!payment.$.isWebComponentPayment) {
                                    this.completePaymentStep(e, payment);
                                }
                            }
                        }
                }

            }

        },


        _load: function (callback) {

            var self = this,
                parameter = this.PARAMETER(),
                paymentTypeId = this.getInitialPaymentTypeId();

            this._checkPaymentNeeded();

            // clear selected payment id
            this.clearPaymentId();

            this.set('loadingAddresses', true);

            flow()
                .seq(function () {
                    self.initializeSession(function (err, results) {
                        var user = self.get('root.user');
                        if (user && !user.$.guest) {
                            self.$.userAddressManager.set({
                                user: user,
                                delivery: self.$.delivery
                            });
                            self.$.userAddressManager.loadUserAddressesAndSetDeliveryAddress(function () {
                                self.set('loadingAddresses', false);
                            });
                        } else {
                            self.set('loadingAddresses', false);

                            if (parameter.email && parameter.token) {
                                // email and token deeplinked from bambam
                                self.$.delivery.set('email', self.$.delivery.$.email || parameter.email);

                                if (!paymentTypeId && !err && results && results.delivery && results.delivery.$.loaded == true) {
                                    self.completeShippingStep();
                                }
                            }
                        }
                    });
                })
                .seq(function (cb) {
                    self.loadAndSelectPaymentType(paymentTypeId, cb);
                })
                .seq(function () {
                    self.handlePaymentError(paymentTypeId, 1);
                })
                .exec(callback);

        },

        loadAndSelectPaymentType: function (paymentTypeId, cb) {
            if (paymentTypeId) {
                // select payment type id
                // fill payment data
                var self = this;
                this.loadPaymentTypes(function () {
                    var paymentType = self.$.root.$.paymentTypes.find(function (paymentType) {
                        return paymentTypeId === paymentType.$.id;
                    });
                    if (paymentType) {
                        self.$.root.set('paymentType', paymentType);
                    }

                    self.initFingerPrint();

                    cb && cb();
                });
            } else {
                cb && cb();
            }
        },

        _checkPaymentNeeded: function () {
            var basket = this.$.basket;
            if (basket) {
                var paymentNeeded = basket.totalVatIncluded() > 0;
                this.set('paymentNeeded', paymentNeeded);
                // we are in payment step and no payment is needed
                if (!paymentNeeded && this.$.currentStep === 1) {
                    this.gotoToReviewStep();
                    return;
                }

                if (paymentNeeded && this.$.currentStep === 2 && !this.get('checkout.paymentType')) {
                    // no payment selected
                    this.gotoPaymentStep();
                }
            }
        },

        _commitPaymentNeeded: function (paymentNeeded) {
            if (!paymentNeeded && this.$.checkout) {
                this.$.checkout.set({
                    payment: null,
                    paymentType: null
                });
            }

        },

        gotoToReviewStep: function (cb) {
            var self = this;
            flow()
                .seq(function (cb) {
                    self.loadBasketSummary(cb);
                })
                .exec(function () {
                    self.initFingerPrint();
                    self.set('currentStep', 2);

                    cb && cb();
                });
        },

        showChallengeContainer: function (event) {
            this.set("showChallengeContainer", true);

            var callback = event.$;
            callback && callback();
        },

        gotoPaymentStep: function (cb) {
            var self = this;
            flow()
                .seq(function (cb) {
                    self.loadPaymentTypes(cb);
                })
                .exec(function () {
                    self.set('currentStep', 1);

                    cb && cb();
                });
        },

        // todo: not sure if still needed
        initFingerPrint: function () {
            // initialize the finger print detection of adyen
            var fingerPrintManager = this.$.fingerPrintManager;
            fingerPrintManager && fingerPrintManager.init();
        },

        _commitSelectedShippingType: function (shippingType, oldShippingType) {
            if (shippingType && oldShippingType) {
                // here we have a change of shipping type
                // so try to update the delivery and then update basket
                var delivery = this.$.delivery,
                    basketManager = this.$.basketManager,
                    user = this.$.user,
                    self = this;
                flow()
                    .seq(function (cb) {
                        var fields = ["shipping", "phone", "giftWrappingMessage", "useGiftWrapping"];
                        if (!delivery.$.invoiceToShippingAddress) {
                            fields.push("billing");
                        }
                        if (!user) {
                            fields.push("email");
                        }
                        delivery.validate({
                            setErrors: false,
                            fields: fields
                        }, function (err, errors) {
                            cb(!errors ? null : "not valid");
                        });
                    })
                    .seq(function (cb) {
                        basketManager.waitForCouponApply(cb);
                    })
                    .seq(function (cb) {
                        delivery.save(null, cb);
                    })
                    .seq(function () {
                        self.saveEmailAddressForBasket(delivery.$.email, self.$.basket);
                    })
                    .seq(function () {
                        basketManager.reloadBasket();
                    })
                    .exec();
            }
        },

        showAddressBook: function (type, additionalAttributes, callback) {
            var top = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop,
                self = this,
                scrollTime = this.$.featureManager.$.scrollTime,
                attributes = {
                    closable: true,
                    top: top + 10,
                    user: self.get('root.user'),
                    addressType: type,
                    delivery: self.$.delivery,
                    countries: self.get('root').shippingCountries(),
                    newAddress: type == "billing"
                };

            _.defaults(attributes, additionalAttributes);

            flow()
                .seq("dialog", function (cb) {
                    if (self.$.addressBookDialog) {
                        self.$.addressBookDialog.set(attributes);
                        cb(null, self.$.addressBookDialog);
                    } else {
                        self.$.windowManager.loadWindow("xaml!checkout/dialog/AddressBookDialog", attributes,
                            function (err, window) {
                                self.set('addressBookDialog', window);
                                cb(err, window);
                            });
                    }
                })
                .exec(function (err, results) {
                    if (err) {
                        callback && callback(err);
                        return;
                    }

                    var dialog = results.dialog;
                    dialog.bind("dom:add", function (a, el) {

                        if (el && el.$el) {

                            ScrollIntoView(el.$el, -100, scrollTime);
                        }

                    });

                    dialog.showModal(function (err, dialog, state) {
                        callback && callback(err, state);
                    });
                });

        },

        saveDeliveryAndUpdateBasket: function (callback, webPaymentApi) {

            var self = this,
                delivery = this.$.delivery;

            var validateFields = ['shipping', 'giftWrappingMessage', 'phone'];
            if (!this.$.user && !webPaymentApi) {
                validateFields.push('email');
            }
            if (delivery && !delivery.$.invoiceToShippingAddress && !webPaymentApi) {
                validateFields.push("billing");
            }

            flow()
                .seq(function (cb) {
                    self.$.basketManager.waitForCouponApply(cb);
                })
                .seq('digital products', function (cb) {
                    if (self.PARAMETER().platform === 'EU') {
                        cb();
                        return;  
                    }
                    var billingAddress = delivery.invoiceAddress();
                    var deliveryAddressInUs = delivery.$.shipping.$.address.$.country === "US" || billingAddress.$.country.$.code === "US";
                    self.$.basket.$.basketItems.$items.forEach(function (basketItem) {
                        var productType = basketItem.$.element.$.product.$.productType;

                        if ((productType.$.id === "2482" || productType.$.id === "2483" || productType.$.id === "2484" || productType.$.id === "2485") && !deliveryAddressInUs) {
                            cb(new Error("digitalProductsNotAllowed"));
                        }
                    });
                    cb();
                })
                .seq("delivery", function (cb) {

                    delivery.clearErrors();
                    delivery.invoiceAddress().clearErrors();
                    delivery.validateAndSave({fields: validateFields}, cb);
                })
                .seq(function () {
                    if (!delivery.$.email) {
                        return;
                    }
                    self.saveEmailAddressForBasket(delivery.$.email, self.$.basket);
                })
                .seq(function (cb) {
                    self.$.basketManager.reloadBasket(cb);
                })
                .exec(callback);
        },

        completeShippingStep: function (e) {
            e && e.preventDefault();

            var self = this,
                bus = this.$.bus,
                delivery = this.$.delivery,
                trackingManager = this.$.trackingManager;

            bus.trigger('Application.ShowLoader');

            flow()
                .seq(function (cb) {
                    self.initializeSession(cb);
                })
                .seq(function () {

                    self.saveDeliveryAndUpdateBasket(function (err) {
                        bus.trigger('Application.HideLoader');

                        if (err) {
                            console.error(err);

                            if (err.message && err.message === "digitalProductsNotAllowed") {
                                self.$.notificationManager.showNotification("notification", {
                                    message: self.$.i18n.t('digitalProducts.notAllowed')
                                });
                            }

                            // if user is logged in, has addresses and there is a validation error
                            // open address book and show error there
                            else if (err instanceof ValidationError && self.isUserLoggedInAndHasAddresses()) {
                                var type = delivery.errors().get('shipping') ? "shipping" : "billing";

                                if (!delivery.errors().get('phone')) {
                                    self.showAddressBook(type, {}, function (err, state) {
                                        if (!err) {
                                            if (state instanceof UserAddress) {
                                                self.completeShippingStep();
                                            }
                                        } else {
                                            bus.trigger("Application.Error", err);
                                        }
                                    });
                                }
                            } else if (err.xhr && err.xhr.status == 400 && err.xhr.responses) {
                                var response = JSON.parse(err.xhr.responses.text);
                                if (response.error == "087019" || response.error == "087018") {
                                    // if logged in
                                    var validationError = new Validator.Error({
                                        code: "vatIdError",
                                        field: "vatId"
                                    });
                                    if (self.$.user) {
                                        //addressBookDialog.set('validationError', validationError);
                                        self.showAddressBook(delivery.$.invoiceToShippingAddress ? "shipping" : "billing", {
                                            validationError: validationError
                                        }, function (err, state) {
                                            if (err) {
                                                bus.trigger("Application.Error", err);
                                                return;
                                            }

                                            self.$.addressBookDialog.set({'validationError': null});

                                            if (state instanceof UserAddress) {
                                                self.completeShippingStep();
                                            }
                                        });
                                    } else {
                                        var billingAddress = delivery.invoiceAddress();
                                        billingAddress._setError('vatId', validationError);
                                        bus.trigger("Checkout.ScrollToInvalidField");
                                    }
                                    return;
                                }
                            }

                            trackingManager.trackError();
                            bus.trigger("Checkout.ScrollToInvalidField");

                            if (err instanceof ValidationError) {
                                bus.trigger("Checkout.ScrollToInvalidField");
                            } else if (!err.message || err.message !== "digitalProductsNotAllowed") {
                                bus.trigger("Application.Error", err);
                            }

                            if (!(err instanceof ValidationError) && (!err.message || err.message !== "digitalProductsNotAllowed")) {
                                self.$.bus.trigger("Application.Error", err);
                            }
                        } else {

                            if (self.get("root.newsletter") === true) {
                                // newsletter activated, track basket for bambam
                                self.trackBasket();
                            }

                            trackingManager.trackCompleteShippingStep();
                            if (self.$.paymentNeeded) {
                                self.gotoPaymentStep();
                            } else {
                                self.gotoToReviewStep();
                            }
                        }
                    });
                })
                .exec(function (err) {
                    err && console.error(err);
                });


        },

        handleCheckoutResult: function (checkoutResult, callback) {

            if (!checkoutResult) {
                return callback && callback();
            }

            if (checkoutResult.$.redirectUrl) {
                window.top.location = checkoutResult.$.redirectUrl;
                return callback && callback();
            }
        },


        completePaymentStep: function (e, payment) {
            e && e.preventDefault();

            var self = this,
                bus = this.$.bus,
                paymentTypeManager = this.$.paymentTypeManager,
                paymentType = payment.$.paymentType,
                trackingManager = this.$.trackingManager,
                adyenManager = this.$.adyenManager;

            bus.trigger('Application.ShowLoader');

            flow()
                .seq(function (cb) {
                    if (payment.$.isWebComponentPayment) {
                        var isValid = adyenManager.validate(payment);
                        if (!isValid) {
                            throw new ValidationError("Payment is not valid");
                        }
                        cb();
                    } else {
                        payment.validate(null, cb);
                    }
                })
                .seq(function () {
                    if (!payment.isValid()) {
                        throw new ValidationError("Payment is not valid!", "INVALID_MODEL", "", payment);
                    }
                })
                .seq(function () {
                    self.$.checkout.set("paymentType", paymentType);

                    if (!paymentType) {
                        throw new Error("Cannot get payment type");
                    } else {
                        self.savePaymentTypeIdForBasketId(paymentType.get('id'), self.get('basket.id'));
                    }
                })
                .exec(function (err) {
                    if (err) {
                        bus.trigger('Application.HideLoader');
                        bus.trigger("Checkout.ScrollToInvalidField");

                        if (!(err instanceof ValidationError)) {
                            self.$.bus.trigger("Application.Error", err);
                        }
                    } else {

                        trackingManager.trackCompletePaymentStep();

                        var paymentSummary = self.$.paymentSummary;
                        paymentSummary.clear();
                        paymentSummary._load(paymentTypeManager.getPaymentSummaryFqClassNameForCategory(paymentType));

                        self.$.checkout.set({
                            paymentType: paymentType,
                            payment: payment
                        });

                        self.gotoToReviewStep(
                            function () {
                                bus.trigger('Application.HideLoader');
                            }
                        );
                    }
                });

        },

        loadBasketSummary: function (cb) {
            this.$.review.refreshBasketSummary(cb);
        },

        loadPaymentTypes: function (callback) {
            this.synchronizeFunctionCall(this._loadPaymentTypes, "paymentTypes", callback, this, true);
        },

        _loadPaymentTypes: function (callback) {


            var self = this;

            this.set({
                loadingPaymentTypes: true,
                paymentTypesAvailable: true
            });

            var object = self.$.basket;

            if (this.$.repayment) {
                object = this.$.order;
            }

            flow()
                .seq("paymentTypes", function (cb) {

                    var paymentTypes = object.getCollection("paymentTypes");
                    paymentTypes.invalidatePageCache();
                    paymentTypes.fetch({
                        fullData: true,
                        noCache: true
                    }, cb);
                })
                .seq(function (cb) {
                    // force trigger change
                    self.$.root.setPaymentTypes(this.vars.paymentTypes.$items, cb);
                })
                .exec(function (err, results) {

                    self.set({
                        loadingPaymentTypes: false,
                        paymentTypesAvailable: !err && results.paymentTypes.length > 0
                    });

                    callback && callback(err);
                });

        },

        editShippingType: function () {
            this.goToStep(0);
            ScrollIntoView("shipping", 0, this.$.featureManager.$.scrollTime);
        },

        sendInvoiceToBillingAddress: function () {
            var delivery = this.$.delivery;
            delivery.set('invoiceToShippingAddress', false);
        },

        checkoutAvailable: function () {
            return !(this.PARAMETER().checkoutAvailable === false);
        },

        sendInvoiceToShippingAddress: function () {
            var delivery = this.$.delivery;
            delivery.set('invoiceToShippingAddress', true);
        },

        and: function (a, b) {
            return a && b;
        },

        scrollToBilling: function () {
            var billingAddress = this.$.billingAddress;
            var billingAddressView = this.$.billingAddressView;
            var delivery = this.$.delivery;
            if (delivery) {
                delivery.validate();
                if (billingAddress && billingAddress.$el && billingAddressView) {
                    ScrollIntoView(billingAddress.$el, -50, this.$.featureManager.$.scrollTime, function () {
                        billingAddressView.focusFirstname();
                    });
                }
            }
        },

        isUserLoggedInAndHasAddresses: function () {
            return !!this.get('user') && this.get('user.addresses.$itemsCount') > 0;
        }.onChange('session.user', 'user.addresses.$itemsCount'),


        addressLoadingClass: function () {
            return this.$.loadingAddresses ? "loading" : "";
        }.onChange('loadingAddresses'),

        bus_StageRendered: function () {
            this.trigger("stageRendered");
        }.bus("Stage.Rendered")

    });

});
