define('sprd/model/Shop',['sprd/model/AbstractShop', 'js/data/Collection',
        'sprd/model/Basket',
        'sprd/model/ProductType',
        'sprd/model/Article',
        'sprd/model/Product',
        'sprd/model/DiscountScale',
        'sprd/model/ShippingType',
        'sprd/model/Design',
        'sprd/model/PrintType',
        'sprd/model/ShippingOption'
    ],
    function (AbstractShop, Collection, Basket, ProductType, Article, Product, DiscountScale, ShippingType, Design, PrintType, ShippingOption) {

        return AbstractShop.inherit("sprd.model.Shop", {

            schema: {

                productTypes: Collection.of(ProductType),
                articles: Collection.of(Article),
                designs: Collection.of(Design),
                discountScale: DiscountScale,
                products: Collection.of(Product),
                printType: Collection.of(PrintType),
                shippingTypes: Collection.of(ShippingType),
                shippingOptions: Collection.of(ShippingOption)

            }
        });
    });


