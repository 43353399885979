
define('json!checkout/config/accountData',[],function () { return {
    "CHF_CH": {
        "CODE_ACCOUNT": "676191.01 W",
        "CODE_ACCOUNT_IBAN": "CH72 0025 4254 6761 9101 W",
        "CODE_ACCOUNT_POSTCHEQUE": "80-2-2",
        "CODE_SORT_CLEARING": "0254",
        "CODE_SWIFTBIC": "UBSWCHZH80A",
        "NAME_ACCOUNT_OWNER": "sprd.net AG",
        "NAME_INSTITUTE_LOCAL": "UBS AG, Bahnhofsplatz 1, 9000 St. Gallen",
        "TRANSACTION_REASON": "Spreadshirt"
    },
    "DE": {
      "CODE_ACCOUNT": "1266261",
      "CODE_ACCOUNT_IBAN": "DE38 8607 0024 0126 6261 00",
      "CODE_SORT_CLEARING": "86070024",
      "CODE_SWIFTBIC": "DEUTDEDBLEG",
      "NAME_ACCOUNT_OWNER": "sprd.net AG",
      "NAME_INSTITUTE_LOCAL": "Deutsche Bank Leipzig",
      "TRANSACTION_REASON": "Spreadshirt"
    },
    "CH": {
        "CODE_ACCOUNT": "1266261",
        "CODE_ACCOUNT_IBAN": "DE38 8607 0024 0126 6261 00",
        "CODE_SORT_CLEARING": "86070024",
        "CODE_SWIFTBIC": "DEUTDEDBLEG",
        "NAME_ACCOUNT_OWNER": "sprd.net AG",
        "NAME_INSTITUTE_LOCAL": "Deutsche Bank AG Leipzig",
        "TRANSACTION_REASON": "Spreadshirt"
    },
    "GB": {
        "CODE_ACCOUNT": "16750301",
        "CODE_ACCOUNT_IBAN": "GB75 HYVE 3010 6116 7503 01",
        "CODE_SORT_CLEARING": "30-10-61",
        "CODE_SWIFTBIC": "HYVEGB2L",
        "NAME_ACCOUNT_OWNER": "sprd.net AG",
        "NAME_INSTITUTE_LOCAL": "UNICREDIT BANK AG",
        "TRANSACTION_REASON": "Spreadshirt"
    },
    "PL": {
        "CODE_ACCOUNT": "53124059181111000049102408",
        "CODE_ACCOUNT_IBAN": "53124059181111000049102408",
        "CODE_SWIFTBIC": "PKOPPLPWXXX",
        "NAME_ACCOUNT_OWNER": "sprd.net AG",
        "NAME_INSTITUTE_LOCAL": "Bank Polska Kasa Opieki SA",
        "TRANSACTION_REASON": "Spreadshirt + numer Twojego zamówienia"
    },
    "SE": {
        "CODE_ACCOUNT": "5102-7340",
        "CODE_ACCOUNT_IBAN": "SE9250000000052771016087",
        "CODE_SWIFTBIC": "ESSESESS",
        "NAME_ACCOUNT_OWNER": "sprd.net AG",
        "NAME_INSTITUTE_LOCAL": "SEB",
        "TRANSACTION_REASON": "Spreadshirt"
    },
    "DEFAULT": {
        "CODE_ACCOUNT": "1266261",
        "CODE_ACCOUNT_IBAN": "DE38 8607 0024 0126 6261 00",
        "CODE_SORT_CLEARING": "86070024",
        "CODE_SWIFTBIC": "DEUTDEDBLEG",
        "NAME_ACCOUNT_OWNER": "sprd.net AG",
        "NAME_INSTITUTE_LOCAL": "Deutsche Bank Leipzig",
        "TRANSACTION_REASON": "Spreadshirt"
    }
};});

