define('sprd/model/processor/BasketProcessor',['sprd/model/processor/DefaultProcessor'], function (DefaultProcessor) {

    return DefaultProcessor.inherit("sprd.model.processor.BasketProcessor", {

        compose: function (model) {

            var payload = this.callBase();

            var self = this,
                basketItems = model.$.basketItems,
                composedBasketItems = [];
            if (basketItems) {
                basketItems.each(function (basketItem) {
                    composedBasketItems.push(self.$dataSource.composeModel(basketItem));
                });
                payload.basketItems = composedBasketItems;
            }

            var coupons = model.$.coupons,
                composedCoupons = [];

            if (coupons) {
                coupons.each(function (coupon) {
                    composedCoupons.push(self.$dataSource.composeModel(coupon));
                });
                payload.coupons = composedCoupons;
            }

            return payload;

        }

    });
});
