define('checkout/dialog/LoginDialogClass',["checkout/dialog/DialogBaseClass", "sprd/model/User", "sprd/data/SprdApiDataSourceClass", "checkout/bindable/Root", "flow", "sprd/manager/TrackingManager"], function (DialogBaseClass, User, SprdApiDataSourceClass, Root, flow, TrackingManager) {
    return DialogBaseClass.inherit({
        defaults: {
            /**
             * @codeBehind
             */
            container: null,

            loading: false
        },

        inject: {
            api: SprdApiDataSourceClass,
            root: Root,
            trackingManager: TrackingManager,
        },

        loadingClass: function () {
            return this.$.loading ? "loading" : ""
        }.onChange("loading"),

        ctor: function () {
            this.callBase();

            this.bind("dom:add", function () {
                this._initLogin();
            }, this);
        },

        _initLogin: function () {

            var self = this;
            this.set("loading", true);
            var lang = (self.PARAMETER().locale || "").split("_")[0] || "en";

            var messageHandler = function (message) {
                switch (message) {
                    case 'RENDERING_COMPLETE':
                        self.set("loading", false);
                        break;
                    case 'LOGIN_SUCCESS':
                        var currentUser = self.$.api.createEntity(User, "current");
                        currentUser.fetch(function (err, user) {
                            if (!err) {
                                self.$.trackingManager.trackFeature('feature-used', {
                                    featureName: 'checkout-login',
                                    type: 'event',
                                    detail: user.$.id
                                 });
                                self.$.root.set('user', user);
                                self.close();
                            }
                        });
                        break;
                }
            };

            flow()
                .seq(function (cb) {
                    require([self.PARAMETER().loginEndPoint], function () {
                        cb();
                    }, cb)
                })
                .seq(function () {
                    self.$.trackingManager.trackFeature('feature-used', {
                        featureName: 'open-checkout-login',
                        type: 'event'
                     });
                })
                .seq(function () {
                    window.sprdAccount.renderLogin({
                        element: self.$.container.$el,
                        context: 'checkout',
                        callback: messageHandler,
                        lang: lang === "us" ? "en" : lang
                    });
                })
                .exec(function (err) {
                    err && console.error(err);
                });

        }
    })
});
