define('sprd/model/Session',['sprd/data/SprdModel', 'sprd/model/User'], function (SprdModel, User) {
	return SprdModel.inherit('sprd.model.Session', {

        defaults: {
            username: null,
            password: null
        },

        schema: {
            user: {
                type: User,
                required: false
            },
            // FIXME remove all data which isn't part of the schema
            href: {
                type: String,
                required: false
            },
            username: String,
            password: String
        }
	});
});
