define('checkout/view/payment/PrePaymentClass',["checkout/view/payment/PaymentView", "json!checkout/config/accountData", "checkout/lib/qrcode"], function(PaymentView, accountData, QRCode) {

    return PaymentView.inherit("checkout.view.PrePaymentClass", {
        defaults: {
            companyData: null,
            country: "{delivery.invoiceAddress().country}",
            orderNumber: null,
            currencyIsoCode: null,
            _accountData: "{getAccountData()}",
            componentClass: "prepayment",
            totalPrice: null,
            price: null,

            showQRCode: false,
            qrcodewrapper: null
        },

        getAccountData: function() {
            var countryCode = this.get("country.code");
            return accountData[this.$.currencyIsoCode + "_" + countryCode] || accountData[countryCode] || accountData["DEFAULT"];

        }.onChange("country", "currencyIsoCode"),

        _renderQrcodewrapper: function(el) {

            var accountData = this.$._accountData,
                price       = this.$.price;

            if (!(accountData && el && el.$el && this.$.showQRCode && price)) {
                return;
            }

            var bic    = accountData['CODE_SWIFTBIC'],
                to     = accountData['NAME_ACCOUNT_OWNER'],
                iban   = (accountData['CODE_ACCOUNT_IBAN'] || "").replace(/\s/g, "");

            this.set("qrcode", new QRCode(el.$el, {
                text: "BCD\n001\n1\nSCT\n" + bic + "\n" + to + "\n" + iban + "\n" + this.$.currencyIsoCode + price + "\n\n\n" + this.reason(),
                width: 200,
                height: 200,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.M
            }))

        },

        reason: function() {
            return this.get("orderNumber") || this.$.i18n.t("payment.prepayment.orderId");
        }.onChange("orderNumber", "_accountData"),

        isSweden: function() {
            return this.get('country.code') == "SE";
        }.onChange('country')
    });
});
