define('checkout/view/PlacesClass',["js/ui/View", "js/core/Base", "checkout/manager/GoogleManager", "underscore", "checkout/manager/FeatureManager"], function (View, Base, GoogleManager, _, FeatureManager) {

    var base = new Base(),
        addressComponentMap = {
            street_number: "houseNumber",
            route: "street",
            locality: "city",
            administrative_area_level_1: "state",
            country: "country",
            postal_code: "zipCode"
        },
        _google;

    return View.inherit({

        defaults: {
            tagName: "form",
            geoLocate: true,
            country: null,

            /***
             *
             */
            types: "address",

            /***
             * @codeBehind
             */
            autoCompleteInput: null,

            fieldName: null,

            value: null,

            triggerFormSubmit: true,

            autoCompleteHoneyPod: null,

            autoCompleteHoneyPodId: null
        },

        inject: {
            googleManager: GoogleManager,
            featureManager: FeatureManager
        },

        $domAttributes: ['class', 'id', 'style', 'tabindex', 'name', 'draggable', 'title', 'autocomplete'],

        events: [
            "on:placeChanged",
            "on:streetChanged"
        ],

        _initializationComplete: function () {
            this.callBase();
            this.bind('change:country', this.setCountry);
        },

        preventSubmit: function (e) {
            e.preventDefault();

            if (this.$.triggerFormSubmit) {
                var $el = this.$el;
                while ($el.parentNode) {
                    $el = $el.parentNode;

                    if ($el.localName == "form") {

                        var submit = $el.ownerDocument.createElement('input');
                        submit.style.display = 'none';
                        submit.type = 'submit';

                        $el.appendChild(submit).click();
                        $el.removeChild(submit);

                        break;
                    }
                }
            }
        },

        keydown: function (e) {
            if (e && e.$.keyCode == 13) {
                var autoCompleteInput = this.$.autoCompleteInput;

                // select the first element from autocomplete
                if (_google && autoCompleteInput && !document.querySelector(".pac-container .pac-item-selected")) {
                    _google.maps.event.trigger(autoCompleteInput.$el, 'keydown', {
                        keyCode: 40
                    });
                }
            }

        },

        blur: function () {

            var self = this;
            setTimeout(function () {
                var autoCompleteInput = self.$.autoCompleteInput;
                if (autoCompleteInput && autoCompleteInput.$el) {
                    if (self.$.value !== autoCompleteInput.$el.value) {

                        if (_google) {

                            _google.maps.event.trigger(autoCompleteInput.$el, 'focus', {});
                            _google.maps.event.trigger(autoCompleteInput.$el, 'keydown', {
                                keyCode: 13
                            });
                        }

                        self.set("value", autoCompleteInput.$el.value);
                        self.trigger("on:streetChanged")
                    }
                }
            }, 100);

        },


        initGoogle: function (callback) {
            this.$.googleManager.load(callback);
        },

        placeChangedListener: function (input, autoComplete) {
            var place = autoComplete.getPlace(),
                rawInput = input.$.value,
                self = this;

            if (!place.address_components) {
                setTimeout(function () {
                    place = autoComplete.getPlace();

                    if (place.address_components) {
                        triggerPlace();
                    }

                }, 200);
            } else {
                triggerPlace();
            }

            function triggerPlace() {
                var value = {
                    place: place,
                    address: self.parseAddress(place, rawInput)
                };
                self.set(value);
                self.trigger("on:placeChanged", value);
            }
        },

        initAutoComplete: function (input, google) {
            var options = {
                    fields: ["address_components", "name"],
                },
                self = this,
                types = self.$.types,
                autoComplete = self.$.autoComplete,
                rawValue = input.$.value,
                startSearchingAfter = 4,
                autoCompleteHoneyPodElement = document.getElementById(this.$.autoCompleteHoneyPodId);

            _google = _google || google || window.google;

            if (!(_google && rawValue)) {
                return;
            }

            if (this.$.autoCompleteHoneyPod && this.$.autoCompleteHoneyPod.length) {
                return;
            }

            if (autoCompleteHoneyPodElement && autoCompleteHoneyPodElement.value && autoCompleteHoneyPodElement.value.length) {
                return;
            }

            if (!this.$stage.$browser.isMobile && !this.$.featureManager.$.featureA38) {
                return;
            }

            if (rawValue.length < startSearchingAfter) {
                this.removeAutoComplete(input);
                return;
            }

            if (autoComplete) {
                return;
            }

            if (types) {
                options.types = _.isString(types) ? types.split(",") : types;
            }

            if (self.$.country) {
                options.componentRestrictions = {
                    country: self.$.country.$.code
                }
            }

            var pac = document.querySelector(".pac-container");
            if (pac) {
                pac.parentNode.removeChild(pac);
            }

            autoComplete = new _google.maps.places.Autocomplete(input.$el, options);

            autoComplete.addListener('place_changed', function () {
                self.placeChangedListener.call(self, input, autoComplete);
            });


            self.set("autoComplete", autoComplete);
            input.$el.value = input.$.value;
        },

        removeAutoComplete: function (input) {
            var google = _google || window.google,
                autoComplete = this.get("autoComplete");

            if (!google || !autoComplete) {
                return;
            }

            autoComplete.unbindAll();
            google.maps.event.clearInstanceListeners(input.$el);
            google.maps.event.clearInstanceListeners(autoComplete);
            var pac = document.querySelector(".pac-container");
            var parentNode = pac.parentNode;

            if (parentNode) {
                parentNode.removeChild(pac);
            }
            this.set("autoComplete", null);
        },

        render: function () {
            var el = this.callBase();
            var autoCompleteInput = this.$.autoCompleteInput;
            base.synchronizeFunctionCall(this.initGoogle, "google", null, this);
            this.bind("autoCompleteInput", "change:value", function () {
                this.initAutoComplete(autoCompleteInput);
            }, this);
            return el;
        },

        geoLocate: function () {

            if (!this.$.geoLocate) {
                return;
            }

            var geolocation = navigator.geolocation;
            var autoComplete = this.$.autoComplete;
            if (geolocation && autoComplete && geolocation.getCurrentPosition) {

                geolocation.getCurrentPosition(function (position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    var circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });

                    autoComplete.setBounds(circle.getBounds());
                });
            }

        },

        setCountry: function () {
            var autoComplete = this.$.autoComplete,
                country = this.$.country;
            if (!autoComplete || !country) {
                return;
            }

            autoComplete.setComponentRestrictions({country: country.$.code});
        },

        parseAddress: function (address, rawInput) {

            var ret = null;
            if (address) {

                ret = {};

                var addressComponents = address.address_components;

                if (addressComponents) {
                    for (var j = 0; j < addressComponents.length; j++) {
                        var addressComponent = addressComponents[j];

                        for (var k = 0; k < addressComponent.types.length; k++) {
                            var type = addressComponent.types[k],
                                mappedType = addressComponentMap[type];

                            if (type === "route") {
                                ret["street_short_name"] = addressComponent.short_name;
                            }

                            if (mappedType && !ret[mappedType]) {
                                ret[mappedType] = addressComponent.long_name;

                                if (mappedType === "country") {
                                    ret["country_code"] = addressComponent.short_name;
                                }
                            }
                        }

                        if (addressComponent.types.indexOf("postal_code_prefix") >= 0) {
                            ret["zipCode"] = null
                        }
                    }

                    var countryCode = ret["country_code"];

                    if (!ret["city"]) {
                        if (["DE"].indexOf(countryCode) >= 0) {
                            ret["city"] = this.getValueOfAddressComponentType(addressComponents, "administrative_area_level_4");
                        } else if ("FI".indexOf(countryCode) >= 0) {
                            ret["city"] = this.getValueOfAddressComponentType(addressComponents, "administrative_area_level_3");
                        }
                    }

                    if (["SE", "NO"].indexOf(countryCode) >= 0) {
                        ret["city"] = this.getValueOfAddressComponentType(addressComponents, "postal_town");
                    }

                }

                if (rawInput && ret.houseNumber) {
                    // check house number with extensions added by slash, eg. 132/9
                    var houseNumberWithExtensionAfter = new RegExp("\\b" + ret.houseNumber + "\\b(\/\\d+)+", "i").exec(rawInput);
                    var houseNumberWithExtensionBefore = new RegExp("\\b(\\d+)+" + "\\b(\\/)" + ret.houseNumber, "i").exec(rawInput);
                    if (houseNumberWithExtensionAfter) {
                        ret.houseNumberWithExtension = houseNumberWithExtensionAfter[0];
                    } else if (houseNumberWithExtensionBefore) {
                        ret.houseNumberWithExtension = houseNumberWithExtensionBefore[0];
                    }

                }

                ret.city = (ret.city || "").substr(0, 30);

                return ret;
            }
        },

        getValueOfAddressComponentType: function (addressComponents, type) {
            if (addressComponents) {

                for (var j = 0; j < addressComponents.length; j++) {
                    var addressComponent = addressComponents[j];

                    for (var k = 0; k < addressComponent.types.length; k++) {
                        var currentType = addressComponent.types[k];
                        if (currentType === type) {
                            return addressComponent.long_name;
                        }

                    }
                }
            }
        }

    });

});
