define('sprd/entity/Payment',["js/data/Entity"], function (Entity) {
    return Entity.inherit("checkout.entity.Payment", {
        type: "payment",

        defaults: {
            root: null,
            paymentType: null,
            isWebComponentPayment: false
        },

        getType: function () {
            return this.type;
        },

        /**
         * Hook to prepare delivery
         */
        prepare: function (cb) {
            cb && cb();
        },

        _beforeCompose: function (cb) {
            cb && cb();
        },

        getWebComponentConfiguration: function () {
            return this.$.isWebComponentPayment ? this.$.paymentType.$.paymentProviderData.$.paymentMethod : undefined;
        }
    });
});
