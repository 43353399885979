define('sprd/model/Product',["sprd/model/ProductBase", "sprd/model/ProductType", "sprd/entity/Configuration", "js/data/Entity"], function (ProductBase, ProductType, Configuration, Entity) {

    return ProductBase.inherit('sprd.model.Product', {
        schema: {
            appearance: Entity,
            productType: ProductType,
            configurations: [Configuration] // List of Entities
        },

        getConfigurationsForView: function (view) {
            if (!view) {
                return [];
            }
            var viewMaps   = view.$.viewMaps,
                printAreas = [];
            for (var i = 0; i < viewMaps.$items.length; i++) {
                printAreas.push(viewMaps.$items[i].$.printArea.$.id);
            }

            var ret = [];
            this.$.configurations.each(function(config) {
                if (printAreas.indexOf(config.get('printArea.id')) > -1) {
                    ret.push(config);
                }
            });
            return ret;
        }
    });

});
