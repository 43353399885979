define('checkout/view/RootViewBase',["checkout/view/ViewBase", "checkout/bindable/Root"], function(ViewBase, Root) {

    return ViewBase.inherit("checkout.view.CheckoutViewBase", {

        defaults: {
            basket: "{root.basket}",
            delivery: "{root.basket.delivery}",
            checkout: "{basket.checkout}"
        },

        inject: {
            root: Root
        }

    });

});
