define('checkout/model/CheckoutBasket',["sprd/model/Basket", "sprd/model/Delivery", "js/data/Collection", "sprd/model/PaymentType", "sprd/model/Checkout", "sprd/model/ShippingOption", "sprd/entity/Price", "js/data/Entity", "sprd/model/Coupon", "underscore"], function (Basket, Delivery, Collection, PaymentType, Checkout, ShippingOption, Price, Entity, Coupon, _) {

    var Shipping = Entity.inherit('sprd.model.Basket.Shipping', {
        schema: {
            price: Price,
            priceItem: Price
        }
    });

    // do not change the fqClassName as we shadow the normal sprd.model.Basket model
    return Basket.inherit("sprd.model.Basket", {

        schema: {
            paymentTypes: Collection.of(PaymentType),
            checkout: Checkout,
            delivery: Delivery,
            coupons: Collection.of(Coupon),
            shipping: Shipping,
            shippingOptions: [ShippingOption]
        },

        hasUnavailableItems: function() {

            return !!_.find(this.$.basketItems.$items, function(x) {
                return !x.$.available;
            });

        }.on('change'),

        getShippingOption: function (type) {

            if (this.$.shippingOptions) {
                return this.$.shippingOptions.find(function (sOption) {
                    return sOption.$.type == type;
                });
            }

            return null;
        }.onChange("shippingOptions", "shippingOptions.size()"),

        getHandlingGross: function () {
            var giftOption = this.getShippingOption('gift');
            return giftOption ? giftOption.$.price : null;
        }.onChange("shippingOptions", "shippingOptions.size()"),

        supportsGiftWrapping: function () {

            var basketItems = this.$.basketItems;

            if (!(basketItems && basketItems.$items.length > 0)) {
                return false;
            }

            return !basketItems.find(function (item) {
                return item.$.giftWrappingSupported === false;
            });

        }.on("change"),

        clone: function () {
            return this.callBase({exclude: ["coupons", "paymentTypes", "shippingOptions"]});
        }

    });

});
