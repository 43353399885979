define('checkout/dialog/ErrorDialogClass',["checkout/dialog/DialogBaseClass", "underscore", "JSON"], function (BaseDialog, _, JSON) {
    return BaseDialog.inherit("checkout.dialog.ErrorDialogClass", {
        defaults: {
            isMoreInformationVisible: true,
            error: null
        },
        toggleMoreInformation: function () {
            this.set('isMoreInformationVisible', !this.$.isMoreInformationVisible);
        },
        refresh: function () {
            var window = this.$stage.$window,
                oldLocation = window.location.toString(),
                newLocation = oldLocation.replace(/(result=[^&]*)/, "");

            if (newLocation.replace(/#.*$/, "") == oldLocation.replace(/#.*$/, "")) {
                // same location, do a reload
                location.reload();
            } else {
                window.location = newLocation;
            }
        },
        getErrorMessage: function (err) {
            if (!err) {
                return "";
            }
            if (_.isString(err)) {
                return err;
            } else if (err.message) {
                return err.message + "\n" + err.stack;
            } else if (err.xhr && err.xhr.responses) {
                var res = "";
                try {
                    res = JSON.parse(err.xhr.responses.text);
                    return res.message;
                } catch (e) {
                }

                if (err.xhr.status == 404) {

                    if (err.model) {
                        try {
                            res += err.model.factory.prototype.constructor.name.split(".").pop();
                            res += " " + err.model.identifier();
                            return res + " " + err.xhr.statusText;
                        } catch (e) {
                        }
                    }


                }

                return err.xhr.status + " " + err.xhr.statusText
            }

            return err;
        },
        supportAddress: function () {
            var platform = this.PARAMETER().platform;
            return platform === "EU" ? "service@spreadshirt.net" : "service@spreadshirt.com";
        }
    });
});
