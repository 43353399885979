define('sprd/entity/Configuration',['js/data/Entity', 'sprd/model/Design', 'sprd/model/PrintType'], function (Entity, Design, PrintType) {
    return Entity.inherit('sprd.entity.Appearance', {
        defaults: {
            designs: null
        },
        
        schema: {
            designs: [Design],
            printType: PrintType
        },
        getName: function () {
            if (this.$.type == "design") {
                return this.get('designs.at(0).name');
            } else if (this.$.type == "text") {
                var text = this.$.content.svg.text;
                return this.getTextForContent(text.content);

            }
            return "Unknown";
        }.onChange("designs.at(0).name"),
        
        getTextForContent: function (content) {
            if(content.content){
                return this.getTextForContent(content.content);
            } else if (content instanceof Array) {
                var ret = [];
                for (var i = 0; i < content.length; i++) {
                    ret.push(this.getTextForContent(content[i]));
                }
                return ret.join(" ");
            } else {
                return content;
            }
        }
    })
});
