define('checkout/manager/UrlManager',["js/core/Component", "sprd/helper/DomainHelper", "js/data/DataSource", "checkout/helper/UrlHelper"], function (Component, DomainHelper, DataSource, UrlHelper) {

    var ZEND_MAP = {
        EU: {
            en: "en-gb",
            se: "sv",
            dk: "da"
        },
        NA: {
            fr: "fr-ca",
            en: "en-us"
        }
    };

    var PRICE_KEY = "price_",
        EMAIL_KEY = "email_";

    var sources = {
        EU: {
            privacy: "/privacy?iframe=true",
            terms: "/terms?iframe=true",
            terms_teamshirts_de: "/agb?iframe=true",
            terms_teamshirts_uk: "/t-and-c?iframe=true",
            terms_teamshirts_fr: "/cgv?iframe=true",
            terms_teamshirts_se: "/allmannavillkor?iframe=true",
            terms_teamshirts_nl: "/t-and-c?iframe=true",
            terms_teamshirts_be: "/t-and-c?iframe=true",
            terms_teamshirts_dk: "/alm-forretningsbetingelser?iframe=true",
            terms_teamshirts_fi: "/yleiset-sopimusehdot?iframe=true",
            terms_teamshirts_no: "/forretningsvilkaar?iframe=true",
            terms_teamshirts_at: "/agb?iframe=true",
            terms_teamshirts_ch: "/agb?iframe=true",
            terms_teamshirts_eu: "/t-and-c?iframe=true",
            objection: "/terms?iframe=true&scrollTo=objection",
            objection_teamshirts_de: "/agb?iframe#paragraph_8",
            objection_teamshirts_uk: "/t-and-c?iframe#paragraph_8",
            objection_teamshirts_fr: "/cgv?iframe#paragraph_8",
            objection_teamshirts_se: "/allmannavillkor?iframe=true#paragraph_8",
            objection_teamshirts_nl: "/t-and-c?iframe=true#paragraph_8",
            objection_teamshirts_be: "/t-and-c?iframe=true#paragraph_8",
            objection_teamshirts_dk: "/alm-forretningsbetingelser?iframe=true#paragraph_8",
            objection_teamshirts_fi: "/yleiset-sopimusehdot?iframe=true#paragraph_8",
            objection_teamshirts_no: "/forretningsvilkaar?iframe=true#paragraph_8",
            objection_teamshirts_at: "/agb?iframe=true#paragraph_8",
            objection_teamshirts_ch: "/agb?iframe=true#paragraph_8",
            objection_teamshirts_eu: "/t-and-c?iframe=true#paragraph_8",
            emptyBasket: "/empty-basket",
            marketplace: "/-C4408",
            designer: "/-C59",
            home: "/",
            shopDesigner: "/-/customize/product",
            articleDetailPage: "/-A",
            imprint: "/{SHOP_ID}/shopData/checkout/imprint",
            newsletter: "/-C3907?iframe=true"
        },
        NA: {
            privacy: "/privacy?iframe=true",
            terms: "/terms?iframe=true",
            terms_teamshirts_us: "/t-and-c?iframe=true",
            objection: "/terms?iframe=true&scrollTo=objection",
            objection_teamshirts_us: "/t-and-c?iframe=true#paragraph_8",
            emptyBasket: "/empty-basket",
            marketplace: "/-C3376",
            designer: "/-C59",
            home: "/",
            shopDesigner: "/-/customize/product",
            articleDetailPage: "/-A",
            imprint: "/{SHOP_ID}/shopData/checkout/imprint",
            newsletter: "/-C3268?iframe=true"
        }
    };

    return Component.inherit("checkout.manager.UrlManager", {
        defaults: {
            useSpreadshirtDomain: true,
            useVirtualDomain: false,
        },

        getZendLocale: function (locale, platform) {
            var language = (locale || "").split("_")[0];

            language = ZEND_MAP[platform.toUpperCase()][language] || language;

            return language;
        },

        url: function (type, subDomain, locale, platform) {
            subDomain = subDomain || "www";
            var parameter = this.PARAMETER();
            platform = platform || parameter.platform;
            locale = locale || parameter.locale;

            if (!platform) {
                return;
            }

            var baseUrl = "//" + subDomain + ".spreadshirt.";
            if (this.$.useSpreadshirtDomain === false) {
                baseUrl = "//" + subDomain + "." + location.host.split(".")[1] + ".";
            }

            var topLevelDomain = DomainHelper.domain(platform, locale);

            if (this.$.useVirtualDomain === true) {
                // runs on the vm -> prefix top level domain
                topLevelDomain = ("." + topLevelDomain).replace(/\.(\w+)(\/\w+)?$/, ".v$1$2").replace(/^\./, "");
            }

            if (type.indexOf("teamshirts") >= 0) {
                baseUrl = "//www.teamshirts.";
                topLevelDomain = DomainHelper.domain(platform, locale);
            }

            if (parameter.mode === "partner") {
                if (type === 'privacy') {
                    return "https://service.spreadshirt.com/hc/" + this.getZendLocale(locale, platform) + "/articles/115000978409/?shop_id=" + parameter.shopId + "&platform=" + platform.toLowerCase();
                }
                if (type === 'imprint') {
                    return parameter.shopServiceEndPoint + '/' + parameter.shopId + '/shopData/checkout/imprint?locale=' + locale;
                }
            }

            return baseUrl + topLevelDomain + this.relativeUrl(type, platform);
        },

        relativeUrl: function (type, platform) {
            platform = platform || this.PARAMETER().platform;
            return ((sources[platform] || {})[type] || "");
        },

        saveBasketPriceForToken: function (basket, token) {
            if (basket) {
                var formattedPrice = basket.$.currency.formatValue(basket.totalVatIncluded());

                try {
                    this.$stage.$window.sessionStorage.setItem(PRICE_KEY + token, formattedPrice);
                } catch (e) {
                }
            }
        },

        saveEmailForToken: function (email, token) {
            try {
                this.$stage.$window.sessionStorage.setItem(EMAIL_KEY + token, email);
            } catch (e) {
            }
        },

        createReturnUrl: function (basket, paymentType) {
            var link = location.protocol + "//" + location.host + location.pathname;
            var queryParameters = {};

            if (paymentType && paymentType.$.id) {
                queryParameters.paymentTypeId = paymentType.$.id;
            }

            var successToken = DataSource.IdGenerator.genId();
            queryParameters.successToken = successToken;

            if (paymentType && paymentType.$.categoryCode === "PREPAYMENT") {
                queryParameters.prePayment = true;
                this.saveBasketPriceForToken(basket, successToken);
            }

            this.saveEmailForToken(basket.$.delivery.$.email, successToken);

            var affiliateUrlParameter = UrlHelper.getAffiliateUrlParameter();

            _.each(_.keys(affiliateUrlParameter), function (key) {
                if (affiliateUrlParameter.hasOwnProperty(key)) {
                    queryParameters[key] = encodeURIComponent(affiliateUrlParameter[key]);
                }
            });

            var parameter = this.PARAMETER();

            if (parameter.locale) {
                var locale = parameter.locale.split("_");
                queryParameters.lang = locale[0];
                queryParameters.locale = locale[1];
            }
            if (parameter.mode === "partner" && parameter.shopId) {
                queryParameters.shopId = parameter.shopId;
            }

            var authToken = parameter.authToken;
            if (authToken) {
                queryParameters.authToken = authToken;
            }

            var version = parameter.version;
            if (version) {
                queryParameters.version = version;
            }

            var continueShoppingLink = parameter.continueShoppingLink;
            if (continueShoppingLink) {
                queryParameters.continueShoppingLink = encodeURIComponent(continueShoppingLink);
            }

            var queryString = Object.keys(queryParameters).map(function (key) {
                return key + "=" + queryParameters[key];
            }).join("&");

            if (queryString) {
                link += "?" + queryString;
            }

            return link += (location.hash || "").replace(/(#\/[^/]+).*/, "$1").replace(/^#\//, "#");
        }

    });
});

