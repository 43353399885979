define('checkout/CheckoutSuccessClass',["xaml!checkout/CheckoutBase", "underscore", "checkout/model/CheckoutOrder", "js/core/Error", "flow", "rAppid", "sprd/model/User", "sprd/manager/TrackingManager", "require", "checkout/helper/UrlHelper"], function(CheckoutBase, _, Order, Error, flow, rAppid, User, TrackingManager, require, UrlHelper) {

    var http = "http:",
        ORIGIN = {
            USERAREA: "4"
        },
        ShareBy = TrackingManager.ShareBy,
        ShareAffiliateId = "1246955";

    return CheckoutBase.inherit("checkout.CheckoutSuccessClass", {

        name: "checkout-success",

        defaults: {
            order: null,
            popupManager: null,
            user: null,
            shopId: "{order.shop.id}",
            totalPrice: null,
            orderItemsLoaded: false,
            isVideoStarted: false,

            /***
             * @codeBehind
             */
            googleCustomerReviews: null,
            surveyId: undefined
        },

        _initializationComplete: function () {
            this.$stage.set('class', this.isPrePayment() ? "pre-payment" : "");
            const surveyLocale = this.PARAMETER().locale.split("_")[0]
            if (this.PARAMETER().businessUnit === "TEAMSHIRTS") {
                this.$.surveyId = {
                    'de': 'tRaiETqnLgj758hTBazgd_2Fu0jRGd3Iksm4_2F9OurWx26qyjz9CpbEaOgE3yzdyIcZ',
                    'en': 'tRaiETqnLgj758hTBazgdyxhAYEFrfnfhu7TDYcP6pmOgSCSt_2FE0XM3z0wDV_2Bsue',
                    'us': 'tRaiETqnLgj758hTBazgdyxhAYEFrfnfhu7TDYcP6pnIIiqP1AdYnJYim7mEoQAW',
                    'fr': 'tRaiETqnLgj758hTBazgd_2Fu0jRGd3Iksm4_2F9OurWx26GIGpprAmXUzfnP7q1jBEs',
                    'nl': 'tRaiETqnLgj758hTBazgd_2Fu0jRGd3Iksm4_2F9OurWx26A3br2Ck71Yxvj8niXeYCc',
                    'se': 'tRaiETqnLgj758hTBazgd_2Fu0jRGd3Iksm4_2F9OurWx24dx82vCtF1ZD1HByy6LLqy',
                    'no': 'tRaiETqnLgj758hTBazgd_2Fu0jRGd3Iksm4_2F9OurWx25bPmMwKwmJhP8N_2FgpiQRag',
                    'dk': 'tRaiETqnLgj758hTBazgdyxhAYEFrfnfhu7TDYcP6pkaOcVE_2F45_2FkOk6t6dzZZcL',
                    'fi': 'tRaiETqnLgj758hTBazgd_2Fu0jRGd3Iksm4_2F9OurWx26r2FSItRI6jQKPIsuzrx6Z',
                }[surveyLocale]
            } else if (this.PARAMETER().businessUnit === "D2C") {
                this.$.surveyId = {
                    'de': 'tRaiETqnLgj758hTBazgd_2FWYuqe_2BEfeIyPrUjzZ9nEqkRBn_2FAIq5_2FV2JBE13uOWy',
                    'en': 'tRaiETqnLgj758hTBazgdy_2Fao_2FnGyFISktXKz_2BhZSBShp1Kt46mA2CujSTT_2F5iPT',
                    'fr': 'tRaiETqnLgj758hTBazgd_2BJgAqj0xqJFcLzqA_2BmcWfniiIpo_2FWDa5Nav1Q5RYIbd',
                    'nl': 'tRaiETqnLgj758hTBazgd_2BJgAqj0xqJFcLzqA_2BmcWfkpQgeSPmHAtDLh3Mm_2F47kI',
                    'us': 'tRaiETqnLgj758hTBazgd_2BJgAqj0xqJFcLzqA_2BmcWfl_2FJqjHqEk2KMjDHOtxw5oP',
                }[surveyLocale];
            };

            this.callBase();
        },

        orderNumber: function() {
            var parameter = this.PARAMETER();
            return (parameter.orderId || "") + "-" + (parameter.transactionId || "");
        },

        isPrePayment: function() {
            return !!(this.PARAMETER() || {}).prePayment;
        },

        _start: function(parameter, checkoutToken, callback) {

            var api = this.$.api,
                order = api.createEntity(Order, parameter.orderId),
                self = this,
                bambamClient = this.$.bambamClient,
                originalResourcePathToUri = api._resourcePathToUri;

            this.set({
                "order": order,
                'totalPrice': self.getBasketPriceForSuccessToken(parameter.successToken)
            });

            api._resourcePathToUri = function(resourcePath, resource) {
                var uri = originalResourcePathToUri(resourcePath, resource);

                if (resource.constructor.name == "sprd.model.Order") {
                    uri = uri.replace("/api/v1", "/rest");
                }

                return uri;
            };

            api._resourcePathToUri = function(resourcePath, resource) {
                var uri = originalResourcePathToUri(resourcePath, resource);

                if (resource.constructor.name == "sprd.model.Order") {
                    uri = uri.replace("/api/v1", "/rest");
                }

                return uri;
            };

            bambamClient.trackBasketSuccess(checkoutToken);

            flow()
                .seq(function(cb) {
                    order.fetch({
                        fullData: true,
                        keepRawData: true,
                        fetchSubModels: ["currency"]
                    }, function(err) {

                        if (err) {
                            if (err.xhr && err.xhr.status === 401) {
                                // sprd-auth-token not set -> first party cookies
                                // TODO: track this error
                            } else {
                                // TODO: track error
                            }
                        }

                        // don't show an error, as the success page is more important
                        // than showing the items purchased
                        cb();
                    });
                })
                .seq(function() {

                    // as article don't have an image resource, we need to fetch articles
                    // to get a product and can display a product image

                    if (order.$.orderItems) {
                        flow()
                            .parEach(order.$.orderItems.toArray(), function(orderItem, cb) {

                                flow()
                                    .par(function(cb) {
                                        orderItem.$.element.init(function() {
                                            cb();
                                        });
                                    }, function(cb) {
                                        orderItem.$.shop.fetch(cb);
                                    })
                                    .exec(cb);
                            })
                            .exec(function() {
                                self.set("orderItemsLoaded", true);
                            });
                    }

                })
                .seq(function() {
                    var currentUser = self.$.api.createEntity(User, "current");
                    currentUser.fetch(function(err, user) {
                        if (!err) {
                            self.set('user', user);
                        }
                    });
                })
                .seq(function() {
                    try {
                        sessionStorage.removeItem("deliveryEmail");

                        for (var i = 0; i < sessionStorage.length; i++) {
                            var key = sessionStorage.key(i);
                            if (/^token_/.test(key) && sessionStorage.getItem(key) === checkoutToken) {
                                sessionStorage.removeItem(key);
                                break;
                            }
                        }
                    } catch (e) {
                    }
                })
                .seq(function() {

                    window.addEventListener("unload", function() {
                        self.clearSuccessToken();
                    });
                })
                .seq(function () {
                    self.loadSurvey(0);
                })
                .exec(callback);

        },

        loadSurvey: function(attempt) {
            var surveyId = this.$.surveyId;
            if (surveyId && attempt < 3) {
                var self = this;
                setTimeout(function () {
                    if (document.getElementById('smcx-sdk')) {
                        return;
                    }

                    if (!window.SMCX) {
                        window.SMXC = [];
                    }

                    var placeholder = document.getElementById('checkout-success-survey');
                    if (!placeholder) {
                        return self.loadSurvey(attempt + 1);
                    }

                    var closeButton = placeholder.nextElementSibling;
                    const closeButtonPosition = window.innerWidth - document.documentElement.clientWidth
                    if (closeButtonPosition > 0) {
                        closeButton.style.setProperty('--right', closeButtonPosition + 'px');
                    }
                    closeButton.onclick = function () { closeButton.parentNode.remove() };

                    var scriptElement = document.createElement('script');
                    scriptElement.type = 'text/javascript';
                    scriptElement.async = true;
                    scriptElement.id = 'smcx-sdk';
                    scriptElement.src = 'https://widget.surveymonkey.com/collect/website/js/' + surveyId + '.js';
                    placeholder.parentNode.insertBefore(scriptElement, placeholder);
                }, 2000);
            }
        },

        _applicationStarted: function(startTime) {

            var self = this;

            flow()
                .seq(function(cb) {
                    var returned = false;

                    if (self.$.orderItemsLoaded) {
                        cb();
                    } else {
                        // we haven't loaded the order items, so we cannot access the
                        // name of it and would always send n/a
                        self.bind("change:orderItemsLoaded", function(orderItemsLoaded) {
                            if (orderItemsLoaded && !returned) {
                                returned = true;
                                cb();
                            }
                        });

                        setTimeout(function() {
                            if (!returned) {
                                returned = true;
                                cb();
                            }
                            // wait maximum 3 seconds, otherwise we're maybe loosing the
                            // tracking event completely
                        }, 3000);
                    }
                })
                .exec(function() {
                    self.$.trackingManager.trackCheckoutSuccess(startTime, self.$.order);
                });

        },

        removeDuplicatesAndUserArea: function(orderItems, url) {

            var ret = [],
                cache = [];

            if (orderItems) {
                orderItems.each(function(orderItem) {

                    var cacheId = [
                        orderItem.get("element.getType()"),
                        orderItem.get("element.appearance.id"),
                        orderItem.get("element.item.id")
                    ].join("-");

                    if (_.indexOf(cache, cacheId) === -1 && orderItem.get('origin.id') != ORIGIN.USERAREA && orderItem.get("element.appearance.id") != 0) {
                        cache.push(cacheId);
                        ret.push(orderItem);
                    }
                });
            }

            return ret;
        },

        shareOnFacebook: function(orderItem, itemUrl) {
            var encodeURIComponent = window.encodeURIComponent;

            this.$.trackingManager.trackShareToOmniture(ShareBy.Facebook);
            itemUrl += "/sb/" + this.$.trackingManager.generateOmnitureShareString(ShareBy.Facebook);

            var url = 'http://www.facebook.com/sharer.php?s=100' +
                '&p[url]=' + itemUrl +
                '&p[title]=' + encodeURIComponent(orderItem.get("element.name"));

            this.$.popupManager.showPopup(url, {
                name: "facebook",
                width: 980,
                height: 480
            });

        },

        shareOnTwitter: function(orderItem, itemUrl) {
            var encodeURIComponent = window.encodeURIComponent,
                hashTags = ["spreadshirt"],
                text = "";

            this.$.trackingManager.trackShareToOmniture(ShareBy.Twitter);

            itemUrl += "/sb/" + this.$.trackingManager.generateOmnitureShareString(ShareBy.Twitter);

            var url = "http://twitter.com/share?text=" + text + "&url=" + encodeURIComponent(itemUrl) + "&hashtags=" + hashTags.join(",");

            this.$.popupManager.showPopup(url, {
                name: "twitter",
                width: 640,
                height: 480
            });

        },

        shareOnPinterest: function(orderItem, itemUrl) {

            var product = orderItem.get("element.getProduct()");
            var name = orderItem.get("element.item.name");
            var description = orderItem.get("element.item.description");

            this.$.trackingManager.trackShareToOmniture(ShareBy.Pinterest);
            itemUrl += "/sb/" + this.$.trackingManager.generateOmnitureShareString(ShareBy.Pinterest);

            var url = "http://pinterest.com/pin/create/link/?url=" + encodeURIComponent(itemUrl);

            if (product) {
                var imageUrl = this.$.imageService.productImage(product.$.lookupId, product.get("defaultValues.defaultView.id"), product.get("appearance.id"), "product", {
                    width: 500,
                    height: 500
                });

                imageUrl = "http:" + imageUrl;
                url += "&media=" + encodeURIComponent(imageUrl);
            }

            if (name && description) {
                url += "&description=" + encodeURIComponent(name + ". ") + encodeURIComponent(description);
            } else if (name) {
                url += "&description=" + encodeURIComponent(name);
            }

            this.$.popupManager.showPopup(url, {
                name: "pinterest",
                width: 640,
                height: 480
            });


        },

        shareByMail: function(orderItem, itemUrl) {

            var i18n = this.$.i18n;

            this.$.trackingManager.trackShareToOmniture(ShareBy.Mail);

            itemUrl += "/sb/" + this.$.trackingManager.generateOmnitureShareString(ShareBy.Mail);

            window.location.href = "mailto:?body=" + encodeURI(i18n.t('share.mail.body', itemUrl)) + "&amp;subject=" + i18n.t('share.mail.subject');
        },

        _getOrderItemUrl: function(orderItem) {

            var stage = this.$stage;

            if (!(orderItem && stage && this.$.orderItemsLoaded)) {
                return;
            }

            var editLink = orderItem.get("element.editLink");

            if (editLink) {
                return editLink;
            }

            var type = orderItem.get("element.getType()"),
                shop = orderItem.$.shop,
                urlManager = this.$.urlManager;

            if (!shop) {
                return;
            }

            var elementId = orderItem.get("element.item.id"),
                platform = this.PARAMETER().platform;

            if (type === "sprd:product") {

                if (shop && shop.isOwnedBySpreadshirt(platform)) {
                    return http + urlManager.url("designer") + "/product/" + elementId + "/affiliate/" + ShareAffiliateId;
                } else {
                    // build a shop specific url
                    return http + urlManager.url("shopDesigner", shop.$.id) + "/" + elementId
                }
            } else if (type === "sprd:article") {
                // send to market place
                if (shop && shop.isMarketPlace(platform)) {
                    return http + urlManager.url("marketplace") + "A" + elementId + "/affiliate/" + ShareAffiliateId;
                } else {
                    // send to shop detail page
                    return http + urlManager.url("articleDetailPage", shop.$.id) + elementId
                }
            }

            return null;
        }.onChange("orderItemsLoaded"),

        play: function() {

            if (this.get("isVideoStarted") === false) {
                this.set("isVideoStarted", true);
            }

        },

        videoUrl: function(videoId) {

            var language = this.PARAMETER().lang;

            return this._buildUrl("https://www.youtube.com/embed/" + videoId, {
                "hl": language === "us" ? "en" : language,
                "autoplay": 1,
                "autohide": 1,
                "modestbranding": 1,
                "color": "white",
                "disablekb": 1,
                "showinfo": 0,
                "controls": 1,
                "vq": "hd480",
                "rel": 0
            });

        },

        _buildUrl: function(baseUrl, queryParameter) {

            var query = [];
            _.map(queryParameter, function(v, k) {
                if (v != null) {
                    query.push(k + "=" + encodeURIComponent(v));
                }
            });

            return baseUrl + "?" + query.join("&");

        },

        or: function(a, b) {
            return a || b
        },

        defaultCountry: function() {
            return {
                code: this.PARAMETER().locale.split("_")[1]
            }
        },

        deliveryEta: function(callback) {

            var shippingType = this.get("order.shipping.type"),
                countryCode = this.get("order.shipping.address.country.code"),
                state = this.get("order.shipping.address.state"),
                quantity = 0,
                orderItems = this.get("order.orderItems");

            orderItems.each(function(orderItem) {
                quantity = quantity + orderItem.$.quantity;
            });

            if (!(shippingType && shippingType.$.id && countryCode && quantity)) {
                return null;
            }

            this.synchronizeFunctionCall(function(cb) {

                var queryParameter = {
                    shippingTypeId: shippingType.$.id,
                    countryCode: countryCode,
                    quantity: quantity
                };

                if (state) {
                    queryParameter.stateCode = state.$.code;
                }

                rAppid.ajax("/api/v1/deliveryETA", {
                    queryParameter: queryParameter
                }, function(err, xhr) {

                    if (!err && xhr.status === 200) {
                        // ok
                        cb(null, JSON.parse(xhr.responses.text));
                    } else {
                        // error
                        cb(err || xhr.responses.text || true);
                    }

                });

            }, [shippingType.$.id, countryCode, state ? state.$.code : "", quantity].join("-"), function(err, eta) {

                if (!err) {

                    eta.loaded = true;
                    callback && callback(eta)
                }

            });

        }

    });
});

