define('sprd/model/Registration',["sprd/data/SprdModel",
    "js/data/Entity",
    "sprd/model/Session",
    "sprd/model/User",
    "sprd/model/Language", "sprd/model/Country"], function (SprdModel, Entity, Session, User, Language, Country) {

    var RegistrationResult = Entity.inherit('sprd.model.Registration.RegistrationResult', {
        schema: {
            session: {
                type: Session,
                required: false
            },
            user: {
                type: User,
                required: false
            }
        }
    });

    var Newsletter = Entity.inherit('sprd.model.Registration.Newsletter', {
        defaults: {
            source: "CHECKOUT",
            lists: {
                customer: true
            }
        },

        schema: {
            source: String,
            shop: String,
            lists: Object
        },

        setListType: function (type, enabled) {
            this.$.lists[type] = !!enabled;
        },

        compose: function () {
            var data = this.callBase();

            if (data.shop) {
                data.shop = {
                    id: data.shop
                };
            }

            return data;
        }
    });


    var Registration = SprdModel.inherit('sprd.model.Registration', {
        defaults: {
            email: ""
        },
        schema: {
            email: String,
            country: Country,
            language: Language,
            newsletter: {
                type: Newsletter,
                required: false
            }
        },
        resultType: RegistrationResult
    });

    Registration.RegistrationResult = RegistrationResult;
    Registration.Newsletter = Newsletter;

    return Registration;
});
