define('checkout/view/LocationClass',["checkout/view/ViewBase", "js/core/List", "rAppid", "flow", "checkout/manager/GoogleManager", "sprd/lib/ScrollIntoView", "sprd/manager/FeatureManager", "underscore", "js/core/Bindable"], function(ViewBase, List, rAppid, flow, GoogleManager, ScrollIntoView, FeatureManager, _, Bindable) {

    // geo coords of gym at leplaystraße in memory of my grand father, who grew olympic winners here
    var defaultCenter = {lat: 51.3345345, lng: 12.3812789},
        CustomMarker;

    return ViewBase.inherit({

        defaults: {
            address: null,
            search: null,

            locations: null,
            yourPosition: null,

            // TODO: show loader
            loading: false,

            selected: "{loading}",
            class: "{hasSearchResultsClass()}",
            componentClass: "ups",

            selectedLocation: null,

            errorKey: "",
            errorMessageKey: "location",
            showMap: false,
            /***
             * @codeBehind
             */
            mapNode: null,
            map: null,

            center: null,
            markers: null,

            hasError: false,
            /***
             * @codeBehind
             */
            errorField: null
        },

        inject: {
            googleManager: GoogleManager,
            featureManager: FeatureManager
        },

        events: [
            "on:locationSelected"
        ],

        ctor: function() {
            this.callBase();

            var errorKey = this.$.errorKey;
            if (errorKey) {
                this.bind(["address.errors()", "change:" + errorKey], function() {
                    this.set("hasError", !!this.get("address.errors()." + errorKey));
                }, this);
            }
        },

        _commitHasError: function(hasError) {
            if (hasError) {
                var errorField = this.$.errorField;
                setTimeout(function() {
                    var el = errorField && errorField.$el;
                    ScrollIntoView(el, -50);
                }, 1);

            }
        },

        _onDomAdded: function() {

            var self = this;

            this.callBase();

            self.$.searchField.focusFirstInput();

            this.$.googleManager.load(function() {
                declareCustomMarker();

                self.initMap();

                if (!self.located) {
                    self.located = true;
                    self.searchNearBy();

                }
            });

        },

        initMap: function(callback) {

            var self = this;
            this.synchronizeFunctionCall(function(callback) {

                flow()
                    .seq(function(cb) {
                        self.$.googleManager.load(cb);
                    })
                    .seq(function() {


                        self.set("showMap", true);
                        var map = new google.maps.Map(self.$.mapNode.$el, {
                            zoom: 6,
                            center: self.$.center || defaultCenter,
                            disableDefaultUI: true,
                            zoomControl: true
                        });

                        google.maps.event.addListener(map, 'idle', function() {

                            var markers = self.$.markers;
                            if (markers) {
                                var bounds = map.getBounds();

                                markers.forEach(function(marker) {
                                    if (!marker.yourPosition && marker.location) {
                                        marker.location.set("insideViewPort", (bounds.contains(marker.getPosition())));
                                    }
                                })
                            }
                        });

                        self.set({
                            map: map
                        });


                        setTimeout(function() {
                            self.removeTabIndexFromMap(self.$.mapNode.$el);
                        }, 1000);

                    })
                    .exec(callback);

            }, "map", callback);

        },

        errorMessage: function() {

            var i18n = this.$.i18n;

            if (!i18n) {
                return;
            }

            return i18n.ts('error.isUndefinedError', this.$.errorMessageKey) || i18n.ts('error.isUndefinedError', 'packstation');

        }.onChange("i18n"),

        removeTabIndexFromMap: function(map) {

            Array.prototype.slice.call(map.querySelectorAll("[tabindex], a, button, iframe")).forEach(function(e) {
                e.tabIndex = -1;
            });

        },

        hasSearchResultsClass: function() {
            var locations = this.$.locations;
            return locations && locations.size > 0 ? "has-locations" : "";
        }.onChange("locations"),

        supportsGeoLocation: function() {
            var geolocation = navigator.geolocation;

            return !!(geolocation && geolocation.getCurrentPosition);
        },

        _searchLocationsByText: function(search, callback) {
            callback && callback(null, []);
        },

        _searchLocationsByCoordinates: function(lat, lng, callback) {
            callback && callback(null, []);
        },

        _selectLocation: function(location) {
            // hook
            this.set("hasError", false);
        },

        search: function(fnc) {

            var self = this;
            this.set("loading", true);

            flow()
                .seq(function(cb) {
                    self.initMap(cb);
                })
                .seq("data", fnc)
                .seq(function() {
                    _.map(this.vars.data, function(location, index) {
                        location.index = index + 1
                    });
                })
                .exec(function(err, results) {

                    err && console.error(err);

                    self.set({
                        loading: false,
                        originalLocations: err ? null : results.data.slice()
                    });

                    self.filterLocations();
                });
        },

        filterLocations: function() {
            var locations          = this._filterLocations(this.$.originalLocations || []),
                selectedLocationId = this.$.selectedLocation ? this.$.selectedLocation.id : null;

            _.forEach(locations, function(location, index) {
                location.index = index + 1;
                location.insideViewPort = true;
            });


            selectedLocationId = _.find(locations, function(location) {
                return location.id === selectedLocationId;
            });


            this.set({
                locations: new List(_.map(locations, function(location) {
                    return new Bindable(location);
                })),
                selectedLocation: selectedLocationId ? this.$.selectedLocation : null
            });
        },

        _filterLocations: function(locations) {
            return locations;
        },

        searchLocation: function(e) {
            e && e.preventDefault();

            var search = this.$.search;

            if (!search) {
                return this.set({
                    locations: new List()
                });
            }

            var self = this;
            this.search(function(callback) {
                self._searchLocationsByText(search, callback);
            });
        },

        searchNearBy: function() {

            if (this.supportsGeoLocation()) {

                var self = this;
                this.set("loading", true);

                navigator.geolocation.getCurrentPosition(function(position) {

                    var lat = position.coords.latitude;
                    var lng = position.coords.longitude;

                    self.search(function(callback) {
                        self._searchLocationsByCoordinates(lat, lng, callback)
                    });

                    var pos = {
                        lat: lat,
                        lng: lng
                    };

                    self.set({
                        center: pos,
                        position: pos
                    });


                }, function(error) {
                    self.set("loading", false);
                    console.warn(error);
                });
            }
        },


        isSelectedLocation: function(location) {
            var selectedLocation = this.$.selectedLocation;
            return selectedLocation && location && selectedLocation.$.id === location.$.id;
        }.onChange("selectedLocation"),

        inc: function(value) {
            return value + 1;
        },

        _commitCenter: function(center) {
            var map = this.$.map;
            if (map && center) {
                map.panTo(new google.maps.LatLng(center.lat, center.lng));
            }
        },

        _commitPosition: function(position) {
            var map = this.$.map;
            if (map && position && CustomMarker) {
                var marker = new CustomMarker({
                    position: new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng)),
                    map: map,
                    render: function(div) {
                        div && div.classList.add("you")
                    }
                });
                marker.yourPosition = true;
            }
        },

        _commitLocations: function(locations) {

            var map  = this.$.map,
                self = this;

            if (!(map && locations)) {
                return;
            }

            var markers = this.$.markers;
            if (markers) {
                markers.forEach(function(marker) {
                    if (!marker.yourPosition) {
                        marker.setMap(null);
                    }
                })
            }

            markers = [];

            if (locations && CustomMarker) {
                markers = _.map(locations.$items, function(location, index) {

                    var position = location.$.position;
                    var marker = new CustomMarker({
                        position: new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng)),
                        title: location.$.title,
                        map: map,

                        render: function(div) {
                            div.innerText = index + 1;
                            div.className = "sprd-marker";

                            if (this.active) {
                                div && div.classList.add("active")
                            }
                        }
                    });

                    marker.location = location;

                    marker.addListener("click", function(event) {

                        event.preventDefault();
                        event.stopImmediatePropagation();

                        self.selectLocation(null, location, false, true);
                    });

                    location.marker = marker;

                    return marker
                });
            }

            if (markers.length) {
                var bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < markers.length && i < 6; i++) {
                    bounds.extend(markers[i].getPosition());
                }

                map.fitBounds(bounds);
            }


            this.set("markers", markers);

            if (locations && locations.size() === 1) {
                this.selectLocation(null, locations.at(0));
            }

        },

        checkEnter: function(event, location) {
            if (event && event.domEvent && event.domEvent.which === 13) {
                event.preventDefault();
                this.selectLocation(null, location, false, false)
            }
        },

        selectLocation: function(event, location, scrollToBilling, scroll) {

            if (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }

            var markers = this.$.markers;
            if (markers) {
                markers.forEach(function(marker) {
                    marker.setActive(false);
                })
            }

            this.set("selectedLocation", location);

            if (location.marker) {
                location.marker.setActive(true);
            }

            if (scrollToBilling) {
                this.trigger("on:locationSelected");
            } else if (location.marker && scroll) {
                var scrollTime = this.$.featureManager.$.scrollTime;
                setTimeout(function() {
                    ScrollIntoView(document.querySelector(".location-" + location.$.id), -50, scrollTime * 4, null, document.querySelector(".address-book .modal-body"))
                }, 500);
            }

            this._selectLocation(location);
        },


    });

    function declareCustomMarker() {

        if (CustomMarker) {
            return;
        }

        CustomMarker = function(options) {
            this.options = options;
            google.maps.OverlayView.apply(this, Array.prototype.slice.call(arguments));

            if (options.init) {
                options.init.call(this);
            }

            this.setMap(options.map);
        };

        CustomMarker.prototype = new google.maps.OverlayView();
        CustomMarker.prototype.constructor = CustomMarker;

        CustomMarker.prototype.draw = function() {


            var div = this.div;

            if (!div) {

                div = this.div = document.createElement('div');

                div.style.position = 'absolute';
                div.style.cursor = 'pointer';
                div.style.width = '0';
                div.style.height = '0';

                if (this.options.render) {
                    this.options.render.call(this, div);
                }

                var self = this;
                google.maps.event.addDomListener(div, "click", function(event) {
                    google.maps.event.trigger(self, "click", event);
                });

                var panes = this.getPanes();
                panes.overlayImage.appendChild(div);
            }

            var point = this.getProjection().fromLatLngToDivPixel(this.getPosition());

            if (point) {
                div.style.left = point.x + 'px';
                div.style.top = point.y + 'px';
            }
        };

        CustomMarker.prototype.remove = function() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        };

        CustomMarker.prototype.getPosition = function() {
            return this.options.position;
        };


        CustomMarker.prototype.setActive = function(active) {
            this.active = active;

            this.div && this.div.classList[active ? "add" : "remove"]("active");

        };

    }

});
