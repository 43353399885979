define('checkout/view/PackStationClass',["xaml!checkout/view/Location", "rAppid", "flow", "underscore"], function(Location, rAppid, flow, _) {

    var PACKSTATION = {
        name: "Packstation",
        automatType: "4"
    };

    var POSTFILIALE = {
        name: "Postfiliale",
        automatType: "30"
    };

    var DAY_NAMES = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

    var STORE_TYPES = [PACKSTATION, POSTFILIALE];

    var CITY_ZIP_EXTRACTOR = /^(\d+)?[, ]*(.+?)?[, ]*(\d+)?$/;

    return Location.inherit({

        defaults: {
            componentClass: "packstation location-view",
            storeType: null,
            search: '{address.city}',
            filter: "packstation",

            errorKey: "packStationNr"
        },

        ctor: function() {
            this.callBase();
            this.set("storeType", this.$.filter === "packstation" ? PACKSTATION : POSTFILIALE);
        },

        storeTypes: function() {
            return STORE_TYPES;
        },

        searchOnEnter: function(e) {
            if (e && e.domEvent && e.domEvent.which === 13) {
                this.searchLocation(e);
            }
        },

        _searchLocationsByText: function(search, callback) {

            var match = CITY_ZIP_EXTRACTOR.exec(search || "");
            var city = search,
                zip  = "";

            if (match) {
                zip = match[1] || match[3];
                city = match[2]
            }

            this._loadData({
                city: city,
                zip: zip || ""
            }, callback);
        },

        _searchLocationsByCoordinates: function(lat, lng, callback) {
            this._loadData({
                location: {
                    latitude: lat,
                    longitude: lng
                }
            }, callback);

        },

        _commitStoreType: function(a, b) {
            if (a && b && a !== b) {
                this.filterLocations();
            }
        },

        openingHoursSummary: function(location) {
            if (!(location && location.$.timeinfos)) {
                return;
            }

            var infos = _.filter(location.$.timeinfos, function(info) {
                return info.type == 1;
            }).sort(function(a, b) {

                var diff = a.dayTo - b.dayTo;

                if (diff == 0) {
                    return parseInt(a.timeFrom) - parseInt(b.timeFrom);
                }

                return diff
            });

            var days = _.groupBy(infos, function(info) {
                return info.dayTo
            });

            var timeTable = _.map(days, function(days) {

                var dayOfTheWeek = days[0].dayTo - 1;
                return {
                    day: DAY_NAMES[dayOfTheWeek],
                    dayOfTheWeek: dayOfTheWeek,
                    time: _.map(days, function(day) {
                        return day.timeFrom + " - " + day.timeTo
                    }).join(", ")
                }
            });

            var group = _.groupBy(timeTable, function(entry) {
                return entry.time
            });

            return _.map(group, function(time) {

                var hasGap = false,
                    first  = time[0].dayOfTheWeek;

                for (var i = 1; i < time.length; i++) {
                    if (time[i].dayOfTheWeek != first + i) {
                        hasGap = true;
                        break;
                    }
                }

                return {
                    day: time.length === 1 ? time[0].day : hasGap ? _.map(time, function(day) {
                        return day.day
                    }).join(", ") : (time[0].day + " - " + time[time.length - 1].day),
                    time: time[0].time
                }
            });


        },

        _filterLocations: function(locations) {

            var automatType = this.$.storeType.automatType;

            locations = _.filter(locations, function(location) {
                return location && location.automatType == automatType;
            });

            return locations;

        },

        _selectLocation: function(location) {

            this.callBase();
            
            if (!location) {
                return;
            }

            var address = location.$.address;
            this.$.address.set({
                packStationNr: "" + (parseInt(location.$.packstationId) || parseInt(location.$.depotServiceNo)),
                packStation: {
                    street: address.street + " " + address.streeNo,
                    company: location.externalMarker || null,
                    city: address.city,
                    zipCode: address.zip,
                    location: location
                }
            })
        },

        _loadData: function(request, callback) {

            var type = this.$.filter.toLowerCase() === "packstation" ? "locker" : "store";
            flow()
                .seq("xhr", function(cb) {
                    rAppid.ajax("/abc/locate/dhl?type=" + type, {
                        type: "POST",
                        contentType: "application/json",
                        data: JSON.stringify(request)
                    }, cb);
                })
                .seq("data", function() {
                    var xhr = this.vars.xhr;
                    if (!(xhr.status === 200 && xhr.responses.text)) {
                        throw new Error("Error loading locations");
                    }

                    var data = JSON.parse(xhr.responses.text);

                    return _.map(data, function(location) {

                        var geocode = location.location,
                            address = location.address;

                        if (location.automatType == PACKSTATION.automatType) {
                            location.title = "Packstation " + location.packstationId;
                        } else {
                            location.title = location.externalMarker || (address.street + " " + address.streetNo);
                        }

                        location.position = {
                            lat: geocode.latitude,
                            lng: geocode.longitude
                        };

                        location.id = location.locationId;

                        return location;
                    });


                })
                .exec(function(err, results) {
                    callback(err, results.data);
                });

        },

        formatDistance: function(distance) {

            if (distance) {
                var value = parseFloat(distance);

                if (value < 1000) {
                    return parseInt(value) + " m";
                }

                return Math.round(value / 1000, 2) + " km";

            }

        }

    });


});
