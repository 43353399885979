define('sprd/model/Checkout',["sprd/data/SprdModel", "sprd/model/PaymentType", "sprd/entity/Payment", "underscore", "rAppid", "flow"], function (SprdModel, PaymentType, Payment, _, rAppid, flow) {

    return SprdModel.inherit("sprd.model.Checkout", {

        defaults: {
            paymentType: null,
            payment: null,
            returnUrl: null,
            fingerPrint: null
        },

        $isDependentObject: true,

        schema: {
            paymentType: PaymentType,
            payment: Payment,
            returnUrl: String
        },

        save: function (callback) {

            var self = this,
                payment = this.$.payment;

            flow()
                .seq(function (cb) {
                    if (!payment) {
                        return cb();
                    }
                    payment._beforeCompose(cb);
                })
                .seq("result", function (cb) {
                    self.doFormPost();
                    cb();
                })
                .exec(function (err, data) {
                    callback && callback(err, data.result);
                });


        },

        doFormPost: function () {

            var dataSource = this.$context.$dataSource,
                parameter = {};

            var url = dataSource._buildUriForResource(this);

            _.defaults(parameter, this.$context.getQueryParameters(), dataSource.getQueryParameters("put", this));
            delete parameter.mediaType;

            url += "?" + rAppid.createQueryString(parameter);

            var processor = dataSource.getProcessorForModel(this);
            var formatProcessor = dataSource.getFormatProcessor("save", this);

            var checkoutData = processor.compose(this, "save");

            var fingerPrint = this.$.fingerPrint;
            if (fingerPrint && checkoutData.payment) {
                checkoutData.payment.fingerPrint = fingerPrint;
            }

            var data = formatProcessor.serialize(checkoutData);

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", url);

            if (this.inIframe()) {
                // do a navigation on the top frame, this
                // could fail if a sandbox is defined without allow-top-navigation.

                // to be more secure, we could just add this _top target, where we know
                // that the loading checkout don't allow to run within an iframe
                // (X-Frame-Options, see https://developer.mozilla.org/en-US/docs/Web/HTTP/X-Frame-Options),
                // which is the case for e.g. paypal
                form.setAttribute("target", "_top");
            }

            var valueField = document.createElement("input");
            valueField.setAttribute("type", "hidden");
            valueField.setAttribute("name", "value");
            valueField.setAttribute("enctype", "multipart/form-data");
            valueField.setAttribute("value", data);

            form.appendChild(valueField);
            document.body.appendChild(form);
            form.submit();
        },

        inIframe: function () {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },

        compose: function () {
            var ret = this.callBase();

            ret.payment = ret.payment || {};
            ret.paymentType = ret.paymentType || {};

            return ret;
        }

    });

});
