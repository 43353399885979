define('checkout/view/FieldClass',["js/ui/FieldClass", "sprd/lib/ScrollIntoView"], function (FieldClass, ScrollIntoView) {

    var fields = [],
        stageBound = false;

    function scrollToTopMostInvalidField(focusFirst) {
        var minTop = null,
            topMostField = null,
            i;

        for (i = 0; i < fields.length; i++) {
            var field = fields[i];

            if (field && field.$el && field.$.$error) {
                var top = field.$el.getBoundingClientRect().top;
                if (top && (minTop === null || top < minTop)) {
                    minTop = top;
                    topMostField = field;
                }
            }
        }

        if (topMostField) {
            focusFirst && topMostField.focusFirstInput();

            var el = topMostField.$el;

            if (el.querySelector) {
                el = el.querySelector(".error") || el;
            } else {
                for (i = 0; i < el.childNodes.length; i++) {
                    var obj = el.childNodes[i];
                    if (obj.className == "error") {
                        el = obj;
                        break;
                    }
                }
            }

            ScrollIntoView(el, -50, function () {
                // set focus here, if possible
                !focusFirst && topMostField.focusFirstInput();
            });

        }
    }

    return FieldClass.inherit({

        defaults: {
            valid: null,
            focused: false,
            validationTimeout: 1000,
            autoValidate: true,
            useContentPlaceHolder: false
        },

        events: ['on:blur'],

        ctor: function () {
            this.callBase();
            fields.push(this);

            if (!stageBound) {
                this.$stage.$bus.bind("Checkout.ScrollToInvalidField", function (e) {

                    var focus = true;

                    if (e && e.$ && e.$.hasOwnProperty("focus")) {
                        focus = e.$.focus;
                    }

                    focus && scrollToTopMostInvalidField(focus);
                }, this);
                stageBound = true;
            }
        },

        _onDomAdded: function () {
            this.callBase();

            // since FF and some other browser don't trigger the change event when fields are autofilled
            // we poll here for changes on the input field to set the state of the field wrapper to selected
            // which brings the label up
            var self = this;
            setTimeout(function () {
                self.checkValueBinding();
            }, 100);
        },

        checkValueBinding: function () {
            if (this.$firstInput) {
                if (!this.$.value && this.$firstInput.$el.value) {
                    this.set({
                        "selected": true,
                        'value': this.$firstInput.$el.value
                    });
                }
            }
        },

        _renderContentChildren: function () {
            this.callBase();

            var firstInput = this.$firstInput;

            if (firstInput && !/radio|checkobox/i.test(firstInput.$.type)) {
                firstInput.bind('change', function (e) {
                    if (e.target.$el.value != '') {
                        self.set('selected', true);
                    }
                });

                firstInput.bind('on:change', function (e) {
                    // fix for android autofill
                    if (e.target.$el.value != e.target.$.value) {
                        e.target.set('value', e.target.$el.value);
                    }
                    self.validateValue();
                });

                firstInput.bind('on:input', function (e) {
                    if (self.$.value == "" && e.target.$el.value != "") {
                        self.set('value', e.target.$el.value);
                    }
                });

                if (firstInput.$el && firstInput.$el.value) {
                    this.set('selected', true);
                }
                // only for input that are not radio or checkobox and select and textareas
                var self = this;
                firstInput.bind('on:focus', function () {
                    self.set('selected', true);
                    self.set('focused', true);
                });

                firstInput.bind('on:blur', function (e) {
                    if (e.target.$el.value == '') {
                        // this is needed for iOS devices ...
                        setTimeout(function () {
                            self.set('selected', false);
                        }, 100);
                    } else if (e.target.$.value) {
                        self.transformValue();
                    }

                    self.set('focused', false);
                    self.validateValue();

                    self.trigger('on:blur', e, self);
                });

                firstInput.bind('on:keyup', function (e) {
                    if (self.$.error) {
                        self._debounceFunctionCall(self.validateValue, "validate", self.$.validationTimeout, self);
                    }
                });
            }
        },

        validateValue: function () {
            if (this.$.autoValidate) {
                this._validateValue();
            }
        },

        _validateValue: function() {
            var valueBindings = this.$bindings.value;
            if (valueBindings && valueBindings.length) {
                var path = valueBindings[0].$.path;
                var self = this;
                var scope = valueBindings[0].$.scope.get(path.slice(0, path.length - 1));
                if (scope && scope.validate instanceof Function) {
                    var field = path[path.length - 1].name;
                    scope.validate({
                        fields: [field]
                    }, function(err, validationErrors) {
                        if (!err) {
                            self._render$error(validationErrors ? validationErrors[0] : null)
                        }
                    });
                }
            }
        },

        transformValue: function () {
            var valueBindings = this.$bindings.value;
            if (valueBindings && valueBindings.length) {
                var path = valueBindings[0].$.path;
                var scope = valueBindings[0].$.scope.get(path.slice(0, path.length - 1));
                if (scope && scope.getTransformedValue instanceof Function) {
                    var field = path[path.length - 1].name;
                    scope.set(field, scope.getTransformedValue(field));
                }
            }
        },

        findFirstInput: function (item) {
            if (item.$tagName == "select") {
                return item;
            }
            return this.callBase();
        },

        _render$error: function (error, oldError) {
            this.callBase(error, oldError);
            if (!error && this.$.value != "" && this.$.value != null) {
                this.addClass('valid');
            } else if (error) {
                this.removeClass('valid');
            }
        },

        _renderValue: function (value) {
            if (!value) {
                this.removeClass('valid');
            }
        },
        _renderFocused: function (focused) {
            if (focused) {
                this.addClass("focused");
            } else {
                this.removeClass("focused");
            }
        },

        showError: function(useContentPlaceHolder) {
            return this.$.error && (useContentPlaceHolder == this.$.useContentPlaceHolder)
        }.onChange("error")
    });

});
