define('js/lib/parser', function () { var exports = (typeof(exports) === "undefined" ? this : exports);
exports.parser = (function () {
    /*
     * Generated by PEG.js 0.8.0.
     *
     * http://pegjs.majda.cz/
     */

    function peg$subclass(child, parent) {
        function ctor() {
            this.constructor = child;
        }

        ctor.prototype = parent.prototype;
        child.prototype = new ctor();
    }

    function SyntaxError(message, expected, found, offset, line, column) {
        this.message = message;
        this.expected = expected;
        this.found = found;
        this.offset = offset;
        this.line = line;
        this.column = column;

        this.name = "SyntaxError";
    }

    peg$subclass(SyntaxError, Error);

    function parse(input) {
        var options = arguments.length > 1 ? arguments[1] : {},

            peg$FAILED = {},

            peg$startRuleFunctions = { varName: peg$parsevarName, string: peg$parsestring, number: peg$parsenumber, float: peg$parsefloat, boolean: peg$parseboolean, index: peg$parseindex, parameter: peg$parseparameter, parameterArray: peg$parseparameterArray, var: peg$parsevar, fnc: peg$parsefnc, pathElement: peg$parsepathElement, path: peg$parsepath, binding: peg$parsebinding, twoWayBinding: peg$parsetwoWayBinding, staticBinding: peg$parsestaticBinding, text: peg$parsetext, eventHandler: peg$parseeventHandler },
            peg$startRuleFunction = peg$parsevarName,

            peg$c0 = [],
            peg$c1 = peg$FAILED,
            peg$c2 = /^[^{}.,()[\]|\n\r\t ]/,
            peg$c3 = { type: "class", value: "[^{}.,()[\\]|\\n\\r\\t ]", description: "[^{}.,()[\\]|\\n\\r\\t ]" },
            peg$c4 = function (end) {
                return end.join("");
            },
            peg$c5 = /^[']/,
            peg$c6 = { type: "class", value: "[']", description: "[']" },
            peg$c7 = /^[^']/,
            peg$c8 = { type: "class", value: "[^']", description: "[^']" },
            peg$c9 = function (s) {
                return s.join("");
            },
            peg$c10 = null,
            peg$c11 = "-",
            peg$c12 = { type: "literal", value: "-", description: "\"-\"" },
            peg$c13 = /^[0-9]/,
            peg$c14 = { type: "class", value: "[0-9]", description: "[0-9]" },
            peg$c15 = function (n, digits) {
                return parseInt(digits.join(""), 10) * (n ? -1 : 1);
            },
            peg$c16 = ".",
            peg$c17 = { type: "literal", value: ".", description: "\".\"" },
            peg$c18 = function (n, d2) {
                return parseFloat(n + "." + d2.join(""), 10)
            },
            peg$c19 = "true",
            peg$c20 = { type: "literal", value: "true", description: "\"true\"" },
            peg$c21 = function () {
                return true;
            },
            peg$c22 = "false",
            peg$c23 = { type: "literal", value: "false", description: "\"false\"" },
            peg$c24 = function () {
                return false;
            },
            peg$c25 = "null",
            peg$c26 = { type: "literal", value: "null", description: "\"null\"" },
            peg$c27 = function () {
                return null;
            },
            peg$c28 = "[",
            peg$c29 = { type: "literal", value: "[", description: "\"[\"" },
            peg$c30 = "]",
            peg$c31 = { type: "literal", value: "]", description: "\"]\"" },
            peg$c32 = function (n) {
                return n;
            },
            peg$c33 = /^[ \n\r\t]/,
            peg$c34 = { type: "class", value: "[ \\n\\r\\t]", description: "[ \\n\\r\\t]" },
            peg$c35 = function (v) {
                return v;
            },
            peg$c36 = ",",
            peg$c37 = { type: "literal", value: ",", description: "\",\"" },
            peg$c38 = function (r) {
                return r;
            },
            peg$c39 = function (s, rp) {
                if (s && s.name === "null") {
                    s = null;
                }
                ;
                for (var i = 0; i < rp.length; i++) {
                    if (rp[i] && rp[i][0] && rp[i][0].name === "null") {
                        rp[i] = null;
                    }
                    ;
                }
                ;
                return typeof s !== "undefined" ? [s].concat(rp) : [];
            },
            peg$c40 = "",
            peg$c41 = function () {
                return []
            },
            peg$c42 = function (n, ind) {
                return {name: n, type: 'var', index: ind};
            },
            peg$c43 = "(",
            peg$c44 = { type: "literal", value: "(", description: "\"(\"" },
            peg$c45 = ")",
            peg$c46 = { type: "literal", value: ")", description: "\")\"" },
            peg$c47 = function (n, pa, ind) {
                return {name: n, type: 'fnc', parameter: pa, index: ind  };
            },
            peg$c48 = function (ind) {
                return {type: 'index', index: ind };
            },
            peg$c49 = function (s, rp) {
                return [s].concat(rp);
            },
            peg$c50 = "{",
            peg$c51 = { type: "literal", value: "{", description: "\"{\"" },
            peg$c52 = "}",
            peg$c53 = { type: "literal", value: "}", description: "\"}\"" },
            peg$c54 = function (path) {
                return path ? {path: path, type: 'normal'} : false;
            },
            peg$c55 = "{{",
            peg$c56 = { type: "literal", value: "{{", description: "\"{{\"" },
            peg$c57 = "|",
            peg$c58 = { type: "literal", value: "|", description: "\"|\"" },
            peg$c59 = function (p) {
                return p;
            },
            peg$c60 = "}}",
            peg$c61 = { type: "literal", value: "}}", description: "\"}}\"" },
            peg$c62 = function (path, a, b) {
                return path ? {path: path, type: 'twoWay', transform: a || false, transformBack: b || false} : false;
            },
            peg$c63 = "$",
            peg$c64 = { type: "literal", value: "$", description: "\"$\"" },
            peg$c65 = function (b) {
                b.type = 'static';
                return b;
            },
            peg$c66 = function (t) {
                return [t];
            },
            peg$c67 = { type: "any", description: "any character" },
            peg$c68 = function (b) {
                var obj, prevObj;
                for (var i = b.length - 1; i >= 0; i--) {
                    obj = b[i];
                    if (i > 0) {
                        prevObj = b[i - 1];
                        if (typeof(obj) == "string" && typeof(prevObj) == "string") {
                            b.splice(i, 1);
                            b[i - 1] = prevObj + obj;
                        }
                    }

                }
                return b;
            },

            peg$currPos = 0,
            peg$reportedPos = 0,
            peg$cachedPos = 0,
            peg$cachedPosDetails = { line: 1, column: 1, seenCR: false },
            peg$maxFailPos = 0,
            peg$maxFailExpected = [],
            peg$silentFails = 0,

            peg$result;

        if ("startRule" in options) {
            if (!(options.startRule in peg$startRuleFunctions)) {
                throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
            }

            peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
        }

        function text() {
            return input.substring(peg$reportedPos, peg$currPos);
        }

        function offset() {
            return peg$reportedPos;
        }

        function line() {
            return peg$computePosDetails(peg$reportedPos).line;
        }

        function column() {
            return peg$computePosDetails(peg$reportedPos).column;
        }

        function expected(description) {
            throw peg$buildException(
                null,
                [
                    { type: "other", description: description }
                ],
                peg$reportedPos
            );
        }

        function error(message) {
            throw peg$buildException(message, null, peg$reportedPos);
        }

        function peg$computePosDetails(pos) {
            function advance(details, startPos, endPos) {
                var p, ch;

                for (p = startPos; p < endPos; p++) {
                    ch = input.charAt(p);
                    if (ch === "\n") {
                        if (!details.seenCR) {
                            details.line++;
                        }
                        details.column = 1;
                        details.seenCR = false;
                    } else if (ch === "\r" || ch === "\u2028" || ch === "\u2029") {
                        details.line++;
                        details.column = 1;
                        details.seenCR = true;
                    } else {
                        details.column++;
                        details.seenCR = false;
                    }
                }
            }

            if (peg$cachedPos !== pos) {
                if (peg$cachedPos > pos) {
                    peg$cachedPos = 0;
                    peg$cachedPosDetails = { line: 1, column: 1, seenCR: false };
                }
                advance(peg$cachedPosDetails, peg$cachedPos, pos);
                peg$cachedPos = pos;
            }

            return peg$cachedPosDetails;
        }

        function peg$fail(expected) {
            if (peg$currPos < peg$maxFailPos) {
                return;
            }

            if (peg$currPos > peg$maxFailPos) {
                peg$maxFailPos = peg$currPos;
                peg$maxFailExpected = [];
            }

            peg$maxFailExpected.push(expected);
        }

        function peg$buildException(message, expected, pos) {
            function cleanupExpected(expected) {
                var i = 1;

                expected.sort(function (a, b) {
                    if (a.description < b.description) {
                        return -1;
                    } else if (a.description > b.description) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                while (i < expected.length) {
                    if (expected[i - 1] === expected[i]) {
                        expected.splice(i, 1);
                    } else {
                        i++;
                    }
                }
            }

            function buildMessage(expected, found) {
                function stringEscape(s) {
                    function hex(ch) {
                        return ch.charCodeAt(0).toString(16).toUpperCase();
                    }

                    return s
                        .replace(/\\/g, '\\\\')
                        .replace(/"/g, '\\"')
                        .replace(/\x08/g, '\\b')
                        .replace(/\t/g, '\\t')
                        .replace(/\n/g, '\\n')
                        .replace(/\f/g, '\\f')
                        .replace(/\r/g, '\\r')
                        .replace(/[\x00-\x07\x0B\x0E\x0F]/g, function (ch) {
                            return '\\x0' + hex(ch);
                        })
                        .replace(/[\x10-\x1F\x80-\xFF]/g, function (ch) {
                            return '\\x' + hex(ch);
                        })
                        .replace(/[\u0180-\u0FFF]/g, function (ch) {
                            return '\\u0' + hex(ch);
                        })
                        .replace(/[\u1080-\uFFFF]/g, function (ch) {
                            return '\\u' + hex(ch);
                        });
                }

                var expectedDescs = new Array(expected.length),
                    expectedDesc, foundDesc, i;

                for (i = 0; i < expected.length; i++) {
                    expectedDescs[i] = expected[i].description;
                }

                expectedDesc = expected.length > 1
                    ? expectedDescs.slice(0, -1).join(", ")
                    + " or "
                    + expectedDescs[expected.length - 1]
                    : expectedDescs[0];

                foundDesc = found ? "\"" + stringEscape(found) + "\"" : "end of input";

                return "Expected " + expectedDesc + " but " + foundDesc + " found.";
            }

            var posDetails = peg$computePosDetails(pos),
                found = pos < input.length ? input.charAt(pos) : null;

            if (expected !== null) {
                cleanupExpected(expected);
            }

            return new SyntaxError(
                    message !== null ? message : buildMessage(expected, found),
                expected,
                found,
                pos,
                posDetails.line,
                posDetails.column
            );
        }

        function peg$parsevarName() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = [];
            if (peg$c2.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c3);
                }
            }
            if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    if (peg$c2.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c3);
                        }
                    }
                }
            } else {
                s1 = peg$c1;
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c4(s1);
            }
            s0 = s1;

            return s0;
        }

        function peg$parsestring() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (peg$c5.test(input.charAt(peg$currPos))) {
                s1 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c6);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                if (peg$c7.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c8);
                    }
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    if (peg$c7.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c8);
                        }
                    }
                }
                if (s2 !== peg$FAILED) {
                    if (peg$c5.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c6);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c9(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsenumber() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 45) {
                s1 = peg$c11;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c12);
                }
            }
            if (s1 === peg$FAILED) {
                s1 = peg$c10;
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                if (peg$c13.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c14);
                    }
                }
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        if (peg$c13.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c14);
                            }
                        }
                    }
                } else {
                    s2 = peg$c1;
                }
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c15(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsefloat() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = peg$parsenumber();
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 46) {
                    s2 = peg$c16;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c17);
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    if (peg$c13.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c14);
                        }
                    }
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            if (peg$c13.test(input.charAt(peg$currPos))) {
                                s4 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c14);
                                }
                            }
                        }
                    } else {
                        s3 = peg$c1;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c18(s1, s3);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parseboolean() {
            var s0, s1;

            s0 = peg$currPos;
            if (input.substr(peg$currPos, 4) === peg$c19) {
                s1 = peg$c19;
                peg$currPos += 4;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c20);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c21();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 5) === peg$c22) {
                    s1 = peg$c22;
                    peg$currPos += 5;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c23);
                    }
                }
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c24();
                }
                s0 = s1;
            }

            return s0;
        }

        function peg$parsenull() {
            var s0, s1;

            s0 = peg$currPos;
            if (input.substr(peg$currPos, 4) === peg$c25) {
                s1 = peg$c25;
                peg$currPos += 4;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c26);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c27();
            }
            s0 = s1;

            return s0;
        }

        function peg$parseindex() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 91) {
                s1 = peg$c28;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c29);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsenumber();
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 93) {
                        s3 = peg$c30;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c31);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c32(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsews() {
            var s0;

            if (peg$c33.test(input.charAt(peg$currPos))) {
                s0 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c34);
                }
            }

            return s0;
        }

        function peg$parseparameter() {
            var s0, s1, s2, s3, s4;

            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parsews();
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parsews();
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsenull();
                if (s2 === peg$FAILED) {
                    s2 = peg$parsestring();
                    if (s2 === peg$FAILED) {
                        s2 = peg$parseboolean();
                        if (s2 === peg$FAILED) {
                            s2 = peg$parsefloat();
                            if (s2 === peg$FAILED) {
                                s2 = peg$parsenumber();
                                if (s2 === peg$FAILED) {
                                    s2 = peg$parsestaticBinding();
                                    if (s2 === peg$FAILED) {
                                        s2 = peg$parsepath();
                                    }
                                }
                            }
                        }
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$parsews();
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$parsews();
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c35(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parseparameterArray() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parseparameter();
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 44) {
                    s4 = peg$c36;
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c37);
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parseparameter();
                    if (s5 !== peg$FAILED) {
                        peg$reportedPos = s3;
                        s4 = peg$c38(s5);
                        s3 = s4;
                    } else {
                        peg$currPos = s3;
                        s3 = peg$c1;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$c1;
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    s3 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s4 = peg$c36;
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c37);
                        }
                    }
                    if (s4 !== peg$FAILED) {
                        s5 = peg$parseparameter();
                        if (s5 !== peg$FAILED) {
                            peg$reportedPos = s3;
                            s4 = peg$c38(s5);
                            s3 = s4;
                        } else {
                            peg$currPos = s3;
                            s3 = peg$c1;
                        }
                    } else {
                        peg$currPos = s3;
                        s3 = peg$c1;
                    }
                }
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c39(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$c40;
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c41();
                }
                s0 = s1;
            }

            return s0;
        }

        function peg$parsevar() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$parsevarName();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseindex();
                if (s2 === peg$FAILED) {
                    s2 = peg$c10;
                }
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c42(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsefnc() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parsevarName();
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 40) {
                    s2 = peg$c43;
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c44);
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parseparameterArray();
                    if (s3 === peg$FAILED) {
                        s3 = peg$c10;
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                            s4 = peg$c45;
                            peg$currPos++;
                        } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c46);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parseindex();
                            if (s5 === peg$FAILED) {
                                s5 = peg$c10;
                            }
                            if (s5 !== peg$FAILED) {
                                peg$reportedPos = s0;
                                s1 = peg$c47(s1, s3, s5);
                                s0 = s1;
                            } else {
                                peg$currPos = s0;
                                s0 = peg$c1;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$c1;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsepathElement() {
            var s0, s1;

            s0 = peg$currPos;
            s1 = peg$parseindex();
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c48(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$parsefnc();
                if (s0 === peg$FAILED) {
                    s0 = peg$parsevar();
                }
            }

            return s0;
        }

        function peg$parsepath() {
            var s0, s1, s2, s3, s4, s5;

            s0 = peg$currPos;
            s1 = peg$parsepathElement();
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 46) {
                    s4 = peg$c16;
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c17);
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parsepathElement();
                    if (s5 !== peg$FAILED) {
                        peg$reportedPos = s3;
                        s4 = peg$c38(s5);
                        s3 = s4;
                    } else {
                        peg$currPos = s3;
                        s3 = peg$c1;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$c1;
                }
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    s3 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 46) {
                        s4 = peg$c16;
                        peg$currPos++;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c17);
                        }
                    }
                    if (s4 !== peg$FAILED) {
                        s5 = peg$parsepathElement();
                        if (s5 !== peg$FAILED) {
                            peg$reportedPos = s3;
                            s4 = peg$c38(s5);
                            s3 = s4;
                        } else {
                            peg$currPos = s3;
                            s3 = peg$c1;
                        }
                    } else {
                        peg$currPos = s3;
                        s3 = peg$c1;
                    }
                }
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c49(s1, s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsebinding() {
            var s0, s1, s2, s3;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 123) {
                s1 = peg$c50;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c51);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsepath();
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 125) {
                        s3 = peg$c52;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c53);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c54(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsetwoWayBinding() {
            var s0, s1, s2, s3, s4, s5, s6, s7, s8;

            s0 = peg$currPos;
            s1 = [];
            s2 = peg$parsews();
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parsews();
            }
            if (s1 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c55) {
                    s2 = peg$c55;
                    peg$currPos += 2;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c56);
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsepath();
                    if (s3 !== peg$FAILED) {
                        s4 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 124) {
                            s5 = peg$c57;
                            peg$currPos++;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c58);
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parsepath();
                            if (s6 !== peg$FAILED) {
                                peg$reportedPos = s4;
                                s5 = peg$c59(s6);
                                s4 = s5;
                            } else {
                                peg$currPos = s4;
                                s4 = peg$c1;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$c1;
                        }
                        if (s4 === peg$FAILED) {
                            s4 = peg$c10;
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 124) {
                                s6 = peg$c57;
                                peg$currPos++;
                            } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c58);
                                }
                            }
                            if (s6 !== peg$FAILED) {
                                s7 = peg$parsepath();
                                if (s7 !== peg$FAILED) {
                                    peg$reportedPos = s5;
                                    s6 = peg$c59(s7);
                                    s5 = s6;
                                } else {
                                    peg$currPos = s5;
                                    s5 = peg$c1;
                                }
                            } else {
                                peg$currPos = s5;
                                s5 = peg$c1;
                            }
                            if (s5 === peg$FAILED) {
                                s5 = peg$c10;
                            }
                            if (s5 !== peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c60) {
                                    s6 = peg$c60;
                                    peg$currPos += 2;
                                } else {
                                    s6 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c61);
                                    }
                                }
                                if (s6 !== peg$FAILED) {
                                    s7 = [];
                                    s8 = peg$parsews();
                                    while (s8 !== peg$FAILED) {
                                        s7.push(s8);
                                        s8 = peg$parsews();
                                    }
                                    if (s7 !== peg$FAILED) {
                                        peg$reportedPos = s0;
                                        s1 = peg$c62(s3, s4, s5);
                                        s0 = s1;
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$c1;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$c1;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$c1;
                            }
                        } else {
                            peg$currPos = s0;
                            s0 = peg$c1;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$c1;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsestaticBinding() {
            var s0, s1, s2;

            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 36) {
                s1 = peg$c63;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c64);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsebinding();
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c65(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$c1;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$c1;
            }

            return s0;
        }

        function peg$parsetext() {
            var s0, s1, s2;

            s0 = peg$currPos;
            s1 = peg$parsetwoWayBinding();
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c66(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = [];
                s2 = peg$parsestaticBinding();
                if (s2 === peg$FAILED) {
                    s2 = peg$parsebinding();
                    if (s2 === peg$FAILED) {
                        if (input.length > peg$currPos) {
                            s2 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c67);
                            }
                        }
                    }
                }
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    s2 = peg$parsestaticBinding();
                    if (s2 === peg$FAILED) {
                        s2 = peg$parsebinding();
                        if (s2 === peg$FAILED) {
                            if (input.length > peg$currPos) {
                                s2 = input.charAt(peg$currPos);
                                peg$currPos++;
                            } else {
                                s2 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c67);
                                }
                            }
                        }
                    }
                }
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c68(s1);
                }
                s0 = s1;
            }

            return s0;
        }

        function peg$parseeventHandler() {
            var s0;

            s0 = peg$parsefnc();
            if (s0 === peg$FAILED) {
                s0 = peg$parsevar();
            }

            return s0;
        }

        peg$result = peg$startRuleFunction();

        if (peg$result !== peg$FAILED && peg$currPos === input.length) {
            return peg$result;
        } else {
            if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                peg$fail({ type: "end", description: "end of input" });
            }

            throw peg$buildException(null, peg$maxFailExpected, peg$maxFailPos);
        }
    }

    return {
        SyntaxError: SyntaxError,
        parse: parse
    };
})();
; return parser; });
