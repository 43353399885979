define('checkout/view/payment/IdealClass',[
    "checkout/view/payment/PaymentView",
    "checkout/manager/PaymentTypeManager",
    "checkout/manager/AdyenManager"
], function (PaymentView, PaymentTypeManager, AdyenManager) {
    return PaymentView.inherit("checkout.view.IdealClass", {

        defaults: {
            container: null
        },

        inject: {
            paymentTypeManager: PaymentTypeManager,
            adyenManager: AdyenManager
        },

        _renderContainer: function () {
            var self = this;
            var baseConfig = {
                onChange: function (state, component) {
                    self.$.payment.setIssuer(component.state.data.issuer);
                }
            };
            this.$.adyenManager.initializeWebComponent(this.$.payment, baseConfig, this.getAdditionalOptions(), this.$.container.$el);
        },

        getAdditionalOptions: function () {
            var configuration = this.$.payment.getWebComponentConfiguration(),
                options = {};

            if (configuration.$ && configuration.$.issuers && configuration.$.issuers.length > 0) {
                options.issuer = configuration.$.issuers[0].id;
            }

            return options;
        }
    });
});
