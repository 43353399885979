define('checkout/view/payment/AdyenPayPalWebComponentClass',["checkout/view/ViewBase",
    "flow",
    "checkout/manager/PaymentTypeManager",
    "checkout/manager/AdyenManager"
], function (ViewBase, flow, PaymentTypeManager, AdyenManager) {

    return ViewBase.inherit("checkout.view.AdyenPayPalWebComponentClass", {
        defaults: {
            basket: "{root.basket}",
            payment: null,
            loading: true,
            container: null
        },

        inject: {
            paymentTypeManager: PaymentTypeManager,
            adyenManager: AdyenManager
        },

        loadingClass: function () {
            return this.$.loading ? "loading" : "";
        }.onChange("loading"),

        _onDomAdded: function () {
            this.$.adyenManager.updateComponent(this.$.payment);
        },

        _renderContainer: function () {
            var options = {
                amount: {currency: this.$.basket.$.currency.$.isoCode},
                style: {
                    label: "pay",
                    height: 40
                },
                blockPayPalPayLaterButton: true,
                onInit: this.handleOnInit.bind(this)
            };

            this.$.adyenManager.initializeWebComponent(this.$.payment, {}, options, this.$.container.$el, this.setLoading.bind(this));
        },

        setLoading: function (loading) {
            if (this.$.loading !== loading) {
                this.set("loading", loading);
            }
        },

        handleOnInit: function (data, actions) {
            actions.enable();
            this.setLoading(false);
        }
    });

});
