define('checkout/manager/FingerPrintManager',["js/core/Component", "flow"], function(Component, flow) {

    var fingerPrintLibrary = "https://live.adyen.com/hpp/js/df.js?v=" + (new Date()).toISOString().replace(/-|T.*/g, "");

    return Component.inherit("checkout.manager.FingerPrintManager", {

        init: function(callback) {


            this.synchronizeFunctionCall(function(callback) {

                flow()
                    .seq(function(cb) {
                        require([fingerPrintLibrary], function() {
                            cb();
                        }, cb);
                    })
                    .seq(function() {

                        var dfInitDS = window.dfInitDS;

                        if (dfInitDS) {
                            dfInitDS();
                        } else {
                            throw "fingerprint not available";
                        }
                    })
                    .exec(callback);

            }, "init", callback, this);

        },

        getFingerPrint: function() {

            var dfInitDS     = window.dfInitDS,
                dfGetProp    = window.dfGetProp,
                dfGetEntropy = window.dfGetEntropy,
                dfHashConcat = window.dfHashConcat;

            if (dfInitDS && dfGetEntropy && dfHashConcat && dfGetProp) {
                try {
                    var a = dfGetProp();
                    var c = dfHashConcat(a);
                    var h = dfGetEntropy();

                    return c + ":" + h;
                } catch (e) {
                    console.error(e);
                }
            }

            return null;
        }


    });

});
