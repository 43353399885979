define('checkout/view/BasketPriceSummaryClass',["checkout/view/ViewBase", "checkout/manager/ConfigurationManager", "checkout/manager/DeliveryEtaManager", "checkout/bindable/Root"], function(ViewBase, ConfigurationManager, DeliveryEtaManager, Root) {

    var AUD_CURRENCY_ID = 17;

    return ViewBase.inherit("checkout.view.BasketPriceSummaryClass", {
        defaults: {
            shippingTypeIsLink: false,
            delivery: null,
            basket: null,
            showShippingTypeName: false,
            showFreeShippingInfo: false,

            priceType: "{configurationManager.priceType()}",

            shippingRegion: "{delivery.shipping.type.getShippingCountryByCode(delivery.shipping.address.country.code).shippingRegion}",
            freeShippingFrom: null,
            showDeliveryEta: false,

            totalQuantity: "{basket.totalItemsCount()}",
            basketValue: "{basket.totalVatIncluded()}"
        },

        inject: {
            configurationManager: ConfigurationManager,
            deliveryEtaManager: DeliveryEtaManager,
            root: Root
        },

        events: [
            "on:editShippingType"
        ],

        canOrder: function () {
            var updating = this.get("basketManager.updating");

            return !updating && this.get("delivery.shipping.shippingType");
        }.onChange("basketManager.updating", "delivery.shipping.shippingType"),

        goToShippingStep: function () {
            this.trigger("on:editShippingType");
        },

        multiply: function(a, b) {
            return a * b;
        },

        and: function(a, b) {
            return a && b;
        },

        eql: function(a, b) {
            return a == b
        },

        showFreeShipping: function(orderValue) {
            var freeShippingFrom = this.$.freeShippingFrom;
            return this.$.showFreeShippingInfo && freeShippingFrom && orderValue < freeShippingFrom.$.vatIncluded;
        }.onChange("freeShippingFrom", "showFreeShippingInfo"),

        _commitShippingRegion: function(shippingRegion) {

            var shippingCosts = shippingRegion ? shippingRegion.$.shippingCosts : null;
            this.set("freeShippingFrom", this.calculateMinBasketValueForFreeShipping(shippingCosts));
        },

        calculateMinBasketValueForFreeShipping: function(shippingCosts) {

            if (!shippingCosts) {
                return null;
            }

            for (var i = 0; i < shippingCosts.$items.length; i++) {
                var shippingCost    = shippingCosts.$items[i],
                    orderValueRange = shippingCost.$.orderValueRange;

                if (orderValueRange.$.from != 0 && orderValueRange.$.to != 0.01 && shippingCost.$.cost.$.vatIncluded == 0) {
                    // found the free shipping value

                    return shippingCost.$.cost.clone().set({
                        vatIncluded: orderValueRange.$.from
                    });
                }
            }

        },

        isPlatform: function (platform) {
            return this.PARAMETER().platform.toUpperCase() == platform.toUpperCase();
        },

        showSalesTax: function () {
            return this.PARAMETER().platform.toUpperCase() == "NA" && this.get("delivery.shipping.address.country.code") != "AU"
        }.onChange("delivery.shipping.address.country"),

        taxLabel: function () {
            return this.get("delivery.shipping.address.country.code") === "NZ" ? "basket.gst" : "basket.salesTax";
        }.onChange("delivery.shipping.address.country"),

        totalTranslationKey: function () {

            var isNA = this.PARAMETER().platform.toUpperCase() == "NA";
            var shipToAU = this.get("delivery.shipping.address.country.code") == "AU";

            var isAustralianDollars = this.get("basket.currency.id") == AUD_CURRENCY_ID;

            if (isNA && shipToAU) {

                var GST_MAX_VALUE = 1000;
                if (isAustralianDollars && this.$.basketValue > GST_MAX_VALUE) {
                    return 'basket.totalExclGst';
                }

                return 'basket.totalInclGst';
            }

            return 'basket.total';
        }.onChange("delivery.shipping.address.country", "basket.currency", "basketValue"),

        showGstInfo: function() {

            return this.PARAMETER().platform.toUpperCase() == "NA" &&
                this.get("delivery.shipping.address.country.code") == "AU" &&
                this.get("basket.currency.id") != AUD_CURRENCY_ID;

        }.onChange("delivery.shipping.address.country", "basket.currency")

    });
});
