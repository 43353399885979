
define('json!checkout/env/default',[],function () { return {
    "enableErrorTracking": true,
    "debugTracking": false,
    "useSpreadshirtDomain": true,
    "useVirtualDomain": false,
    "showVersion": false,
    "raygunCheckConsent": true
};});

