define('checkout/view/CouponViewClass',["checkout/view/RootViewBase", "sprd/manager/ApiBasketManager", "js/core/Bus"], function (RootViewBase, ApiBasketManager, Bus) {

    return RootViewBase.inherit({

        defaults: {
            coupons: "{basket.coupons}",
            componentClass: "coupon",
            newCoupon: null,
            couponCode: "",
            couponActive: "{_couponActive()}"
        },

        inject: {
            basketManager: ApiBasketManager,
            bus: Bus
        },

        _couponActive: function() {
            var coupons = this.$.coupons;
            return coupons && coupons.$items.length > 0;
        }.onChange("coupons").on(["basketManager", "on:couponApplied"], ["basketManager", "on:couponRemoved"], ["basketManager", "on:couponsLoaded"]),

        _commitBasket: function(basket) {
            if (basket && !this.couponResetted) {
                this._resetNewCoupon();
                this.couponResetted = true;
            }
        },

        isMode: function (mode) {
            return this.$.mode === mode;
        }.onChange("mode"),

        getCode: function (coupon, i) {
            return coupon.$.couponCode || "COUPON #" + (i + 1);
        },

        enterCoupon: function () {
            this.$.couponField.focusFirstInput();
        },

        removeCoupon: function (coupon) {
            this.$.basketManager.removeCoupon(coupon);
        },

        _resetNewCoupon: function () {
            var nc = this.$.basket.getCollection('coupons').createItem();
            this.set('newCoupon', nc);
        },

        onCouponSubmit: function (e) {
            e && e.preventDefault();

            var basketManager = this.$.basketManager;
            var newCoupon = this.$.newCoupon;
            var locale = this.PARAMETER().locale;
            var self = this;
            if (newCoupon.$.code) {
                basketManager.applyCoupon(newCoupon, locale, function () {
                    if (newCoupon.isValid()) {
                        self._resetNewCoupon();
                    } else {
                        self.$.bus.trigger("Checkout.ScrollToInvalidField", {
                            focus: false
                        });
                    }
                });
            }

        },
        onBlur: function () {
            if (!this.get('newCoupon.code')) {
                this.$.newCoupon.clearErrors();
            } else {
                this.onCouponSubmit();
            }
        },

        trim: function(a) {
            return (a || "").replace(/^\s+|\s+$/g, "");
        },

        couponCannotBeUsed: function() {
            var parameter = this.PARAMETER(),
                result = parameter.result,
                errorCode = parameter.errorCode;

            return result == "FAILED_ERROR" && errorCode == "87020";
        }

    });


});
