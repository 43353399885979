define('checkout/view/ReviewClass',["checkout/view/RootViewBase", "checkout/manager/WindowManager"], function (RootViewBase, WindowManager) {

    return RootViewBase.inherit({

        defaults: {

            step: "3",
            delivery: null,
            basket: null,
            canOrder: false,

            selected: false,
            footerVisible: "{selected}",

            componentClass: "step order-summary",

            payment: null,

            /***
             * @codeBehind
             */
            basketLoader: null
        },


        inject: {
            windowManager: WindowManager
        },

        $events: ["on:buy", "on:editShippingType", "on:headerClick", "on:buyWithWebComponent"],

        buy: function (e) {
            if (this.$.payment && this.$.payment.$.isWebComponentPayment) {
                this.trigger("on:buyWithWebComponent", e);
            } else {
                this.trigger("on:buy", e);
            }
        },

        headerClick: function (e) {
            this.trigger("on:headerClick", e)
        },

        editShippingType: function (e) {
            this.trigger("on:editShippingType", e);
        },


        showInfo: function (type) {
            this.$.windowManager.showWindow("xaml!checkout/dialog/IFrameDialog", {
                type: type,
                useRelativeDomain: false
            });
        },

        getTermsType: function () {
            return "terms" + this.getSuffixForType();
        },

        getObjectionType: function () {
            return "objection" + this.getSuffixForType();
        },

        getSuffixForType: function () {

            var shopId = this.PARAMETER().shopId;

            switch (shopId) {
                case "830295":
                    return '_teamshirts_de';
                case "1076344":
                    return '_teamshirts_uk';
                case "1076345":
                    return '_teamshirts_fr';
                case "1089703":
                    return '_teamshirts_se';
                case "100064386":
                    return '_teamshirts_nl';
                case "100064422":
                    return '_teamshirts_be';
                case "100202449":
                    return '_teamshirts_dk';
                case "100202606":
                    return '_teamshirts_fi';
                case "100202651":
                    return '_teamshirts_no';
                case "100384982":
                    return '_teamshirts_at';
                case "100385251":
                    return '_teamshirts_ch';
                case "100385301":
                    return '_teamshirts_eu';
                case "100733454":
                    return '_teamshirts_us';
                default:
                    return '';
            }



        },

        refreshBasketSummary: function (cb) {
            var basketLoader = this.$.basketLoader;
            basketLoader.clear();
            basketLoader._load(cb);
        },

        showPayPalButton: function () {
            return this.$.payment && this.$.payment.getType().toLowerCase() === "paypal";
        }.onChange("payment")
    });
});
