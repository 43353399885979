define('checkout/service/IframeGeolocationService',["checkout/service/GeolocationService", "flow"], function(GeolocationService, flow) {

    return GeolocationService.inherit("checkout.service.GeolocationService", {

        _decodeAddress: function (position, callback) {

            var self = this;

            flow()
                .seq("iframe", function(cb) {
                    self.synchronizeFunctionCall(function(cb) {

                        var iframe = document.createElement("iframe");
                        iframe.onload = function() {
                           cb(null, iframe);
                        };

                        iframe.onerror = function(e) {
                            cb(e || true);
                        };

                        iframe.setAttribute("style", "border: 0 none; height: 0; overflow: hidden; visibility: hidden");
                        iframe.setAttribute("sandbox", "allow-scripts");
                        iframe.src = self.baseUrl('checkout/sandbox/maps.html');
                        (document.body ||document.getElementsByTagName("body")[0]).appendChild(iframe);

                    }, "iframe", cb, this);
                })
                .seq("address", function(cb) {
                    var iframe = this.vars.iframe;

                    if (window.addEventListener) {
                        window.addEventListener("message", handler, false);
                    } else {
                        window.attachEvent("onmessage", handler);
                    }

                    iframe.contentWindow.postMessage(JSON.stringify({
                        message: "decodeLocation",
                        coords: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }
                    }), "*");

                    function handler(e) {
                        if (e.source !== iframe.contentWindow) {
                            return;
                        }

                        var data = JSON.parse(e.data);
                        if (data.message === "location") {
                            if (window.addEventListener) {
                                window.removeEventListener("message", handler, false);
                            } else {
                                window.detachEvent("onmessage", handler);
                            }

                            cb(data.error, data.data);
                        }

                    }

                })
                .exec(function(err, results) {
                    callback && callback(err, results.address);
                });
        }

    });

});
