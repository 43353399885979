define('checkout/manager/DeliveryEtaManager',["js/core/Component", "rAppid", "js/core/Bindable", "underscore", "JSON", "js/core/I18n"], function(Component, rAppid, Bindable, _, JSON, I18n) {

    return Component.inherit({

        inject: {
            i18n: I18n
        },

        deliveryEta: function(shippingType, countryCode, state, quantity) {

            if (!(shippingType && shippingType.$.id && countryCode && quantity)) {
                return null;
            }

            var productTypeIds,
                printTypeIds,
                basket      = this.get("basket"),
                basketItems = this.get("basket.basketItems");

            if (basketItems && basketItems.each) {
                productTypeIds = {};
                printTypeIds = {};

                basketItems.each(function(basketItem) {
                    var productTypeId = basketItem.get("element.getProduct().productType.id");
                    if (productTypeId) {
                        productTypeIds[productTypeId] = true;
                    }

                    var configurations = basketItem.get("element.getProduct().configurations");
                    configurations && configurations.each(function (c) {
                      printTypeIds[c.get('printType.id')] = true;
                    });

                });

                productTypeIds = _.keys(productTypeIds).join(",");
                printTypeIds = _.keys(printTypeIds).join(",");
            }


            var ret  = new Bindable({
                    loaded: false
                }),
                i18n = this.$.i18n,
                self = this;

            this.synchronizeFunctionCall(function(callback) {

                var queryParameter = {
                    shippingTypeId: shippingType.$.id,
                    countryCode: countryCode,
                    quantity: quantity,
                    context: "default"
                };

                if (productTypeIds) {
                    queryParameter.productTypeIds = productTypeIds;
                }

                if (printTypeIds) {
                    queryParameter.printTypeIds = printTypeIds;
                }

                if (basket) {
                    queryParameter.basketId = basket.$.id;
                }

                if (state) {
                    queryParameter.stateCode = state.$.code;
                }

                var businessUnit = self.PARAMETER().businessUnit;
                if (businessUnit === "TEAMSHIRTS") {
                    queryParameter.context = businessUnit
                }

                rAppid.ajax("/api/v1/deliveryETA", {
                    queryParameter: queryParameter
                }, function(err, xhr) {

                    if (!err && xhr.status === 200) {
                        // ok
                        callback(null, JSON.parse(xhr.responses.text));
                    } else {
                        // error
                        callback(err || xhr.responses.text || true);
                    }

                });

            }, [shippingType.$.id, countryCode, state ? state.$.code : "", quantity, productTypeIds || ""].join("-"), function(err, data) {

                if (!err) {

                    var format = i18n.t("date.formatString"),
                        months = (i18n.t("date.monthsShort") || "").split("_");

                    data.loaded = true;
                    data.earliest = self.formatDate(data.earliestDateUTC, format, months);
                    data.latest = self.formatDate(data.latestDateUTC, format, months);
                    ret.set(data);
                }

            });

            return ret;

        },

        formatDate: function(date, format, months) {
            date = date.split("-");

            return format.replace("day", date[2]).replace("month", months[date[1] - 1])
        },
    })
});

