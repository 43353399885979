define('checkout/CheckoutBaseClass',["js/core/Application", "flow", "underscore", "checkout/bindable/Root", "sprd/model/Shop", "sprd/model/User", "checkout/model/CheckoutBasket", "sprd/model/Checkout", "js/core/List", "sprd/entity/Address", "sprd/model/Session", "js/data/DataSource", "sprd/model/UserAddress", "sprd/model/Delivery", "require", "sprd/lib/ScrollIntoView", "js/core/Bus"], function(Application, flow, _, Root, Shop, User, Basket, Checkout, List, Address, Session, DataSource, UserAddress, Delivery, require, ScrollIntoView, Bus) {

    var undefined,
        config    = {
            EU: {
                endPoint: "http://api.spreadshirt.net/api/v1",
                imageServerEndPoint: "//image.spreadshirt.net/image-server/v1",
                imageServiceEndPoint: "//image.spreadshirt.net/image-server/v1",
            },
            NA: {
                endPoint: "http://api.spreadshirt.com/api/v1",
                imageServerEndPoint: "//image.spreadshirt.com/image-server/v1",
                imageServiceEndPoint: "//image.spreadshirt.com/image-server/v1",
            }
        },
        PRICE_KEY = "price_",
        EMAIL_KEY = "email_";

    return Application.inherit("checkout.CheckoutBaseClass", {

        defaults: {

            // code behind elements
            localeManager: null,
            injection: null,
            i18n: null,
            imageService: null,
            api: null,
            tracking: null,
            featureManager: null,
            urlManager: null,

            wrapper: null,
            content: null,

            // dialogs
            imprintDialog: null,

            language: null,
            country: null,
            continueShoppingLink: null,
            tokenForBasketKnown: null,
            whiteLabel: false,

            showTrackingPixel: false,
            /**
             * @codeBehind
             */
            backToTopButton: null,

            componentClass: "{PARAMETER().platform}",
            mode: "{PARAMETER().mode}",

            customTracking1: null,
            customTracking2: null
        },

        inject: {
            bus: Bus
        },

        supportEnvironments: true,

        applicationDefaultNamespace: "checkout",

        name: "checkout",

        ctor: function() {
            this.$windowCache = {};
            this.callBase();

            var window = this.$stage.$window;

            var self = this;
            window.scrollIntoView = ScrollIntoView;
            window.platform = this.PARAMETER().platform;

        },

        _getEnvironment: function() {
            var hostname = location.hostname;

            var isInternal = (/vm\d{3}\./).test(hostname) || /-sprd/.test(hostname);
            if (isInternal) {
                return "test";
            }

            var isLocal = /localhost/i.test(hostname);
            if (isLocal) {
                return "dev"
            }

            return null;
        },

        _renderWhiteLabel: function(whiteLabel) {
            if (whiteLabel) {
                this.$stage.addClass("white-label");
            } else {
                this.$stage.removeClass("white-label");
            }

        },

        _renderMode: function(mode, oldMode) {
            /*
            * We only want the spreadshop login button for self orders
            * Self order checkout lands at checkout.spreadshirt.com/... while
            * normal shop checkout lands at shopname.myspreadshop.com/...
            * we can test the subdomain to find out which one we are dealing with
            */
            if (mode === "partner" && window.location.hostname.split(".")[0] !== "checkout") {
                this.$stage.addClass("hidelogin");
            }
            oldMode === "partner" && this.$stage.removeClass("hidelogin");

            mode && this.$stage.addClass(mode);
            oldMode && this.$stage.removeClass(oldMode);
        },

        _initializeDescriptors: function() {
            this.callBase();

            this.$.customTracking1.bind("change:frame", this._initConsent, this);
            this.$.customTracking2.bind("change:frame", this._initConsent, this);

            var featureManager = this.$.featureManager;

            var parameter = this.$stage.$parameter;
            parameter.mode = parameter.mode || "d2c";

            featureManager.loadFeatureSet(parameter.mode);
        },

        _registerFirstClickTracking: function() {
            var tracking = this.$.trackingManager,
                window;

            if (this.runsInBrowser()) {
                window = this.dom(this.$stage.$window);
                window.bindDomEvent("pointer", firstClickHandler);
            }

            function firstClickHandler () {
                tracking.trackFirstClick();
                window.unbindDomEvent("pointer", firstClickHandler);
            }
        },

        _start: function(parameter, shop, basket, checkoutToken, callback) {
            this.set("basket", basket);
            callback && callback();
        },

        /***
         * @hook
         * @private
         */
        _applicationStarted: function(startTime) {
        },

        start: function(parameter, callback) {
            parameter = parameter || {};

            var trackingManager = this.$.trackingManager,
                i18n = this.$.i18n;

            this._registerFirstClickTracking();

            trackingManager.trackStartApplication();

            var self = this,
                api = this.$.api,
                accounts = this.$.accounts,
                platform = parameter.platform || "EU";

            if (parameter.continueShoppingLink && !/^https?:\/\//.test(decodeURIComponent(parameter.continueShoppingLink))) {
                parameter.continueShoppingLink = null;
            }

            _.defaults(parameter, {
                platform: "EU",
                mode: "d2c",
                locale: (platform === "EU" ? "en_EU" : "us_US"),
                enableVouchers: true,
                emptyBasketUrl: null
            }, config[platform]);

            api.set({
                apiKey: parameter.apiKey,
                secret: parameter.secret,
                endPoint: parameter.endPoint
            });

            accounts.set({
                apiKey: parameter.apiKey,
                secret: parameter.secret,
                endPoint: parameter.registrationEndPoint
            })

            var imageService = this.$.imageService;

            imageService.set({
                endPoint: parameter.imageServiceEndPoint
            });


            var locale = parameter.locale = this.$.localeManager.getLocale(parameter.locale);

            if (locale) {
                api.set("locale", locale);
            }

            var language = (locale || "").split("_")[0];
            var country = (locale || "").split("_")[1];
            var continueShoppingLink = parameter.continueShoppingLink ? decodeURIComponent(parameter.continueShoppingLink) : "https://" + this.$stage.$window.location.hostname.replace("checkout", "www") + "/";
            if (parameter.mode === "d2c") {
                continueShoppingLink = continueShoppingLink + (/\?/.test(continueShoppingLink) ? "&" : "?") + "basketId=" + parameter.basketId;
            }

            this.set({
                country: country,
                locale: locale,
                language: language,
                continueShoppingLink: continueShoppingLink,
                whiteLabel: !!parameter.whiteLabel
            });

            var i18nLocale = locale;
            if (parameter.language && locale.indexOf(parameter.language) == -1) {
                i18nLocale = parameter.language;
            }

            var basketId      = parameter.basketId,
                startTime     = this.$creationTime + this.$stage.$applicationContext.$creationTime,
                startLoading  = Date.now(),
                checkoutToken = parameter.token || this.getTokenForBasketId(basketId);

            self.set("tokenForBasketKnown", !!checkoutToken);

            if (!checkoutToken) {
                checkoutToken = this.createAndStoreToken(basketId);
            }

            api.set('checkoutToken', checkoutToken);


            //noinspection JSValidateTypes
            flow()
                .par(function(cb) {
                    i18n.loadLocale(i18nLocale, cb);
                }, function(cb) {
                    self._start(parameter, checkoutToken, cb);
                })
                .exec(function(err) {
                    // we start application in all cases

                    Application.prototype.start.call(self, parameter, function(applicationError) {

                        err = err || applicationError;

                        self.set('error', err);

                        var loadingTime = (new Date()).getTime() - startLoading;

                        if (err) {
                            self.$.bus.trigger("Application.Error", err);
                        } else {

                            self._applicationStarted(loadingTime + startTime);
                            self.set("showTrackingPixel", true);
                            self.trigger("basketAvailable");
                            self.$.wrapper.set("content", self.$.content);
                            self.loadFonts();

                        }
                        // must return null to render application
                        callback && callback(null);
                    });

                });

        },

        _initConsent: function() {
            var sprdConsent = window.sprdConsent;
            if (sprdConsent && sprdConsent.init && !this.$sprdConsentInitizialied) {
                var frames = _.filter([this.$.customTracking1.$.frame, this.$.customTracking2.$.frame], function(x) {
                    return !!x;
                });

                // do not initialize without frames
                if (frames.length === 0) {
                    return;
                }

                sprdConsent.init({
                    frames: frames,
                    usecase: (this.PARAMETER().mode || "").toUpperCase()
                });

                this.$sprdConsentInitizialied = true;
            }
        },

        appendParameter: function(url) {

            if (!url) {
                return url;
            }

            return url + window.location.search;

        },

        or: function(a, b) {
            return a || b
        },

        loadFonts: function() {

            if (!this.$.whiteLabel) {
                return;
            }

            var document = this.$stage.$document,
                heads    = document.getElementsByTagName("head");
            if (!!heads.length) {
                var baseUrl = this.baseUrl()
                if (baseUrl.slice(-1) !== "/") {
                    baseUrl += "/"
                }

                var link = document.createElement("link");
                link.rel = "stylesheet";
                link.type = "text/css";
                link.href = baseUrl + "checkout/css/museo.css";
                link.media = "all";
                heads[0].appendChild(link);
            }
        },

        createAndStoreToken: function(basketId) {
            var token = DataSource.IdGenerator.genId();
            this.setTokenForBasketId(token, basketId);
            return token;
        },

        getTokenForBasketId: function(basketId) {
            var window = this.$stage.$window,
                token;
            try {
                token = window.sessionStorage.getItem(this.getTokenKeyForBasketId(basketId));
            } catch (e) {
            }

            return token;
        },

        getTokenKeyForBasketId: function(basketId) {
            return "token_" + basketId;
        },

        clearTokenForBasketId: function(basketId) {
            try {
                window.sessionStorage.removeItem(this.getTokenKeyForBasketId(basketId));
            } catch (e) {
            }
        },

        getBasketPriceForSuccessToken: function(token) {
            try {
                return this.$stage.$window.sessionStorage.getItem(PRICE_KEY + token);
            } catch (e) {
            }
        },

        getEmailForSuccessToken: function(token) {
            try {
                return this.$stage.$window.sessionStorage.getItem(EMAIL_KEY + token);
            } catch (e) {
            }
        },

        clearSuccessToken: function() {

            var token = this.PARAMETER().successToken;

            _.each([PRICE_KEY + token, EMAIL_KEY + token], function(key) {
                try {
                    window.sessionStorage.removeItem(key);
                } catch (e) {
                    console.error(e);
                }
            });
        },

        setTokenForBasketId: function(token, basketId) {
            var window = this.$stage.$window;
            try {
                window.sessionStorage.setItem(this.getTokenKeyForBasketId(basketId), token);
            } catch (e) {
            }
        },

        showInfo: function(type) {

            if (type === "privacy" && this.PARAMETER().mode === "partner") {
                window.open(this.$.urlManager.url(type))
                return;
            }

            this.$.windowManager.showWindow("xaml!checkout/dialog/IFrameDialog", {
                type: type,
                useRelativeDomain: false
            });
        },

        _bindDomEvents: function () {
            this.callBase();
            var self = this;
            this.dom(this.$stage.$window).bindDomEvent("scroll", function () {
                self._positionBackToTopButton();
            });
        },

        _positionBackToTopButton: function () {
            var checkoutContainer = this.$.checkoutContainer;

            if (!checkoutContainer) {
                return;
            }

            var tabContent = checkoutContainer.$el;
            if (tabContent && this.$.backToTopButton) {
                var clientRect = tabContent.getBoundingClientRect(),
                    checkoutWrapperHeight = this.$.checkoutWrapper.$el.getBoundingClientRect().height,
                    backToTopButton = this.$.backToTopButton;
                if (clientRect.height + 300 < checkoutWrapperHeight) {

                    var top = -1 * (clientRect.height + clientRect.top - window.innerHeight);
                    if (top > 400) {
                        backToTopButton.set('visible', true);
                    } else {
                        backToTopButton.set('visible', false);
                    }
                } else if (backToTopButton.$.visible) {
                    backToTopButton.set('visible', false);
                }
            }
        },

        scrollToTop: function () {
            ScrollIntoView(document.body, 0, this.get("featureManager.scrollTime"));
        },

        bus_StageRendered: function() {
            this.$stage.addClass(this.name);
            this.trigger("stageRendered");
        }.bus("Stage.Rendered"),

        showImprint: function() {
            ScrollIntoView(document.body, 0, this.get("featureManager.scrollTime"));

            if (this.PARAMETER().mode === "partner") {
                this.$.windowManager.showWindow("xaml!checkout/dialog/IFrameDialog", {
                    type: "imprint",
                });
            } else {
                // show D2C imprint
                this.$.imprintDialog.showModal();
            }
        },

        and: function(a, b) {
            return a && b;
        },

        isMode: function(mode) {
            return this.PARAMETER().mode == mode;
        },

        showBBB: function() {
            return this.PARAMETER().platform == "NA";
        },


        eq: function(a, b) {
            return a == b;
        },

        spreadshirtMcAffeeHost: function() {
            var tld = this.$stage.$window.location.hostname.split(".").pop();
            if (/de$/.test(tld)) {
                return "www.spreadshirt.de";
            } else if (/com$/.test(tld)) {
                return "www.spreadshirt.com";
            }
        },


        trustedTrackingFrameSrc: function() {

            if (!this.$.showTrackingPixel) {
                return;
            }

            return this.PARAMETER().trustedTrackingFrameSrc || "";

        }.onChange('showTrackingPixel'),

        shopDtmTrackingFrameSrc: function() {

            if (!this.$.showTrackingPixel) {
                return;
            }

            var trustedTrackingSrc = this.PARAMETER().trustedTrackingFrameSrc || "";
            return trustedTrackingSrc.replace('/trusted', '/dtm');

        }.onChange('showTrackingPixel'),

        _handleBeforeSend: function(payload) {

            if (payload && payload.Details && payload.Details.Error && payload.Details.Error.Message) {
                var message = payload.Details.Error.Message;
                var filter = [
                    /Sandbox\saccess\sviolation/i,
                    /spreadshirtsandbox\.(com|net)/i,
                    /blocked\sa\frame/i
                ];

                for (var i = 0; i < filter.length; i++) {
                    if (filter[i].test(message)) {
                        return false;
                    }
                }
            }

            return payload;
        },

        bus_ApplicationError: function(e) {
            var tracking = this.$.trackingManager;

            if (tracking && e && e.$) {
                var err = e.$;

                tracking.trackApplicationError(err.message, err.stack);
                if (typeof console !== "undefined" && console.error) {
                    console.error(err);
                }


            }
            // show error dialog
            this.$.errorDialog.set('error', err);
            this.$.errorDialog.showModal();

        }.bus("Application.Error"),

        bus_ShowLoader: function() {
            this.$.loader.showModal();
        }.bus('Application.ShowLoader'),

        bus_HideLoader: function() {
            this.$.loader.close();
        }.bus('Application.HideLoader')
    });
});

