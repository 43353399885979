define('checkout/manager/ConfigurationManager',["js/core/Component"], function(Component) {

    return Component.inherit("checkout.manager.ConfigurationManager", {

        priceType: function() {
            return this.PARAMETER().platform == "NA" ? "vatExcluded" : "vatIncluded";
        }

    });

});
