define('checkout/view/CheckoutViewBase',["checkout/view/ViewBase", "checkout/manager/WindowManager", "js/core/Bus", "checkout/bindable/Root", "checkout/manager/TrackingManager", "checkout/manager/PaymentTypeManager", "flow", "sprd/model/User", "sprd/model/Delivery", "sprd/data/SprdApiDataSourceClass", "sprd/manager/AuthenticationManager", "checkout/manager/FeatureManager", "js/core/List", "underscore", "sprd/entity/Address", "sprd/manager/ApiBasketManager", "checkout/manager/UserAddressManager", "checkout/manager/BamBamClient", "checkout/manager/UrlManager", "js/data/DataSource", "sprd/lib/ScrollIntoView", "checkout/config/Constant", "js/core/History", "checkout/manager/ConfigurationManager", "checkout/manager/PurchaseManager", "checkout/manager/AdyenManager", "js/core/NotificationManager"], function (ViewBase, WindowManager, Bus, Root, TrackingManager, PaymentTypeManager, flow, User, Delivery, SprdApiDataSourceClass, AuthenticationManager, FeatureManager, List, _, Address, ApiBasketManager, UserAddressManager, BamBamClient, UrlManager, DataSource, ScrollIntoView, Constant, History, ConfigurationManager, PurchaseManager, AdyenManager, NotificationManager) {

    var KEY_EMAIL = "deliveryEmail",
        billingAddressIdCounter = 1,
        SHIPPING_TYPE = Constant.SHIPPING_TYPE;

    return ViewBase.inherit("checkout.view.CheckoutViewBase", {

        stepIds: [],

        defaults: {

            basket: "{root.basket}",
            session: "{root.session}",
            delivery: "{root.basket.delivery}",
            checkout: "{basket.checkout}",
            shop: "{root.shop}",
            user: "{root.user}",

            componentClass: "checkout",

            currentStep: 0,
            repayment: false,

            savedAddresses: List,

            loadingPaymentTypes: false,
            loadingAddresses: false,

            tokenForBasketKnown: null,

            saveAddressOnCheckout: true
        },

        inject: {
            windowManager: WindowManager,
            bus: Bus,
            root: Root,
            basketManager: ApiBasketManager,
            trackingManager: TrackingManager,
            paymentTypeManager: PaymentTypeManager,
            api: SprdApiDataSourceClass,
            authenticationManager: AuthenticationManager,
            featureManager: FeatureManager,
            bambamClient: BamBamClient,
            userAddressManager: UserAddressManager,
            urlManager: UrlManager,
            history: History,
            configurationManager: ConfigurationManager,
            purchaseManager: PurchaseManager,
            adyenManager: AdyenManager,
            notificationManager: NotificationManager
        },

        load: function (callback) {
            this.synchronizeFunctionCall(this._load, "_load", callback, this);
        },

        /***
         * called, when the checkout is initialized, the view is at this point not
         * available
         *
         * @param callback
         * @abstract
         */
        _load: function (callback) {
            callback && callback();
        },

        /***
         * called, when the checkout is selected
         * @abstract
         */
        start: function () {
        },

        goToStep: function (step) {
            if (this.isCompletedStep(step)) {
                this.set("currentStep", step);
            }
        },


        isCompletedStep: function (step) {
            return step < (this.$.currentStep);
        }.onChange("currentStep"),

        completedStep: function (step) {
            return this.$.currentStep > step ? "complete" : "";
        }.onChange("currentStep"),

        isStep: function (step) {
            return this.$.currentStep === step;
        }.onChange("currentStep"),

        editShippingType: function () {
            throw new Error("Method 'editShippingType' not implemented")
        },

        loadingPaymentTypesClass: function () {
            return this.$.loadingPaymentTypes ? "loading" : "";
        }.onChange('loadingPaymentTypes'),

        showCustomsWarning: function () {

            var platform = this.PARAMETER().platform,
                address = this.get('delivery.shipping.address');

            if (!address) {
                return false;
            }

            if (this.get('delivery.shipping.type.id') == SHIPPING_TYPE.EMAIL) {
                return false;
            }

            if (platform === "NA") {
                return _.indexOf(["US"], address.get('country.code')) === -1
            } else {
                return address.get("country.customs")
            }

        }.onChange('delivery.shipping.address.country', "delivery.shipping.shippingType"),

        canOrder: function () {
            var updating = this.get("basketManager.updating");
            return !updating && this.get("delivery.shipping.type");
        }.onChange("basketManager.updating", "delivery.shipping.shippingType"),

        showInfo: function (type) {
            this.$.windowManager.showWindow("xaml!checkout/dialog/IFrameDialog", {
                type: type,
                useRelativeDomain: false
            });
        },

        saveEmailAddressForBasket: function (email, basket) {
            if (email) {
                var window = this.$stage.$window;
                try {
                    window.sessionStorage.setItem(KEY_EMAIL + "_" + basket.$.id, email);
                } catch (e) {
                }
            }
        },

        loadCurrentUser: function (cb) {
            var currentUser = this.$.api.createEntity(User, "current");
            currentUser.fetch(cb);
        },

        trackBasket: function () {
            var checkoutUrl = "" + window.location;
            // replace result
            checkoutUrl.replace(/result=[^&]*/, "");

            this.$.bambamClient.trackBasket(this.get("basket.id"), this.get("delivery.email"), this.get('user.id'), this.get('api.checkoutToken'), this.get('root.newsletter'), this.PARAMETER().locale, this.get("shop.id"), checkoutUrl);
        },

        _commitCurrentStep: function (step, oldStep) {

            if (!step && step !== 0) {
                return;
            }

            var history = this.$.history,
                name = this.$.name;

            if (history && name) {
                history.navigate([name, step + 1].join("/"), true, false);
            }

            var id = this.stepIds[step],
                scrollTime = this.get("featureManager.scrollTime");

            if (id) {

                this.$.trackingManager.trackCheckoutStep(this.name, id, this.$.root);

                if (!(step == 0 && oldStep == null && this.$.showBasketOnTop)) {
                    setTimeout(function () {
                        ScrollIntoView(id, 0, scrollTime);
                    }, 10);
                }

            }
        },

        initializeSession: function (callback) {
            // TODO: would be nice, if the session could already be initialized earlier
            this.synchronizeFunctionCall(this._initializeSession, "_initializeSession", callback, this);
        },

        _initializeSession: function (callback) {

            var self = this,
                basket = this.$.basket,
                root = this.$.root,
                tokenForBasketKnown = this.$.tokenForBasketKnown;

            flow()
                .seq("user", function (cb) {
                    self.loadCurrentUser(function (err, user) {
                        cb(null, user);
                    });
                })
                .seq("delivery", function (cb) {
                    var delivery = basket.createEntity(Delivery, "delivery");
                    if (delivery && tokenForBasketKnown) {
                        delivery.fetch(function (err) {
                            delivery.set('loaded', !err);
                            if (!err) {
                                var bAddress = delivery.get('billing.address');
                                if (bAddress.$.id) {
                                    var m = bAddress.$.id.match(/billing(\d*)/);
                                    if (m) {
                                        self.$.savedAddresses.add(bAddress.clone());
                                        billingAddressIdCounter = m[1] || 1;
                                    }
                                }
                                var realShippingType = root.$.shippingTypes.find(function (shippingType) {
                                    return shippingType.$.id == delivery.get('shipping.type.id');
                                });

                                delivery.$.shipping.set('type', realShippingType);
                            }
                            // fetch delivery without throwing an error
                            // this
                            cb(null, delivery);
                        });
                    } else {
                        cb(null, delivery);
                    }
                })
                .seq(function (cb) {
                    var delivery = this.vars.delivery;
                    var shippingAddress = delivery.get('shipping.address');
                    var city = self.PARAMETER().geoLocationCity;
                    if (shippingAddress && !shippingAddress.get('city') && city) {
                        shippingAddress.set('city', self.capitalize(city));
                    }
                    cb(null, delivery);
                })
                .seq(function (cb) {
                    var delivery = this.vars.delivery;

                    var t = basket.getShippingOption("gift");
                    if (t) {
                        delivery.set('useGiftWrapping', true);
                    }

                    var email = self.loadEmailAddressForBasket(basket);
                    delivery.set('email', email);

                    var user = this.vars.user;
                    if (user && !user.$.guest) {
                        root.set('user', user);
                    }
                    cb();
                })
                .exec(callback);
        },

        capitalize: function (string) {
            if (string) {
                return _.map(String(string).split(' '), function (word) {
                    word = word.toLowerCase();
                    return word.charAt(0).toUpperCase() + word.substr(1);
                })
                    .join(' ');
            }
            return string;
        },

        loadEmailAddressForBasket: function (basket) {
            var window = this.$stage.$window;
            try {
                return window.sessionStorage.getItem(KEY_EMAIL + "_" + basket.$.id);
            } catch (e) {
            }

            return null;
        },

        or: function (a, b) {
            return a || b
        },

        buyWithWebComponent: function () {
            var checkout = this.$.checkout,
                payment = checkout.$.payment;
            this.$.adyenManager.submit(payment);
        },

        buy: function (callback) {

            var self = this,
                api = this.$.api,
                checkout = this.$.checkout,
                bus = this.$.bus,
                repayment = this.$.repayment,
                root = this.$.root,
                initialSession = this.$.session,
                trackingManager = this.$.trackingManager,
                featureManager = this.$.featureManager,
                fingerPrintManager = this.$.fingerPrintManager;

            bus.trigger('Application.ShowLoader');

            flow()
                .seq(function () {
                    fingerPrintManager && fingerPrintManager.init();
                })
                // save shipping and billing address of logged in user if he has entered new addresses
                .seq(function (cb) {
                    self.$.purchaseManager.saveAddress(repayment, self.$.saveAddressOnCheckout, cb)
                })
                .seq("session", function (cb) {
                    self.$.purchaseManager.createSession(repayment, cb);
                })
                .seq(function () {
                    checkout.set({
                        returnUrl: self.$.urlManager.createReturnUrl(self.$.basket, checkout.get("paymentType"))
                    });
                })
                .seq(function (cb) {
                    self.$.purchaseManager.saveSession(this.vars.session);
                    cb();
                })
                .seq(function (cb) {
                    trackingManager.trackBuy(root, repayment, cb);
                    // let the tracking stuff been send

                })
                .seq(function (cb) {
                    setTimeout(function () {
                        cb();
                    }, featureManager.$.checkoutBuyDelay)
                })
                .seq("checkoutResult", function (cb) {
                    var payment = checkout.$.payment;
                    payment && self.$.bus.setUp(payment);

                    if (fingerPrintManager) {
                        checkout.set("fingerPrint", fingerPrintManager.getFingerPrint());
                    }

                    checkout.save(cb);
                })
                .seq(function (cb) {
                    self.handleCheckoutResult(this.vars.checkoutResult, cb);
                })
                .exec(function (err) {

                    if (err) {
                        // only hide the loader in an error case as the checkout will be redirect anyway
                        bus.trigger('Application.HideLoader');
                    }

                    api.$.session = initialSession;
                    err && console.log(err);

                    if (_.isFunction(callback)) {
                        callback && callback(err);
                    }
                });

        },

        handleCheckoutResult: function (checkoutResult, callback) {
            callback && callback();
        },

        handlePaymentError: function (paymentTypeId, paymentStep) {
            var parameter = this.PARAMETER(),
                result = parameter.result,
                paymentType = this.get('root.paymentType');

            if (result == "FAILED_PAYMENT_REFUSED") {
                if (paymentType) {
                    paymentType.set('paymentError', "paymentVerificationFailure");
                    this.set('currentStep', paymentStep);
                }
            } else if (result == "FAILED_ERROR") {
                var errorCode = parameter.errorCode;

                switch (errorCode) {
                    case "87004": // The payment type you selected does not exist or is not available in this context.
                        if (paymentType) {
                            paymentType.set('paymentError', "paymentNotAvailable");
                            this.set('currentStep', paymentStep);
                        } else {
                            throw new Error(result + " " + errorCode);
                        }
                        break;
                    case "87006": // "No session information was supplied. The checkout requires a logged-in user.
                    case "87007":
                        this.set('currentStep', 0);
                        this.$.bus.trigger('Application.showSessionTimeout');
                        break;
                    case "87009":
                    // TODO: show error at shipping types, but should not happen anyway
                    case "87020":
                        // Coupon cannot be applied
                        break;
                    case "87107":
                        this.set('currentStep', paymentStep);
                        this.$.bus.trigger('Application.showBasketWithEmptyProducts');
                        break;
                    case "86018":
                        // shipping price not found, therefore, we show an error above the checkout
                        this.set('currentStep', 0);
                        this.$.bus.trigger('Application.showShippingPriceError');
                        break;
                    case "86017":
                        if (parameter.hasOwnProperty("unavailableItemIds") && parameter.unavailableItemIds) {
                            var itemIds = parameter.unavailableItemIds.trim().split(",");
                            this.$.basket.markItemsAsOutOfStock(itemIds);
                        }
                        this.$.bus.trigger('Application.showBasketWithOutOfStockItems');
                        break;
                    default :
                        throw new Error(result + " " + errorCode);
                }
            } else if (result == "FAILED_TEMPORARILY_UNAVAILABLE") {
                if (paymentType) {
                    paymentType.set('paymentError', "paymentConnectionTimeout");
                    this.set('currentStep', paymentStep);
                } else {
                    throw new Error(result);
                }
            } else if (paymentTypeId && paymentType && this.$.paymentNeeded) {
                this.set('currentStep', paymentStep);
            }
        },

        getInitialPaymentTypeId: function () {
            var parameter = this.PARAMETER();
            return parameter.paymentTypeId || this.loadSavedPaymentTypeIdForBasketId(parameter.basketId);
        },

        loadSavedPaymentTypeIdForBasketId: function (basketId) {
            try {
                var sessionStorage = this.$stage.$window.sessionStorage;
                return sessionStorage.getItem("paymentTypeId_" + basketId);
            } catch (e) {
            }

        },


        savePaymentTypeIdForBasketId: function (paymentId, basketId) {
            try {
                var sessionStorage = this.$stage.$window.sessionStorage;
                sessionStorage.setItem("paymentTypeId_" + basketId, paymentId);
            } catch (e) {
            }
        },

        clearPaymentId: function () {
            try {
                var sessionStorage = this.$stage.$window.sessionStorage;
                sessionStorage.removeItem("paymentTypeId");
            } catch (e) {
            }
        },

        _commitSession: function (session) {
            if (session) {
                this.$.basketManager.reloadBasket();
            }
        },

        bus_onLogout: function () {
            var delivery = this.$.delivery;
            if (delivery) {
                this.$.userAddressManager.set({
                    user: null
                });
            }
            this.set("currentStep", 0);

            this.$.basketManager.reloadBasket();

        }.bus('User.logout')

    });

});
