define('checkout/view/payment/SepaClass',["checkout/view/payment/PaymentView", "checkout/manager/AdyenManager", "underscore"], function (PaymentView, AdyenManager, _) {

    return PaymentView.inherit("checkout.view.SepaClass", {

        defaults: {
            container: null
        },

        inject: {
            adyenManager: AdyenManager
        },

        _renderContainer: function () {
            var self = this;
            var config = {
                onChange: _.debounce(function (data) {
                    var paymentMethod = data && data.isValid && data.data && data.data.paymentMethod;
                    if (paymentMethod) {
                        self.$.payment.set({iban: paymentMethod.iban, owner: paymentMethod.ownerName});
                    }
                }, 500)
            };

            this.$.adyenManager.initializeWebComponent(this.$.payment, config, {}, this.$.container.$el);
        }
    });
});
