define('checkout/view/AddressClass',["checkout/view/ViewBase", "flow", "sprd/entity/Address", "underscore", "checkout/manager/FeatureManager", "sprd/entity/Person", "checkout/manager/TrackingManager", "checkout/bindable/Root", "checkout/config/Constant", "checkout/lib/Cookies"], function (View, flow, Address, _, FeatureManager, Person, TrackingManager, Root, Constant, Cookies) {

    var alphaNumericZipCodeCountries = ["GB", "PE", "NL", "SZ", "MT", "VE", "CA", "JM", "PL", "IM"],
        SHIPPING_TYPE = Constant.SHIPPING_TYPE;

    return View.inherit("checkout.view.AddressBase", {

        defaults: {
            address: null,
            countries: null,
            addressType: "billing",
            componentClass: "address-form",

            addressLoading: false,

            salutations: [
                {key: "mr", id: Person.Salutation.MR},
                {key: "mrs", id: Person.Salutation.MRS},
                {key: "mx", id: Person.Salutation.MX},
                {key: "company", id: Person.Salutation.COMPANY}
            ],
            maxLength: Address.MAX_LENGTH,
            states: null,
            holdsVatId: true,

            /***
             * @codeBehind
             */
            addressField: null,

            /***
             * @codeBehind
             */
            firstname: null,

            useGoogleAutoCompletion: "{_useAutoComplete('google')}",
            shippingTypeId: "{root.basket.delivery.shipping.type.id}",

            autoCompleteHoneyPod: ''
        },

        ctor: function () {
            this.callBase();

            if (Cookies.get('disableGooglePlaces') == "true") {
                this.set("useGoogleAutoCompletion", false);
            }


        },

        events: [
            "on:locationSelected"
        ],

        inject: {
            featureManager: FeatureManager,
            trackingManager: TrackingManager,
            root: Root
        },

        isEU: function () {
            return this.PARAMETER().platform == "EU";
        },

        countries: function () {

            var countries = this.$.countries;
            if (countries && this.$.addressType === "shipping") {
                var excludedShippingCountries = (this.PARAMETER().excludedShippingCountries || "").split(",");

                countries = _.filter(countries, function (country) {
                    return _.indexOf(excludedShippingCountries, country.$.isoCode) === -1;
                });
            }

            return countries;

        }.onChange("countries", "addressTypes"),

        addressTypes: function () {

            var ret = [Address.ADDRESS_TYPES.PRIVATE];

            if (this.$.addressType === "shipping") {

                var possibleShippingTypes = this.$.root.getShippingTypesForCountry(this.get("address.country")),
                    supportsUpsPickup = false;

                if (possibleShippingTypes) {
                    supportsUpsPickup = _.find(possibleShippingTypes, function (shippingType) {
                        return shippingType.$.supportsUpsPickup
                    });
                }

                if (this.isEU() && this.get("address.supportsPackStation()")) {
                    ret.push(Address.ADDRESS_TYPES.PACKSTATION);
                    ret.push(Address.ADDRESS_TYPES.POSTFILIALE);
                }

                if (this.isEU() && supportsUpsPickup && this.get("address.supportsUpsPickup()")) {
                    ret.push(Address.ADDRESS_TYPES.UPS_PICKUP);
                }
            }


            return ret;

        }.onChange("address", "address.supportsPackStation()", "address.supportsUpsPickup()", "addressType", "address.country"),

        showAddressTypeSelector: function (types) {
            return types && types.length > 1;
        },

        displayCompany: function (salutationId) {
            return !(salutationId == 4 && this.get("address.country.code") == "AU" && this.PARAMETER().platform == 'NA');
        }.onChange("address.country"),

        autoCompletionClass: function () {
            return this.$.useGoogleAutoCompletion ? "auto-completion" : ""
        }.onChange("useGoogleAutoCompletion"),

        id: function (type) {
            return this.$.addressType + "_" + type;
        }.onChange("addressType"),

        zipCodeClassName: function () {
            return this.get("address.needsZipCode()") ? "with-zip-code" : "without-zip-code"
        }.onChange("address.needsZipCode()"),

        streetAnnexVisible: function () {
            return !!(this.get("address.street") || this.get("address.streetAnnex"));
        }.onChange("address.streetAnnex", "address.street"),

        getShippingStates: function (country) {

            if (country && country.$.shippingStates) {
                return country.$.shippingStates;
            }
            if (this.$.countries && country) {
                country = _.find(this.$.countries, function (c) {
                    return country.get('code') == c.get('code');
                });

                if (country) {
                    return country.$.shippingStates;
                }
            }
            return null;
        }.onChange('countries'),


        _commitChangedAttributes: function ($) {
            this.callBase();

            if (this.$.countries && $.address) {
                var countryCode = $.address.get('country.code') || this.PARAMETER().geoLocationCountry || (this.PARAMETER().locale || "").split("_")[1];
                var selectedCountry;

                if (countryCode) {
                    selectedCountry = _.find(this.$.countries, function (country) {
                        return (country.$.code || "").toLowerCase() === countryCode.toLowerCase();
                    });
                }

                if (!selectedCountry) {
                    // fallback based on platforms
                    countryCode = (this.PARAMETER().platform === "EU" ? "GB" : "US");
                    selectedCountry = _.find(this.$.countries, function (country) {
                        return (country.$.code || "").toLowerCase() === countryCode.toLowerCase();
                    });
                }

                $.address.set('country', selectedCountry);

                if (!$.address.$.state && $.address.isStateRequired()) {
//                    var states = this.getShippingStates($.address.$.country);
                }
            }
        },

        showCustomsWarning: function () {

            var platform = this.PARAMETER().platform,
                address = this.$.address;

            if (!(address && this.$.addressType === "shipping")) {
                return false;
            }

            if (this.$.shippingTypeId == SHIPPING_TYPE.EMAIL) {
                return false;
            }

            if (platform === "NA") {
                return _.indexOf(["US", "AU"], address.get('country.code')) === -1
            } else {
                return address.get("country.customs")
            }

        }.onChange("address.country", "addressType", "shippingTypeId"),

        isEnglish: function () {
            var code = this.get('address.country.code');
            return code == "GB" || code == "US" || code == "IE";
        }.onChange('address.country'),

        zipCodeInputType: function () {
            var code = this.get('address.country.code');
            if (code) {
                return alphaNumericZipCodeCountries.indexOf(code) > -1 ? "text" : this.inputType("tel");
            } else {
                return "text";
            }
        }.onChange('address.country'),

        and: function (a, b) {
            return a && b;
        },

        openHref: function (href) {
            window.open(href, "_blank")
        },

        setPlaceAddress: function (e, part) {
            var newAddress = e.target.$.address;

            newAddress.country = _.find(this.$.countries, function (country) {
                return country.$.code === newAddress.country_code;
            });

            var streetAndHouseNumber = e.target.$.place.name;

            if (newAddress.houseNumberWithExtension && newAddress.houseNumber
                && streetAndHouseNumber.indexOf(newAddress.houseNumberWithExtension) < 0) {
                streetAndHouseNumber = streetAndHouseNumber.replace(newAddress.houseNumber, newAddress.houseNumberWithExtension);
            }

            newAddress.street = streetAndHouseNumber;

            _.defaults(newAddress, {
                street: "",
                city: "",
                state: null,
                zipCode: ""
            });

            if (newAddress.state && newAddress.country) {
                newAddress.state = _.find(newAddress.country.$.shippingStates.$items, function (state) {
                    return state.$.name.toLowerCase() === newAddress.state.toLowerCase();
                })
            }

            delete newAddress.country_code;

            if (!part) {
                this.$.address.set("street", "");
                this.$.address.set(newAddress, {
                    force: true
                });
            }

            _.each([this.$.addressField, this.$.zip, this.$.city], function (field) {
                field && field._validateValue();
            });

        },

        _useAutoComplete: function (type) {
            return this.get("featureManager.addressAutoCompletion") === type;
        }.onChange("featureManager.addressAutoCompletion"),

        validateAddress: function () {
            var field = this.$.addressField;
            field && field._validateValue();
        },

        linkToGooglePrivacyPolicy: function () {
            var language = this.PARAMETER().language || "en";
            return "http://www.google.com/intl/" + language + "/policies/privacy/";
        },

        locationSelected: function (e) {
            this.trigger("on:locationSelected", e);
        },

        focusFirstname: function () {
            if (this.$.firstname && this.$.firstname.$firstInput) {
                this.$.firstname.$firstInput.focus();
            }
        }
    });
});
