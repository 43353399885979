define('checkout/entity/payment/CreditCardPayment',['sprd/entity/Payment'], function (Payment) {
    return Payment.inherit('checkout.entity.payment.CreditCardPayment', {

        type: "creditCard",

        defaults: {
            isWebComponentPayment: true
        }
    });
});
