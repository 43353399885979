define('checkout/manager/BrandingManager',["js/core/Component", "rAppid", "checkout/lib/psbc", "underscore"], function (Component, rAppid, pSBC, _) {

    var FONT_MAP = {
        textFontUrls: {name: "sprd-text"},
        textBoldFontUrls: {name: "sprd-text", weight: "bold"},
        headlineFontUrls: {name: "sprd-headline"}
    };

    return Component.inherit("checkout.manager.BrandingManager", {

        defaults: {

            endPoint: null,

            mode: null,
            shopId: null,

            settings: null,

            headerImage: null,
            title: null,
            shopUrl: null
        },

        ctor: function () {
            this.callBase();
        },

        _commitMode: function (mode) {
            if (mode === "d2c") {
                this.set({
                    headerImage: this.baseUrl("checkout/img/logo.svg"),
                    title: "Spreadshirt"
                });
            }
        },

        _commitShopId: function (shopId) {
            var self = this;
            if (shopId && this.$.endPoint && this.$.mode == "partner") {
                rAppid.ajax(this.$.endPoint + "/" + shopId + "/shopData/checkout/settings?locale="  + this.PARAMETER().locale, null, function (err, data) {
                    if (!err && data.status === 200) {
                        var settings = JSON.parse(data.responses.text);
                        self._handleSettings(settings);
                    }
                });
            }

        },

        _handleSettings: function (settings) {

            var style = document.createElement("style");
            style.type = 'text/css';

            this.set("settings", settings);
            this.set({
                settings: settings,
                headerImage: settings.logoImageUrl,
                title: settings.title,
                shopUrl: settings.shopUrl
            });

            var color = settings.primaryColor;

            var css = ":root {\n";

            if (color) {
                css +=
                    "--brand: " + color + " ;\n" +
                    "--brand-light: " + pSBC(0.08, color) + ";\n" +
                    "--brand-lighter: " + pSBC(0.16, color) + ";\n" +
                    "--brand-dark: " + pSBC(-0.08, color) + ";\n" +
                    "--brand-darker: " + pSBC(-0.16, color) + ";\n";
            }

            css +=
                "--header-background: " + (settings.headerBackgroundColor || "white") + ";\n" +
                "--footer-background: " + (settings.footerBackgroundColor || settings.headerBackgroundColor || "#f2f2f2") + ";\n" +
                "--footer-color: " + (settings.footerFontColor || settings.headerFontColor || "#333") + ";\n" +
                "--header-color: " + (settings.headerFontColor || "#333") + ";\n";

            css += "}\n";

            css += _.map(FONT_MAP, function (fontSetting, key) {
                if (!settings.hasOwnProperty(key)) {
                    return "";
                }

                return "\n" +
                    "@font-face {\n" +
                    "  font-family: '" + fontSetting.name + "';\n" +
                    "  src: " + _.map(settings[key], function (url, format) {
                        return "url('" + url + "') format('" + format + "')"
                    }).join(", ") + ";\n" +
                    "  font-weight: " + (fontSetting.weight || "normal") + ";\n" +
                    "}";

            }).join("\n");

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            var head = document.getElementsByTagName("head")[0];
            head.appendChild(style);

        }

    });


});
