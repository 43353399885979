define('checkout/helper/UrlHelper',["underscore"], function(_) {

    return {

        getAffiliateUrlParameter: function() {

            var urlParameter = this.getUrlParameter(),
                ret          = {};

            _.each(["affiliate", "directAffiliate", "indirectAffiliate", "lastSemTouch"], function(key) {
                if (urlParameter.hasOwnProperty(key)) {
                    ret[key] = urlParameter[key]
                }
            });

            return ret;

        },

        getUrlParameter: function() {
            var queryString = location.search.substring(1);
            var urlParameter = queryString.split("&");
            var ret = {};

            for (var i = 0; i < urlParameter.length; i++) {
                var parameter = urlParameter[i].split('=');
                var key = parameter.shift();

                if (!ret.hasOwnProperty(key)) {
                    ret[key] = decodeURIComponent(parameter.join("="));
                }

            }

            return ret;
        },
    }

});
