define('checkout/CheckoutClass',["xaml!checkout/CheckoutBase", "flow", "underscore", "checkout/bindable/Root", "sprd/model/Shop", "sprd/model/Basket", "checkout/model/CheckoutBasket", "sprd/entity/Address", "require", "js/core/Bus", "checkout/lib/Modernizr", "rAppid", "js/core/Injection", "checkout/manager/FeatureManager", "xaml!checkout/dialog/LoginDialog", 'js/core/ErrorProvider'],
    function(CheckoutBase, flow, _, Root, Shop, Basket, CheckoutBasket, Address, require, Bus, Modernizr, rAppid, Injection, FeatureManager, LoginDialog, ErrorProvider) {

        var HIGH_CONTRAST_CLASS = "high-contrast";

        var FETCHSTATE = {
            CREATED: 0,
            LOADING: 1,
            LOADED: 2,
            ERROR: -1
        };

        var KEY_EMAIL = "deliveryEmail";

        var CheckoutLaterAffiliateIdEu = "1251165";
        var CheckoutLaterAffiliateIdNa = "9707";

        var AFFILIATE_ID_KEY = "affiliateID";

        return CheckoutBase.inherit({

            defaults: {

                root: Root,

                // shortcuts
                basket: "{{root.basket}}",
                session: "{{root.session}}",
                delivery: "{root.basket.delivery}",
                billingCountry: "{delivery.billingAddress.country}",
                shop: "{{root.shop}}",
                checkout: "{basket.checkout}",
                user: "{{root.user}}",

                currentStep: 0,

                formAddress: null,
                basketLoader: null,

                paymentError: null,

                /***
                 * @codeBehind
                 */
                paymentTypeManager: null,

                /***
                 * @codeBehind
                 */
                basketManager: null,

                /**
                 * @codeBehind
                 */
                addressBookDialog: null,

                /***
                 * @codeBehind
                 */
                paymentSummary: null,

                selectedCheckout: null,

                /***
                 * @codeBehind
                 */
                checkoutContainer: null,

                showSessionTimeout: false,

                showBasketWithEmptyProducts: false,

                showBasketWithOutOfStockItems: false,

                showShippingPriceError: false,

                showBasketOnTop: "{showBasketOnTop()}",
                showBasket: false,
                basketOnTop: null,

                couponApplied: false,
                autoApplyCoupon: false
            },

            inject: {
                bus: Bus,
                featureManager: FeatureManager,
                errorProvider: ErrorProvider
            },

            ctor: function() {
                this.callBase();


                var root = this.$.root;

                var injection = this.createComponent(Injection);
                injection.addInstance(root);
            },

            _initializationComplete: function() {
                this.callBase();
                this._checkContrastPreferences();
            },

            toggleBasket: function() {
                this.set("showBasket", !this.$.showBasket);

                if (this.$.showBasket) {
                    this.$.basketOnTop.set("load", true);
                }
            },

            _startHistory: function(callback) {
                callback && callback();
            },

            share: function() {

                var i18n              = this.$.i18n,
                    checkoutLaterLink = this.checkoutLaterLink();

                if (navigator.share) {
                    navigator.share({
                        title: 'Share Basket',
                        url: checkoutLaterLink
                    })

                } else {
                    var subject = i18n.t('checkoutLater.email.subject');
                    var body = this.encode(i18n.t('checkoutLater.email.text', checkoutLaterLink));

                    var link = "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + body

                    window.open(link);
                }

            },

            _start: function(parameter, checkoutToken, callback) {

                var self            = this,
                    injection       = this.$.injection,
                    basketManager   = this.$.basketManager,
                    trackingManager = self.$.trackingManager,
                    api             = this.$.api,
                    basket          = api.createEntity(CheckoutBasket, parameter.basketId),
                    bus             = this.$stage.$bus,
                    root            = this.$.root;

                basketManager.bind('on:basketUpdating', this._positionBackToTopButton, this);
                basketManager.bind('on:couponApplied', function(e) {
                    var coupon = e.$;
                    trackingManager.trackCouponApply(coupon.isValid(), coupon.$.code);
                });


                if (parameter.couponCode) {
                    this.bind("basketManager", "on:basketUpdated", function() {
                        if (!self.$.couponApplied && self.$.autoApplyCoupon) {

                            var coupon = basket.getCollection('coupons').createItem();
                            coupon.set('code', parameter.couponCode);

                            basketManager.applyCoupon(coupon, parameter.locale, function() {
                                self.set("couponApplied", coupon.isValid());
                            });
                        }
                    }, this);
                }


                bus.setUp(root);
                injection.addInstance(root);

                if (!parameter.basketId) {
                    redirectToEmptyBasketPage();
                    return;
                }

                flow()
                    .seq("basketXHR", function (cb) {
                        if (!window.basket) {
                            return cb();
                        }

                        if (window.basket.xhr.readyState === 4) {
                            cb(null, window.basket.xhr);
                        } else {
                            window.basket.addEventListener(function (xhr) {
                                cb(null, xhr);
                            }, self);
                        }
                    })
                    .seq(function (cb) {
                        var basketXHR = this.vars.basketXHR;
                        if (basketXHR && basketXHR.status === 200) {
                            try {
                                var xhr = new rAppid.ajaxWrapper(basketXHR);
                                self.$.api._parseModelPayload(xhr, basket);
                                basket._fetch.state = 2;
                                cb(null, basket);
                            } catch (e) {
                                basket.fetch(cb);
                            }
                        } else {
                            basket.fetch(cb);
                        }

                    })
                    .seq(function(cb) {
                        if (basket.$.basketItems.$items.length === 0) {
                            // empty basket
                            redirectToEmptyBasketPage();
                        } else {
                            cb();
                        }
                    })
                    .par(function(cb) {
                        basket.$.shop.fetch(cb);
                    }, function(cb) {
                        var collection = basket.$.shop.getCollection("shippingTypes");
                        collection.$.queryParameters['currency'] = basket.$.currency.$.id;
                        collection.fetch({
                            fullData: true
                        }, cb);
                    }, function(cb) {
                        var collection = basket.$.shop.getCollection("shippingOptions");
                        collection.$.queryParameters['currency'] = basket.$.currency.$.id;
                        
                        collection.fetch(null, function() {

                            var shippingOption = basket.$.shop.$.shippingOptions.find(function(shippingOption) {
                                return shippingOption.$.type === "gift";
                            });

                            self.$.root.set('giftWrappingShippingOption', shippingOption);

                            cb();

                        });
                    }, function(cb) {
                        basketManager._initBasket({lazyLoad: true}, cb);
                    }, function(cb) {
                        basket.$.currency.fetch(cb);
                    })
                    .seq(function() {


                        if (parameter.couponCode) {
                            // create a coupon instance and make it available via the basket
                            var coupon = basket.getCollection('coupons').createItem();

                            coupon.set('code', parameter.couponCode);

                            basketManager.applyCoupon(coupon, parameter.locale, function() {

                                var valid = coupon.isValid();
                                if (!valid) {
                                    self.$.notificationManager.showNotification("notification", {
                                        message: self.$.errorProvider.getErrorMessage(coupon.$errors.$.code)
                                    });
                                }

                                self.set({
                                    couponApplied: valid,
                                    autoApplyCoupon: true
                                });
                            });

                        }

                        root.set({
                            shop: basket.$.shop,
                            basket: basket
                        });


                        trackingManager.set("basket", basket);

                    })
                    .seq(function(cb) {
                        // this starts the history and loads the checkout component
                        // also load is invoked on the checkout
                        self.$stage.$history.start(cb);
                    })
                    .exec(function(err, result) {
                        if (err && err.xhr && (err.model instanceof Basket) && err.xhr.status == 404) {
                            redirectToEmptyBasketPage();
                            return;
                        }

                        callback && callback(err, result);
                    });


                function redirectToEmptyBasketPage() {
                    self.$.trackingManager.trackEmptyBasket(basket);
                    window.location = parameter.emptyBasketUrl || self.$.urlManager.url('emptyBasket');
                }
            },

            couponRemoved: function() {
                this.set("couponApplied", false);
                this.$.notificationManager.showNotification("notification", {
                    message: this.$.i18n.t('error.coupon.checkout')
                });
            },

            couponManualRemoved: function() {

                this.set({
                    couponApplied: false,
                    autoApplyCoupon: false
                });


                this.$.notificationManager.showNotification("notification", {
                    message: this.$.i18n.t('coupon.removed')
                });
            },

            _applicationStarted: function(startTime) {
                this.$.trackingManager.trackMainApplicationStarted(startTime);

                if (this.$.paymentError) {
                    this.set('currentStep', 1);
                }

            },

            scrollToShippingTypeSection: function() {
                this.$.bus.trigger("Checkout.ScrollToShippingType");
            },

            editShippingType: function() {
                var selectedCheckout = this.$.selectedCheckout;
                selectedCheckout && selectedCheckout.editShippingType();
            },

            editAddress: function(address) {
                this.set('formAddress', address.clone());
            },

            _showLoginDialog: function() {
                var self            = this,
                    trackingManager = this.$.trackingManager,
                    root            = this.$.root;

                this.set('showSessionTimeout', false);

                var dialog = self.createComponent(LoginDialog);
                dialog.set('class', "login-dialog");
                dialog.showModal(function(err, dialog, state) {
                    if (state === true) {
                        trackingManager.trackCheckoutStep("checkout", "login", root)
                    }
                });

            },

            _commitUser: function(user) {
                var trackingManager = this.$.trackingManager;
                if (user && trackingManager) {
                    trackingManager.handleUserAsRegisteredUser();
                }
            },

            logout: function() {
                var self = this;

                this.$.authenticationManager.logout(function(err) {
                    if (!err) {

                        // clear address when logging out
                        var delivery = self.$.delivery;

                        delivery.reset();

                        self.$.root.set({
                            paymentType: null,
                            user: null
                        });

                        self.$.paymentTypeManager.clear();

                        try {
                            sessionStorage.removeItem("sessionId");
                        } catch (e) {
                        }
                        self.clearEmailAddressForBasket(self.$.basket);
                        self.clearTokenForBasketId(self.$.basket.$.id);

                        // create a new token
                        var checkoutToken = self.createAndStoreToken(self.$.basket.$.id);
                        self.$.api.set('checkoutToken', checkoutToken);

                        self.$.trackingManager.handleUserAsGuest();

                    }
                });

            },

            clearEmailAddressForBasket: function(basket) {
                var window = this.$stage.$window;
                try {
                    return window.sessionStorage.removeItem(KEY_EMAIL + "_" + basket.$.id);
                } catch (e) {
                }

            },

            showBasketOnTop: function() {

                var window = this.$stage.$window;
                var parameter = this.PARAMETER();
                var hasUnavailableItemIds = parameter.hasOwnProperty("unavailableItemIds") && parameter.unavailableItemIds;
                if (window && window.matchMedia && (parameter.showBasketOnTop || hasUnavailableItemIds)) {
                    var match = window.matchMedia("only screen and (max-width: 46.063em)");
                    if (match && match.matches) {
                        return true;
                    }
                }

                return false;

            },

            checkoutLink: function() {
                return this.$stage.$window.location.href;
            },

            checkoutLaterLink: function() {
                var location                 = this.$stage.$window.location,
                    checkoutLaterLink,
                    CheckoutLaterAffiliateId = this.PARAMETER().platform === "EU" ? CheckoutLaterAffiliateIdEu : CheckoutLaterAffiliateIdNa;

                checkoutLaterLink = location.protocol + "//" + location.host + location.pathname + location.search;

                if (!checkoutLaterLink.match(/affiliateId/i)) {
                    checkoutLaterLink += ("&" + AFFILIATE_ID_KEY + "=" + CheckoutLaterAffiliateId)
                }

                if (!checkoutLaterLink.match(/showBasketOnTop/i)) {
                    checkoutLaterLink += "&showBasketOnTop=true"
                }

                checkoutLaterLink += location.hash;

                return checkoutLaterLink;
            },

            bus_showSessionTimeout: function() {
                this.set('showSessionTimeout', true);
            }.bus('Application.showSessionTimeout'),

            bus_showBasketWithEmptyProducts: function() {
                this.set('showBasketWithEmptyProducts', true);
            }.bus('Application.showBasketWithEmptyProducts'),

            bus_showBasketWithOutOfStockItems: function() {
                this.set('showBasketWithOutOfStockItems', true)
            }.bus('Application.showBasketWithOutOfStockItems'),

            bus_showShippingPriceError: function() {
                this.set('showShippingPriceError', true)
            }.bus('Application.showShippingPriceError'),

            encode: function(text) {
                return encodeURIComponent(text);
            },

            basketUpdated: function() {
                var trackingManager = this.$.trackingManager;
                trackingManager && trackingManager.trackBasketChange();
            },

            defaultRoute: function(routeContext) {
                routeContext.navigate("spreadshirt");
            }.async(),

            showCheckout: function(routeContext) {

                var self             = this,
                    checkoutName     = routeContext.route.name,
                    selectedCheckout = this.$.selectedCheckout;

                if (selectedCheckout && selectedCheckout.$.name == checkoutName) {
                    // checkout already loaded
                    return routeContext.callback();
                }

                self.set("selectedCheckout", null);

                flow()
                    .seq("checkout", function(cb) {
                        self.synchronizeFunctionCall(function(callback) {
                            require(["xaml!checkout/view/SpreadshirtCheckout"], function (componentFactory) {
                                var checkout = self.createComponent(componentFactory, {
                                    tokenForBasketKnown: self.$.tokenForBasketKnown,
                                    showBasketOnTop: self.$.showBasketOnTop,
                                    name: checkoutName
                                });
                                self.$.checkoutContainer.addChild(checkout);
                                callback(null, checkout);
                            }, callback);

                        }, checkoutName, cb);
                    })
                    .seq(function(cb) {
                        this.vars.checkout.load(cb);
                    })
                    .seq(function() {
                        var checkout = this.vars.checkout;
                        checkout.start && checkout.start();
                    })
                    .exec(function(err, results) {
                        if (!err) {
                            self.set("selectedCheckout", results.checkout);
                            self.$.basketLoader && self.$.basketLoader.set('load', true);
                        }

                        routeContext.callback(err);
                    });


            }.async(),

            showCheckoutStep: function(routeContext, step) {

                var selectedCheckout = this.$.selectedCheckout;
                step = parseInt(step);

                if (isNaN(step) || !selectedCheckout || step < 1 || step > 3) {
                    return;
                }

                step--;
                if (step < selectedCheckout.$.currentStep) {
                    selectedCheckout.set("currentStep", step);
                } else if (step - 1 == selectedCheckout.$.currentStep && selectedCheckout.performNextAction) {
                    selectedCheckout.performNextAction(null, step);
                }
            },

            _checkContrastPreferences: function() {
                var self = this;
                try {
                    setTimeout(function() {
                        var htmlElements = document.getElementsByTagName('html');
                        if (!htmlElements || !htmlElements.length) {
                            return;
                        }

                        var useHighContrast = false,
                            htmlElement     = htmlElements[0];

                        if (self.$.featureManager.$.highContrast) {
                            useHighContrast = true;
                        } else if (htmlElement.getAttribute("hc") != null) {
                            useHighContrast = true;
                        }


                        if (useHighContrast) {
                            htmlElement.classList.add(HIGH_CONTRAST_CLASS);
                        }
                    }, 1000);


                } catch (e) {
                    console.error(e);
                }
            }
        });
    });



