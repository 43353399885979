define('checkout/RepaymentClass',["xaml!checkout/CheckoutBase", "flow", "checkout/bindable/Root", "checkout/model/CheckoutOrder", "js/core/Bus", "checkout/lib/Modernizr", "js/core/Injection", "sprd/model/Delivery"],
    function (CheckoutBase, flow, Root, CheckoutOrder, Bus, Modernizr, Injection, Delivery) {

        return CheckoutBase.inherit({

            defaults: {

                root: Root,

                order: null,

                // shortcuts
                session: "{{root.session}}",
                delivery: "{{root.delivery}}",
                billingCountry: "{delivery.billingAddress.country}",
                shop: "{{root.shop}}",
                checkout: "{order.checkout}",
                user: "{{root.user}}",

                paymentError: null,

                /***
                 * @codeBehind
                 */
                paymentSummary: null,

                /***
                 * @codeBehind
                 */
                orderManager: null,

                /***
                 * @codeBehind
                 */
                spreadshirtCheckout: null,

                /***
                 * @codeBehind
                 */
                checkoutContainer: null,

                /***
                 * @codeBehind
                 */
                authenticationManager: null,

                /***
                 * @codeBehind
                 */
                checkoutWrapper: null
            },

            inject: {
                bus: Bus
            },

            ctor: function () {
                this.callBase();

                var injection = this.createComponent(Injection);
                injection.addInstance(this.$.root);
            },

            _startHistory: function (callback) {
                callback && callback();
            },

            _start: function (parameter, checkoutToken, callback) {

                var self = this,
                    orderManager = this.$.orderManager,
                    api = this.$.api,
                    order = api.createEntity(CheckoutOrder, parameter.orderId),
                    originalResourcePathToUri = api._resourcePathToUri,
                    root = this.$.root,
                    spreadshirtCheckout = this.$.spreadshirtCheckout;

                orderManager.set("order", order);

                api._resourcePathToUri = function (resourcePath, resource) {
                    var uri = originalResourcePathToUri(resourcePath, resource);

                    if (resource.constructor.name == "sprd.model.Order") {
                        uri = uri.replace("/api/v1", "/rest");
                    }

                    return uri;
                };

                flow()
                    .par(function (cb) {
                        order.fetch({
                            fullData: true
                        }, cb);
                    }, function (cb) {
                        // TODO: initialize session
                        cb();
                    })
                    .seq(function () {

                        var giftWrappingShippingOption = order.$.shippingOptions.find(function (shippingOption) {
                                return shippingOption.$.type == "gift";
                            }),
                            shipping = order.$.shipping,
                            billing = order.$.billing,
                            delivery = new Delivery({
                                shipping: shipping,
                                billing: billing,
                                invoiceToShippingAddress: shipping.$.address.isEqual(billing.$.address),
                                phone: shipping.get("address.phoneNumber"),
                                useGiftWrapping: !!giftWrappingShippingOption,
                                giftWrappingMessage: giftWrappingShippingOption ? giftWrappingShippingOption.$.giftWrappingMessage : null
                            });

                        order.set("delivery", delivery);

                        root.set({
                            basket: order,
                            delivery: delivery,
                            checkout: order.$.checkout
                        });
                    })
                    .par(function (cb) {
                        orderManager.init(cb);
                    }, function (cb) {
                        spreadshirtCheckout.loadPaymentTypes(cb);
                    })
                    .seq(function (cb) {
                        // this starts the history and loads the checkout component
                        // also load is invoked on the checkout
                        self.$stage.$history.start(cb);
                    })
                    .exec(callback);

            },


            _applicationStarted: function (startTime) {
                this.$.trackingManager.trackMainApplicationStarted(startTime);

                if (this.$.paymentError) {
                    this.set('currentStep', 1);
                }

            }
        });
    });



