define('checkout/manager/BamBamClient',["js/core/Component", "underscore"], function(Component, _) {

    return Component.inherit("checkout.manager.BamBamClient", {

        defaults: {
            endpoint: "/bambam",
            enabled: true,
            mode: null
        },

        trackBasket: function(basketId, email, userId, token, newsletterConfirmed, locale, shopId, checkoutUrl) {
            if (!this.$.enabled) {
                return;
            }

            var img = new Image();
            img.src = this._buildUrl(this.$.endpoint + "/baskets", {
                basketId: basketId,
                email: email,
                userId: userId,
                token: token,
                newsletterConfirmed: newsletterConfirmed == true ? 1 : 0,
                locale: locale,
                mode: this.$.mode,
                shopId: shopId,
                checkoutUrl: checkoutUrl
            });
        },

        trackBasketSuccess: function(token) {
            if (!this.$.enabled) {
                return;
            }

            var img = new Image();
            img.src = this._buildUrl(this.$.endpoint + "/orderSuccess", {
                token: token
            });
        },

        _buildUrl: function(baseUrl, queryParameter) {

            var query = [];
            _.map(queryParameter, function(v, k) {
                if (v != null) {
                    query.push(k + "=" + encodeURIComponent(v));
                }
            });

            return baseUrl + "?" + query.join("&");

        }

    });

});
