define('checkout/view/UpsClass',["xaml!checkout/view/Location", "rAppid", "flow", "underscore"], function(Location, rAppid, flow, _) {

    return Location.inherit({

        defaults: {
            componentClass: "ups location-view",
            errorKey: "ups",
            errorMessageKey: "ups_pickup"
        },

        _searchLocationsByText: function(search, callback) {

            var request = this.buildRequest({
                AddressKeyFormat: {
                    CountryCode: this.get("address.country.code"),
                    SingleLineAddress: search
                }
            });

            this._loadData(request, callback);
        },

        _searchLocationsByCoordinates: function(lat, lng, callback) {
            var request = this.buildRequest({
                AddressKeyFormat: {
                    CountryCode: this.$.address.get("country.code")
                },
                Geocode: {
                    Latitude: "" + lat,
                    Longitude: "" + lng
                }
            });

            this._loadData(request, callback);

        },

        _selectLocation: function(location) {

            this.callBase();

            if (!location) {
                return;
            }

            var address = location.$.AddressKeyFormat;
            this.$.address.set({
                ups: {
                    street: this.case(address.AddressLine),
                    streetAnnex: location.$.AccessPointInformation.PublicAccessPointID,
                    company: this.case(address.ConsigneeName),
                    city: this.case(address.PoliticalDivision2),
                    zipCode: address.PostcodePrimaryLow,
                    location: location
                }
            })
        },

        _loadData: function(request, callback) {

            flow()
                .seq("xhr", function(cb) {
                    rAppid.ajax("/abc/locate/ups", {
                        type: "POST",
                        contentType: "application/json",
                        data: JSON.stringify(request)
                    }, cb);
                })
                .seq("data", function() {
                    var xhr = this.vars.xhr;
                    if (!(xhr.status === 200 && xhr.responses.text)) {
                        throw new Error("Error loading locations");
                    }

                    var data = JSON.parse(xhr.responses.text);

                    if (data.LocatorResponse.Error) {
                        throw new Error(data.LocatorResponse.Error.ErrorDescription);
                    }

                    data = _.map(data.LocatorResponse.SearchResults.DropLocation, function(location) {

                        var geocode = location.Geocode;

                        location.AddressKeyFormat.AddressLine = (location.AddressKeyFormat.AddressLine || "")
                            .replace(/UPS ACCESS POINT/g, "")
                            .replace(/&#.+?;/g, "");

                        location.id = location.LocationID;
                        location.title = location.AddressKeyFormat.ConsigneeName;
                        location.position = {
                            lat: geocode.Latitude,
                            lng: geocode.Longitude
                        };

                        return location;
                    });

                    data = _.filter(data, function(location) {
                        return location &&
                            location.AccessPointInformation && location.AccessPointInformation.AccessPointStatus && location.AccessPointInformation.AccessPointStatus.Code == "01" &&
                            location.AccessPointInformation.PublicAccessPointID
                    });

                    return data;
                })
                .exec(function(err, results) {
                    callback(err, results.data);
                });

        },

        formatDistance: function(distance) {

            if (distance && distance.Value) {
                var value = parseFloat(distance.Value);

                if (value < 1) {
                    return parseInt(value * 1000) + " m";
                }

                return Math.round(value, 2) + " km";

            }

        },

        case: function(text) {
            if (!text) {
                return;
            }

            return text.toLocaleLowerCase().split(" ").map(function(word) {
                return word.charAt(0).toUpperCase() + word.substr(1)
            }).join(" ");
        },

        buildRequest: function(originAddress) {

            return {
                    Request: {
                        RequestAction: "Locator",
                        RequestOption: "1",
                        TransactionReference: {
                            "CustomerContext": "Foo Bar"
                        }
                    },
                    OriginAddress: originAddress,
                    Translate: {
                        Locale: this.PARAMETER().locale
                    },
                    UnitOfMeasurement: {
                        Code: "KM"
                    },
                    LocationSearchCriteria: {
                        AccessPointSearch: {
                            AccessPointStatus: "07"
                        },
                        MaximumListSize: "10",
                        SearchRadius: "20"
                    }
            };


        },

        openingHoursSummary: function(location) {
            if (!(location && location.$.StandardHoursOfOperation)) {
                return
            }

            var hoursOfOperation = location.$.StandardHoursOfOperation;

            var replace     = {
                "Mon": "Lun", "Tue": "Mar", "Wed": "Mer", "Thu": "Jeu", "Fri": "Ven", "Sat": "Sam", "Sun": "Dim"
            }, translations = (this.$.i18n.t("date.daysShort") || "").split("_");

            Object.keys(replace).forEach(function(key, index) {
                hoursOfOperation = hoursOfOperation.replace(new RegExp("\\b" + key + "\\b", "g"), translations[index] || replace[key])
            });

            hoursOfOperation = hoursOfOperation.replace(/\b(\d{1,2}:\d{2}\s?)am\b/g, "$1");

            hoursOfOperation = hoursOfOperation.replace(/\b(\d{1,2}):(\d{2})\s?pm/g, function(all, hour, minute) {
                hour = parseInt(hour) + 12;
                hour = hour >= 24 ? hour - 24 : hour;
                return hour + ":" + minute;
            });


            return hoursOfOperation.split(/;/)
                .filter(function(line) {
                    return !line.match(/closed/i);
                })
                .map(function(line) {
                    var strings = line.split(/:/);
                    return {
                        day: strings.shift(),
                        time: strings.join(":")
                    }
                });

        },

    });


});
