define('sprd/model/PendingPayment',["sprd/data/SprdModel", "js/data/Entity"], function (SprdModel, Entity) {
    var PendingPayment = SprdModel.inherit('sprd.model.PendingPayment', {
        defaults: {}
    });

    PendingPayment.Result = Entity.inherit('sprd.entity.PendingPaymentResult', {
        defaults: {
            redirectUrl: String
        }
    });

    PendingPayment.CancelPendingPayment = SprdModel.inherit('sprd.model.CancelPendingPayment', {
        defaults: {},
        resultType: PendingPayment.Result
    });

    return PendingPayment;
});
