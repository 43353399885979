define('checkout/manager/PurchaseManager',["js/core/Component", "sprd/model/Registration", "sprd/data/SprdAccountsDataSourceClass", "sprd/data/SprdApiDataSourceClass", "flow", "sprd/entity/Address", "underscore"], function (Component, Registration, SprdAccountsDataSourceClass, SprdApiDataSourceClass, flow, Address, _) {

    return Component.inherit("checkout.manager.PurchaseManager", {
        defaults: {
            root: null,
            basket: "{root.basket}",
            session: "{root.session}",
            delivery: "{root.basket.delivery}",
            user: "{root.user}"
        },

        inject: {
            accounts: SprdAccountsDataSourceClass,
            api: SprdApiDataSourceClass
        },

        createSession: function (repayment, cb) {
            if (repayment) {
                cb();
                return;
            }

            if (this.$.user) {
                cb(null, this.$.session);
                return;
            }

            var parameter = this.PARAMETER(),
                registration = this.$.accounts.createEntity(Registration),
                mode = parameter.mode,
                isPartner = (mode === "partner"),
                isTeamshirts = parameter.businessUnit === "TEAMSHIRTS";

            registration.set({
                email: this.$.delivery.$.email,
                guest: true,
                country: this.$.basket.$.country,
                language: this.$.basket.$.language
            });

            // newsletter option is checked
            if (this.$.root.$.newsletter) {
                var newsletter = new Registration.Newsletter({
                    shop: undefined
                });

                if (isTeamshirts) {
                    newsletter.setListType('teamshirts', true);
                    newsletter.setListType('customer', false);
                } else if (isPartner) {
                    // for partners disable the spreadshirt newsletter
                    newsletter.setListType('customer', false);
                }

                registration.set('newsletter', newsletter);
            }

            registration.save(null, function (err, registrationResult) {
                cb(err, registrationResult ? registrationResult.$.session : null);
            });
        },

        saveSession: function (sessionFromRegistration) {
            var session = this.$.api.$.session = sessionFromRegistration;

            if (!session) {
                return;
            }

            // save session id for success page to use it in trusted shops
            try {
                this.$stage.$window.sessionStorage.setItem("session", session.$.id);
            } catch (e) {
            }
        },

        saveAddress: function (repayment, saveAddressOnCheckout, cb) {
            if (repayment) {
                cb();
                return;
            }

            if (!saveAddressOnCheckout) {
                cb();
                return;
            }

            if (!this.$.user) {
                cb();
            } else {
                var invoiceToShipping = this.get('delivery.invoiceToShippingAddress');
                var self = this;
                flow()
                    .seq(function (cb) {
                        // save as default shipping address;
                        var shippingAddress = self.get('delivery.shipping.address');
                        if (!shippingAddress.$.defaultShippingAddress) {
                            self.saveAddressAsDefault(shippingAddress, invoiceToShipping ? "both" : "shipping", cb)
                        } else {
                            cb();
                        }
                    })
                    .seq(function (cb) {
                        // save default billing address
                        var billingAddress = self.get('delivery.billing.address');
                        if (!invoiceToShipping && !billingAddress.$.defaultBillingAddress) {
                            self.saveAddressAsDefault(billingAddress, "billing", cb);
                        } else {
                            cb();
                        }
                    })
                    .exec(function () {
                        cb && cb();
                    });
            }
        },

        saveAddressAsDefault: function (address, defaultFor, cb) {
            var addresses = this.$.user.getCollection('addresses');

            var newAddress = addresses.createItem();
            newAddress.set(_.clone(address.$));
            if (newAddress.$.id === "shipping" || newAddress.$.id === "billing") {
                newAddress.set('id', null);
            }

            var oldAddress = addresses.find(function (address) {
                return address && Address.prototype.isEqual.call(address, newAddress);
            });

            if (oldAddress && oldAddress.$.id) {
                newAddress.set('id', oldAddress.$.id);
            }

            switch (defaultFor) {
                case "shipping":
                    newAddress.set('defaultShippingAddress', true);
                    break;
                case "billing":
                    newAddress.set('defaultBillingAddress', newAddress.$.billingAddress);
                    break;
                case "both":
                    newAddress.set({
                        'defaultShippingAddress': true,
                        'defaultBillingAddress': newAddress.$.billingAddress
                    });
            }
            newAddress.save(null, cb);
        }
    });

});
