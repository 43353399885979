define('sprd/manager/AuthenticationManager',["js/core/Component", "xaml!sprd/data/SprdApiDataSource", "flow", "sprd/model/Session", "js/core/Bus"], function(Component, SprdApiDataSource, flow, Session, Bus) {

    return Component.inherit("sprd.manager.AuthenticationManager", {

        defaults: {
            session: null
        },

        inject: {
            api: SprdApiDataSource,
            bus: Bus
        },

        _commitSession: function(session) {
            var api = this.$.api;
            api && api.set("session", session);
        },

        logout: function(callback) {

            var session = this.$.session,
                self = this;

            if (!session) {
                var id = "current";
                session = this.$.api.createEntity(Session, id);
                session.$.id = id;
            }

            session.remove(null, function(err) {
                if (self.$.session === session) {
                    self.set("session", null);
                }

                if (!err) {
                    self.$.bus.trigger('User.logout');
                }

                callback && callback(err);
            });

        }

    });

});
