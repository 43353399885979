define('checkout/manager/CustomTracking',['require', 'js/core/Component', 'js/core/Base', 'js/core/History', 'flow', 'js/core/Bindable', "underscore", "JSON"], function(require, Component, Base, History, flow, Bindable, _, JSON) {

    return Component.inherit('checkout.manager.CustomTracking', {

        defaults: {
            /***
             * enables debugging to console
             * @type Boolean
             */
            debug: false,

            enabled: true,

            frameSrc: null,

            trusted: false,

            frame: null,

            allowForms: false,
        },

        events: [
            "on:error"
        ],

        initialize: function() {
            this.$trackQueue = [];

            this.callBase();
        },

        _queueOrExecute: function(executeFunction) {

            // do not track during node rendering or when disabled
            if (!this.runsInBrowser() || !this.$.enabled) {
                return;
            }

            if (this.$frameWindow) {
                // tracker available
                try {
                    executeFunction.apply(this.$frameWindow);
                } catch (e) {
                    this.log(e, 'error');
                }
            } else {
                // queue it
                this.$trackQueue.push(executeFunction);
            }

        },

        _commitFrameSrc: function(src) {
            if (src && this.$.enabled) {
                var self = this,
                    frame = document.createElement("iframe");
                frame.setAttribute("style", "border: 0 none; width: 0; height: 0; position: absolute");
                var additionalSandbox = this.$.allowForms ? " allow-forms" : "";
                frame.setAttribute("sandbox", "allow-scripts allow-same-origin" + additionalSandbox);
                frame.setAttribute("title", "tracking");

                frame.onload = function () {
                    self.$frameWindow = frame.contentWindow;
                    self._trackQueue();
                };

                frame.onerror = function(e) {
                    self.trigger("on:error", e);
                    console.error(e);
                };

                frame.src = src;
                document.body.appendChild(frame);

                self.set("frame", frame);
            }
        },

        track: function(type, data) {

            var meta = {
                mode: this.PARAMETER().mode
            };

            var trackingData = {
                type: type,
                meta: meta,
                data: data
            };

            if (this.$.trusted) {
                _.extend(meta, {
                    location: window.location.href,
                    referrer: window.document.referrer
                });
            }

            this._queueOrExecute(function() {
                try {
                    this.postMessage(JSON.stringify(trackingData), "*");
                } catch (e) {
                }

                var qaContext = window.qaContext;
                if (qaContext && qaContext.getTrackings()) {
                    try {
                        qaContext.getTrackings().push({
                            service: "custom",
                            type: "track",
                            data: trackingData
                        });
                    } catch (e) {
                    }
                }

            });

            this._debug(['track: ', trackingData]);
        },

        _debug: function(message) {
            if (this.$.debug) {
                this.log(message);
            }
        },


        _trackQueue: function() {
            var self = this;
            // track all events from queue

            _.each(this.$trackQueue, function(executeFunction) {
                try {
                    executeFunction.apply(self.$frameWindow);
                } catch (e) {
                    console.error(e);
                }
            });

            self.$trackQueue = [];

        }

    });
});

