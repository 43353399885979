define('checkout/view/BasketOrderClass',["checkout/view/ViewBase", "sprd/manager/ApiBasketManager", "checkout/manager/TrackingManager", "checkout/manager/UrlManager", "js/core/Bus", "js/data/DataSource", "sprd/model/Shop", "flow", "sprd/data/ImageService", "checkout/manager/ConfigurationManager", "xaml!checkout/view/MobileZoom", "js/core/Bindable", "underscore", "js/core/List"],
    function (ViewBase, ApiBasketManager, TrackingManager, UrlManager, Bus, DataSource, Shop, flow, ImageService, ConfigurationManager, MobileZoom, Bindable, _, List) {

        var GRAPHIC_SERVICE_ID = 667;
        var SCREEN_PRINTING_PRINT_TYPE_ID = '5';
        var SCREEN_PRINTING_MIN_ITEMS = 20;

        var Group = Bindable.inherit({

            defaults: {
                sizes: {},
                item: null,
                outOfStock: "{outOfStock()}",
                items: List
            },

            events: [
                "updated"
            ],

            outOfStock: function () {
                return _.filter(this.$.items.$items, function (x) {
                    return !!x.$.outOfStock
                }).length > 0;
            }.on(["items", "item:change:outOfStock"], "updated"),

            update: function () {
                this.trigger("updated");
            },

            isSizeOutOfStock: function (size) {

                if (!size) {
                    return false;
                }

                var items = this.$.sizes[size.$.id] || [];
                return _.find(items.$items || items, function (item) {
                    return item.$.outOfStock;
                });
            }.onChange("outOfStock", "updated"),

            unavailableClass: function () {
                return !this.get("item.available") ? "unavailable" : "";
            }.onChange("item.available"),

            getQuantity: function (size) {

                var quantity = 0;
                if (size) {
                    var items = this.$.sizes[size.$.id] || [];
                    _.each(items, function (item) {
                        quantity += item.$.quantity;
                    });
                }

                return quantity;

            }.on("updated"),

            totalPrice: function (priceType) {

                var basketItems = [];
                _.each(this.$.sizes, function (size) {
                    basketItems = basketItems.concat(size);
                });

                var total = 0;
                _.each(basketItems, function (basketItem) {
                    total += basketItem.totalPrice(priceType);
                })

                return total;

            }.on("updated"),

            selectedSizes: function () {
                return _.keys(this.$.sizes).length;
            }.on("updated")
        });

        return ViewBase.inherit("checkout.view.BasketClass", {

            inject: {
                basketManager: ApiBasketManager,
                trackingManager: TrackingManager,
                urlManager: UrlManager,
                imageService: ImageService,
                bus: Bus,
                configurationManager: ConfigurationManager
            },

            defaults: {
                basketOrder: null,
                delivery: null,
                updatingBasket: false,
                componentClass: "order {basketStatusClass()}",
                showShippingTypeName: false,
                editable: true,
                summary: false,
                selectedView: null,
                allowEditing: true,
                allowDuplicate: false,
                zoomContainer: null,
                showFreeShippingInfo: false,
                showDeliveryEta: false,
                showVoucher: true,

                items: "{basketOrder.items()}",
                priceType: "{configurationManager.priceType()}",
                groups: "{groupItems(items)}"
            },

            events: [
                "on:editShippingType"
            ],

            ctor: function () {
                this.callBase();

                this.bind('basketManager', 'on:basketUpdated', this._checkIfBasketEmpty, this);

                this.bind('basketManager', 'on:basketUpdated', function () {
                    this.set('allowEditing', true);

                    var groups = this.$.groups;
                    _.each(groups, function (group) {
                        group.update();
                    })

                }, this);

                this.bind('basketManager', 'on:basketSaving', function () {
                    this.set('allowEditing', false);
                }, this);
            },

            outOfStockClass: function (outOfStock) {
                return outOfStock ? "out-of-stock" : "";
            },

            updateBasket: function () {
                var self = this;
                this.$.basketManager.saveBasketDebounced(function () {
                    self.trigger("refreshGroups");
                });
            },

            or: function (a, b) {
                return a || b
            },


            groupItems: function (items) {

                if (!items) {
                    return;
                }

                var currentGroups = this.$.groups;
                var showSizesOfCurrentGroups = {};
                if (currentGroups) {
                    for (var currentGroupIndex = 0; currentGroupIndex < currentGroups.length; currentGroupIndex++) {
					   var currentGroup = currentGroups[currentGroupIndex];
					   showSizesOfCurrentGroups[[currentGroup.$.product.$.id, currentGroup.$.appearanceId].join("-")] = currentGroup.$.showSizes;
				   }
                }

                var groups = {};
                var groupList = [];

                items.each(function (item) {
                    var product = item.get("element.getProduct()"),
                        appearanceId = item.get("element.appearance.id"),
                        sizeId = item.get("element.size.id"),
                        groupId = [product.$.id, appearanceId].join("-");

                    if (!groups[groupId]) {
                        var productType = product.get("productType");
                        var group = new Group({
                            item: item,
                            product: product,
                            showSizes: Boolean(showSizesOfCurrentGroups[groupId]),
                            productType: productType,
                            appearanceId: appearanceId,
                            appearance: null,
                            editLink: item.get("element.editLink"),
                            sizes: {}
                        });

                        var printTypes = [],
                            designs = [];

                        var configurations = product.get("configurations");
                        configurations.each(function (configuration) {
                            printTypes.push(configuration.$.printType);

                            if (configuration.$.type === "design") {

                                configuration.$.designs.each(function (design) {
                                    designs.push(design);
                                });
                            }
                        });

                        _.uniq(printTypes, function (printType) {
                            return printType.$.id;
                        }).forEach(function (printType) {
                            printType.fetch(null);
                        });

                        _.uniq(designs, function (design) {
                            return design.$.id;
                        }).forEach(function (design) {
                            design.fetch(null);
                        });

                        productType.fetch(null, function (err) {
                            if (err) {
                                return;
                            }

                            group.set("appearance", productType.getAppearanceById(appearanceId));
                        });

                        groupList.push(groups[groupId] = group)
                    }

                    groups[groupId].$.items.add(item);

                    (groups[groupId].$.sizes[sizeId] || (groups[groupId].$.sizes[sizeId] = [])).push(item);
                });


                return groupList;

            }.on("refreshGroups"),

            toggleGroup: function (group) {
                group && group.set("showSizes", !group.$.showSizes);
            },

            sizeText: function (showSizes) {
                return showSizes ? this.$.i18n.t('basket.hideSizes') : this.$.i18n.t('basket.showSizes');
            },

            getItemClass: function (item) {
                return [
                    item.$.outOfStock ? 'out-of-stock' : '',
                    item.$.available ? '' : 'unavailable'
                ].join(" ");
            },

            zoomImageUrl: function (product, appearanceId, view) {
                var imageService = this.$.imageService;

                if (imageService && product && appearanceId) {
                    return imageService.productImage(product.$.lookupId, view ? view.$.id : product.getDefaultViewId(), appearanceId, 'product', {
                        width: 1200,
                        height: 1200,
                        mediaType: 'jpeg'
                    });
                }

            },

            openZoom: function (basketItem) {
                if (!this.isMobile()) {
                    return;
                }

                var mobileZoom = this.createComponent(MobileZoom);

                mobileZoom.set({
                    basketItems: this.$.basketOrder.items(),
                    selectedItem: basketItem
                });

                mobileZoom.showModal();
            },

            closeMenu: function (menu) {
                menu.closeMenu();
            },

            addNewItemSize: function (group, size, quantity) {
                var basketManager = this.$.basketManager;
                var basketItems = basketManager.$.basket.$.basketItems;
                var newItem = basketItems.createItem();
                var element;
                var item = group.$.item;
                var originalElement = item.$.element;

                var editLink = originalElement.$.editLink;
                // set editLink to empty string, otherwise it could be interpreted as string with binding
                originalElement.set('editLink', "");
                element = originalElement.clone();
                element.set("editLink", (editLink || "").replace(item.$.id, "{BASKET_ITEM_ID}"));
                element.set("size", size);
                originalElement.set('editLink', editLink);

                var origin = item.$.origin;

                newItem.set({
                    element: element,
                    quantity: quantity || 1,
                    origin: origin
                });

                group.$.sizes[size.$.id] = [newItem];
                group.update();

                basketItems.add(newItem);

                this.updateBasket();
            },

            decreaseQuantity: function (sizeEntry, group, size) {

                var basketItem = group.$.sizes[size.$.id][0];
                basketItem.decreaseQuantity();

                if (basketItem.$.quantity === 0) {
                    // item needs to be removed
                    this.$.items.remove(basketItem);
                }

                sizeEntry.set("_quantity", group.getQuantity(size));
                this.updateBasket();

                this.trigger("quantityUpdated");
            },

            increaseQuantity: function (sizeEntry, group, size) {

                var sizes = group.$.sizes[size.$.id];

                if (sizes && sizes.length > 0) {
                    sizes[0].increaseQuantity();

                    sizeEntry.set("_quantity", group.getQuantity(size));
                    this.updateBasket();
                } else {
                    this.addNewItemSize(group, size);
                }

                this.trigger("quantityUpdated");
            },

            onQuantityChange: function (e) {
                var sizeEntry = e.target.find('sizeEntry'),
                    group = e.target.find("group"),
                    size = e.target.find("size");

                if (!sizeEntry || !group || !size) {
                    return;
                }

                var value = e.target.$.value;
                if (_.isString(value) && !value.match(/^[0-9]{1,3}$/)) {
                    value = NaN;
                } else {
                    value = parseInt(value);
                }

                if (isNaN(value) || value < 1) {
                    e.target.set("value", group.getQuantity(size));
                    return;
                }

                if (this._isScreenPrintingProductGroup(group)) {
                    var updatedQuantity = this._countGroupQuantity(group, size.$.id, value);
                    if (updatedQuantity < SCREEN_PRINTING_MIN_ITEMS) {
                        value += SCREEN_PRINTING_MIN_ITEMS - updatedQuantity;
                        e.target.set("value", value);
                    }
                }

                var sizes = group.$.sizes[size.$.id];

                if (sizes && sizes.length > 0) {
                    var basketItem = sizes[0];
                    basketItem.set("quantity", value);

                    for (let i = 1; i < sizes.length; i++) {
                        this.$.items.remove(sizes[i]);
                    }

                    this.updateBasket();
                } else {
                    this.addNewItemSize(group, size, value);
                }

                sizeEntry.set("_quantity", group.getQuantity(size));

                this.trigger("quantityUpdated");
            },

            handleDelete: function (group, size) {

                var basket = this.$.basketOrder,
                    sizeId = size.$.id;

                var sizes = group.$.sizes[sizeId];
                var basketItem = sizes[0];

                sizes.splice(0, 1);

                if (sizes.length === 0) {
                    delete group.$.sizes[sizeId];
                }

                group.$.items.remove(basketItem);
                group.update();
                
                basket.$.basketItems.remove(basketItem);

                this.updateBasket();
            },

            handleDeleteAll: function (group) {
                var basket = this.$.basketOrder
                var sizes = group.$.sizes;
                var basketItemsToRemove = [];

                for (var sizeId in sizes) {
                    if (sizes.hasOwnProperty(sizeId)) {
                        var basketItems = sizes[sizeId];
                        for (var basketItemIndex = 0; basketItemIndex < basketItems.length; basketItemIndex++) {
                            basketItemsToRemove.push(basketItems[basketItemIndex]);
                        }
                    }
                }

                basket.$.basketItems.remove(basketItemsToRemove);
                this.updateBasket();
            },

            _checkIfBasketEmpty: function () {
                var basket = this.$.basketOrder;
                if (basket && basket.$.basketItems.$items.length === 0) {
                    this.$.trackingManager.trackEmptyBasket(basket);
                    this.$.bus.trigger("Application.ShowLoader");
                    window.location = this.PARAMETER().emptyBasketUrl || this.$.urlManager.url('emptyBasket');
                }
            },

            gt: function (quantity, b) {
                return quantity > b;
            },

            lt: function (quantity, b) {
                return quantity < b;
            },

            eql: function (a, b) {
                return a === b;
            },

            basketStatusClass: function () {
                return this.get("basketManager.updating") ? "updating" : "";
            }.onChange("basketManager.updating"),

            evenClass: function (index) {
                return index % 2 ? "odd" : "even";
            },

            editShippingType: function () {
                this.trigger("on:editShippingType")
            },

            _isQuantityEditable: function (productTypeId) {
                return productTypeId != GRAPHIC_SERVICE_ID;
            },

            /**
             * check if the quantity of a size can be decreased or the size removed
             * @param {Group} group
             * @returns {boolean}
             * @private
             */
            canQuantityDecrease: function (group) {
                return !this._isScreenPrintingProductGroup(group) || this._countGroupQuantity(group) > SCREEN_PRINTING_MIN_ITEMS;
            }.on("quantityUpdated"),

            /**
             * only show the info if the user cannot decrease any size quantity
             * @param {Group} group
             * @returns {boolean}
             * @private
             */
            showScreenPrintingMinItemInfo: function (group) {
                return this._isScreenPrintingProductGroup(group) && this._countGroupQuantity(group) <= SCREEN_PRINTING_MIN_ITEMS;
            }.on("quantityUpdated"),

            /**
             * checks if any configuration of a product has screen printing as print type
             * @param {Group} group
             * @returns {boolean}
             * @private
             */
            _isScreenPrintingProductGroup: function (group) {
                var configurations = group.$.product.$.configurations.$items;
                for (var configurationIndex = 0; configurationIndex < configurations.length; configurationIndex++) {

                    if (configurations[configurationIndex].$.printType.$.id === SCREEN_PRINTING_PRINT_TYPE_ID) {
                        return true;
                    }
                }

                return false;
            },

            /**
             * calculates the total quantity of the whole group
             *  takes an optional size-ID and an updated quantity
             *  to calculate the updated total quantity
             * @param {Group} group
             * @param {string} [size] - size id
             * @param {number} [quantity] - updated quantity
             * @returns {number}
             * @private
             */
            _countGroupQuantity: function (group, size, quantity) {
                var groupQuantity = 0;
                var sizes = group.$.sizes;
                for (var sizeId in sizes) {
                    if (sizes.hasOwnProperty(sizeId)) {
                        if (sizeId === size) {
                            groupQuantity += quantity;
                        } else {
                            var items = sizes[sizeId];
                            for (var itemIndex = 0; itemIndex < items.length; itemIndex++) {
                                groupQuantity += items[itemIndex].$.quantity;
                            }
                        }
                    }
                }

                return groupQuantity;
            },

            and: function () {

                for (var i = 0; i < arguments.length; i++) {
                    if (!arguments[i]) {
                        return false;
                    }
                }

                return true;

            },

            touchTabIndex: function () {
                return this.$stage.$browser.hasTouch ? "-1" : "0";
            },

            basketHasOutOfStockItems: function () {
                var parameter = this.PARAMETER(),
                    errorCode = parameter.errorCode;

                return errorCode == "86017";
            },

            basketHasEmptyProductsOverLimit: function () {
                var parameter = this.PARAMETER(),
                    result = parameter.result,
                    errorCode = parameter.errorCode;

                return result == "FAILED_ERROR" && errorCode == "87107";
            },

            isMobile: function () {
                return this.$stage.$browser.isMobile
            }
        });
    });

