define('checkout/view/ShippingTypeViewClass',["checkout/view/RootViewBase", "checkout/config/Constant", "sprd/entity/Price", "sprd/lib/ScrollIntoView", "checkout/manager/DeliveryEtaManager", "underscore"], function(RootViewBase, Constant, Price, ScrollIntoView, DeliveryEtaManager, _, FilterDataView) {

    var emailShippingCost = null;



    return RootViewBase.inherit({

        defaults: {
            totalQuantity: "{basket.totalItemsCount()}",
            basket: null,
            addressType: "{basket.delivery.shipping.address.type}"
        },

        inject: {
            deliveryEtaManager: DeliveryEtaManager
        },

        getCost: function(cost, shippingType) {

            if (!cost && shippingType && shippingType.$.id == Constant.SHIPPING_TYPE.EMAIL && !emailShippingCost) {
                // exception for shipping via mail, as there are not shipping regions and costs delivered by the api
                emailShippingCost = new Price({
                    currency: this.get("root.basket.currency")
                });
            }

            return cost || emailShippingCost;
        },

        possibleShippingTypesWithPrice: function(shippingTypes, shippingCountry, shippingState, orderValue) {

            return _.filter(shippingTypes, function(shippingType) {

                if (shippingType.$.id == Constant.SHIPPING_TYPE.EMAIL) {
                    return true;
                }

                var country = shippingType.getShippingCountryByCode(shippingCountry.$.code);

                if (!country) {
                    return false;
                }

                var region = shippingState ? shippingType.getShippingRegionById(shippingState.get("shippingRegion.id")) : country.$.shippingRegion;
                if (!region) {
                    return false;
                }

                var costsForOrderValue = region.getShippingCostsForOrderValue(orderValue);

                return costsForOrderValue && !!costsForOrderValue.$.cost;
            })

        },

        isEmpty: function(list) {
            return !list || list.length === 0;
        },

        getAdvantages: function(shippingType) {

            if (!shippingType) {
                return;
            }

            var i18n = this.$.i18n;

            var prefix = 'shippingType' + (this.PARAMETER().platform || "").toUpperCase();
            
            return _.filter([
                i18n.t([prefix, shippingType.$.id, "advantage1"].join(".")),
                i18n.t([prefix, shippingType.$.id, "advantage2"].join(".")),
                i18n.t([prefix, shippingType.$.id, "advantage3"].join(".")),
                i18n.t([prefix, shippingType.$.id, "advantage4"].join(".")),
                i18n.t([prefix, shippingType.$.id, "advantage5"].join("."))
            ], function(x) {
                return !!x
            });

        },

        eql: function(a, b) {
            return a == b;
        },

        scrollToShippingTypeSection: function() {
            this.$el && ScrollIntoView(this.$el);
        }.bus("Checkout.ScrollToShippingType")

    });


});

