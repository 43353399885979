define('checkout/manager/CustomTrackings',['js/core/Component', 'checkout/manager/CustomTracking', 'underscore'], function(Component, CustomTracking, _) {

    return Component.inherit('checkout.manager.CustomTrackings', {

        defaults: {
            trackers: []
        },

        addChild: function(child) {
            this.callBase();

            if (child instanceof CustomTracking) {
                this.$.trackers.push(child);
            }
        },

        track: function(type, data) {
            _.each(this.$.trackers, function(tracker) {
                tracker.track(type, data);
            });
        }

    });
});
