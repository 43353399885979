define('checkout/config/Constant',[], function() {

    return {
        SHIPPING_TYPE: {
            EMAIL: 12
        },
        ORIGIN: {
            MARKETPLACE: 42
        }
    }

});
