define('checkout/manager/PaymentTypeManager',[
    "js/core/Component",
    "checkout/entity/payment/CreditCardPayment",
    "checkout/entity/payment/PayPalPayment",
    "checkout/entity/payment/SepaPayment",
    "checkout/entity/payment/IdealPayment",
    "sprd/entity/Payment"
], function (Component, CreditCardPayment, PayPalPayment, SepaPayment, IdealPayment, Payment) {

    var paymentTypePaymentMap = {
        CREDITCARD: CreditCardPayment,
        PAYPAL: PayPalPayment,
        SEPA_DIRECT_DEBIT: SepaPayment,
        PREPAYMENT: Payment,
        IDEAL: IdealPayment,
        INVOICE: Payment
    };

    var paymentTypeToPaymentView = {
        CREDITCARD: "checkout/view/payment/CreditCard",
        PAYPAL: "checkout/view/payment/PayPal",
        SEPA_DIRECT_DEBIT: "checkout/view/payment/Sepa",
        PREPAYMENT: "checkout/view/payment/PrePayment",
        IDEAL: "checkout/view/payment/Ideal",
        INVOICE: "checkout/view/payment/Invoice"
    };

    var paymentTypeToPaymentSummary = {
        CREDITCARD: "checkout/view/payment/CreditCardSummary",
        SEPA_DIRECT_DEBIT: "checkout/view/payment/SepaSummary",
        IDEAL: "checkout/view/payment/IdealSummary"
    };

    return Component.inherit("checkout.manager.PaymentTypeManager", {

        defaults: {
            root: null
        },

        ctor: function () {
            this.$paymentCache = {};
            this.callBase();
        },

        getPaymentForPaymentType: function (paymentType) {

            var category = paymentType.$.categoryCode;
            var factory = paymentTypePaymentMap[category];

            if (!factory) {
                this.log("No payment for '" + category + "' found.", "warn");
                return null;
            }

            var payment = new factory({
                root: this.$.root,
                paymentType: paymentType
            });

            return this.$paymentCache[category] || (this.$paymentCache[category] = payment);
        },

        getPaymentSummaryFqClassNameForCategory: function (paymentType) {

            if (!paymentType) {
                return null;
            }

            return paymentTypeToPaymentSummary[paymentType.$.categoryCode];
        },

        getPaymentViewFqClassNameForCategory: function (paymentType) {
            return paymentTypeToPaymentView[paymentType.$.categoryCode];
        },

        clear: function () {
            this.$paymentCache = {};
        }
    });
});
