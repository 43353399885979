define('checkout/entity/payment/PayPalPayment',['sprd/entity/Payment'], function (Payment) {

    return Payment.inherit('checkout.entity.payment.PayPalPayment', {
        type: "paypal",

        defaults: {
            isWebComponentPayment: true
        }
    });
});
