define('sprd/model/AdyenWebComponentPayment',["sprd/data/SprdModel", "js/data/Entity"], function (SprdModel, Entity) {
    var Payment = {}

    Payment.PaymentResult = Entity.inherit('sprd.model.PaymentResult', {
        defaults: {},
        schema: {
            action: {
                type: Object,
                required: false
            },
            type: String,
            redirectUrl: {
                type: String,
                required: false
            }
        }
    })

    Payment.InitiatePaymentResult = Payment.PaymentResult.inherit('sprd.model.InitiatePaymentResult', {
        schema: {
            pendingPaymentId: {
                type: String,
                required: false
            }
        }
    })

    Payment.InitiatePayment = SprdModel.inherit("sprd.model.InitiatePayment", {

        defaults: {},

        resultType: Payment.InitiatePaymentResult,

        schema: {
            data: {
                type: Object,
                required: true
            },
            paymentTypeId: {
                type: Number,
                required: true
            },
            returnUrl: {
                type: String,
                required: true
            }
        }

    })

    Payment.AdditonalDetails = SprdModel.inherit("sprd.model.AdditionalDetails", {
        defaults: {},
        schema: {
            pendingPaymentId: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                required: true
            }
        },
        resultType: Payment.PaymentResult
    })


    return Payment;
});
