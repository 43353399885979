define('checkout/view/NewsletterClass',["checkout/view/ViewBase", "checkout/bindable/Root", "checkout/manager/UrlManager"], function (ViewBase, Root, UrlManager) {
    return ViewBase.inherit({
        inject: {
            root: Root,
            urlManager: UrlManager
        },

        replaceLink: function(text, link) {
            return (text || "").replace(/\[([^[\]|]+)\|\d+\]/gi, '<a href="' + link + '" target="_blank">$1</a>')
        },

    });
});
