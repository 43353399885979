define('checkout/view/SliderClass',["js/ui/View"], function(View) {

    return View.inherit({

        defaults: {
            componentClass: "slider",

            /***
             * @codeBehind
             */
            slides: null,

            slideCount: "{slideCount()}",
            currentSlideIndex: 0
        },

        $defaultContentName: "slides",

        slideCount: function() {
            var slides = this.$.slides;
            if (!slides) {
                return 0;
            }

            return slides.$renderedChildren.length;

        }.on("childrenChanged"),

        showNavigation: function() {
            return this.$.slideCount != 1;
        }.onChange("slideCount"),

        canSlideLeft: function() {
            return this.$.currentSlideIndex > 0
        }.onChange("currentSlideIndex"),

        canSlideRight: function() {
            return this.$.currentSlideIndex < this.$.slideCount - 1
        }.onChange("currentSlideIndex", "slideCount"),

        width: function() {
            return (this.$.slideCount * 100) + "%";
        }.onChange("slideCount"),

        slide: function(add) {
            this.set("currentSlideIndex", this.$.currentSlideIndex + add);
        },

        transform: function() {
            return "translate(-" + this.$.currentSlideIndex * (100 / this.$.slideCount) + "%, 0)";
        }.onChange("currentSlideIndex", "slideCount"),

        render: function() {
            var ret = this.callBase();

            this.trigger("childrenChanged");

            var slides = this.$.slides;
            if (slides) {
                var renderedChildren = slides.$renderedChildren;
                for (var i = 0; i < renderedChildren.length; i++) {
                    var obj = renderedChildren[i];
                    obj.set("width", 100 / renderedChildren.length + "%")
                }

            }

            return ret;
        }


    });

});
