define('checkout/ErrorProvider',["js/core/ErrorProvider", "js/core/Bindable"], function (ErrorProvider, Bindable) {

    return ErrorProvider.inherit('checkout.ErrorProvider', {
        getErrorMessage: function (error) {
            if (error && error.$.message == "COUPON_ERROR") {
                var args = ["error.coupon." + error.$.code];
                args = args.concat(error.$.values);
                return this.$.i18n.t.apply(this.$.i18n, args) || this.$.i18n.t("error.coupon.generic");
            }

            if (error && error.$.code === "vatIdError") {
                debugger;
            }

            return this.callBase(error);
        }

    });

});
