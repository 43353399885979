define('sprd/model/Design',["sprd/data/SprdModel"], function (SprdModel) {

    return SprdModel.inherit('sprd.model.Design', {
        identifier: function () {
            var lookupId = this.$['lookupId'];
            return lookupId ? lookupId : this.$[this.idField]
        }
    });

});
