define('checkout/service/GeolocationService',["js/core/Component", "flow", "require"], function (Component, flow, require) {

    var geoCoder,
        google,
        addressComponentMap = {
            street_number: "houseNumber",
            route: "street",
            locality: "city",
            administrative_area_level_1: "state",
            country: "country",
            postal_code: "zipCode"
        };

    return Component.inherit("js.service.GeolocationService", {

        supportsGeolocation: function () {
            return this.runsInBrowser() && "geolocation" in window.navigator;
        },

        /***
         *
         * @param {Object} [options]
         * @param callback
         */
        getCurrentPosition: function (options, callback) {

            if (arguments.length === 1) {
                callback = options;
                options = null;
            }

            if (this.supportsGeolocation()) {
                window.navigator.geolocation.getCurrentPosition(function (position) {
                    callback && callback(null, position);
                }, function (err) {
                    callback && callback(err || true);
                }, options);
            } else {
                callback && callback("Geolocation isn't supported");
            }

        },

        _getGeoCoder: function (callback) {
            callback = callback || this.emptyCallback;

            if (geoCoder) {
                callback && callback(null, geoCoder);
            } else {

                var callbackName = "GeolocationService_" + this.$cid;
                window[callbackName] = function() {
                    delete window[callbackName];
                    geoCoder = new google.maps.Geocoder();
                    callback && callback(null, geoCoder);
                };

                require(["https://maps.googleapis.com/maps/api/js?v=3&sensor=false&callback=" + callbackName], function () {
                    google = window["google"];
                }, function (err) {
                    delete window[callbackName];

                    callback(err);
                });
            }

        },

        _decodeAddress: function(position, callback) {

            var self = this,
                coordinates = position.coords;

            flow()
                .seq(function(cb) {
                    self._getGeoCoder(cb);
                })
                .seq("address", function(cb) {
                    var latLng = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);

                    geoCoder.geocode({'latLng': latLng}, function (results, status) {

                        if (status == google.maps.GeocoderStatus.OK) {
                            cb(null, results);
                        } else {
                            cb(status || true);
                        }

                    });
                })
                .exec(function(err, results) {
                    callback(err, results.address);
                })

        },

        parseAddresses: function(addresses) {

            var ret = null;
            if (addresses) {

                ret = {};

                var components = 0,
                    componentCount = 6;

                for (var i = 0; i < addresses.length; i++) {
                    var addressComponents = addresses[i].address_components;

                    if (addressComponents) {
                        for (var j = 0; j < addressComponents.length; j++) {
                            var addressComponent = addressComponents[j];

                            for (var k = 0; k < addressComponent.types.length; k++) {
                                var type = addressComponent.types[k],
                                    mappedType = addressComponentMap[type];

                                if (mappedType && !ret[mappedType]) {
                                    components++;
                                    ret[mappedType] = addressComponent.long_name;

                                    if (mappedType === "country") {
                                        ret["country_code"] = addressComponent.short_name;
                                    }

                                }
                            }
                        }
                    }

                    var countryCode = ret["country_code"];

                    if (!ret["city"]) {
                        if (["DE"].indexOf(countryCode) >= 0) {
                            ret["city"] = this.getValueOfAddressComponentType(addressComponents, "administrative_area_level_4");
                        } else if ("FI".indexOf(countryCode) >= 0) {
                            ret["city"] = this.getValueOfAddressComponentType(addressComponents, "administrative_area_level_3");
                        }
                    }

                    if (["SE"].indexOf(countryCode) >= 0) {
                        ret["city"] = this.getValueOfAddressComponentType(addressComponents, "postal_town");
                    }

                    if (components === componentCount) {
                        break;
                    }
                }
            }

            return ret;
        },

        getCurrentAddresses: function (options, callback) {
            if (arguments.length === 1) {
                callback = options;
                options = null;
            }

            var self = this;

            flow()
                .seq("position", function (cb) {
                    self.getCurrentPosition(options, cb);
                })
                .seq("address", function(cb) {
                    self._decodeAddress(this.vars.position, cb)
                })
                .exec(function (err, results) {
                    callback && callback(err, results.address);
                });

        },

        getCurrentAddress: function(options, callback) {

            if (arguments.length === 1) {
                callback = options;
                options = null;
            }

            var self = this;

            flow()
                .seq("addresses", function(cb) {
                    self.getCurrentAddresses(options, cb);
                })
                .seq("address", function() {
                    return self.parseAddresses(this.vars.addresses);
                })
                .exec(function(err, result) {
                    callback && callback(err, result.address);
                });
        },

        getValueOfAddressComponentType: function (addressComponents, type) {
            if (addressComponents) {

                for (var j = 0; j < addressComponents.length; j++) {
                    var addressComponent = addressComponents[j];

                    for (var k = 0; k < addressComponent.types.length; k++) {
                        var currentType = addressComponent.types[k];
                        if (currentType === type) {
                            return addressComponent.long_name;
                        }

                    }
                }
            }
        }

    });
});
