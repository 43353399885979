define('checkout/entity/payment/IdealPayment',['sprd/entity/Payment'], function (Payment) {

    return Payment.inherit('checkout.entity.payment.IdealPayment', {
        type: "ideal",

        defaults: {
            issuer: null,
            isWebComponentPayment: true
        },

        setIssuer: function (issuerId) {
            if (issuerId && this.$.isWebComponentPayment) {
                var issuer = this.getWebComponentConfiguration().$.issuers.find(function (issuer) {
                    return issuer.id === issuerId;
                });

                if (issuer) {
                    this.set('issuer', issuer);
                }
            }
        }
    });
});
