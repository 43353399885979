define('checkout/bindable/Root',["js/core/Bindable", "underscore", "checkout/manager/PaymentTypeManager", "js/core/List", "checkout/config/Constant"], function (Bindable, _, PaymentTypeManager, List, Constant) {

    var SHIPPING_TYPE = Constant.SHIPPING_TYPE;

    return Bindable.inherit({

        defaults: {
            basket: null,

            shop: null,

            session: null,

            paymentType: null,

            paymentTypes: null,

            checkout: "{basket.checkout}",

            createAccount: false,

            newsletter: true,

            delivery: "{basket.delivery}",

            shippingTypes: "{shop.shippingTypes}",

            deliveryCountry: "{basket.delivery.shipping.address.country}",
            deliveryState: "{basket.delivery.shipping.address.state}",

            possibleShippingTypes: "{getShippingTypesForCountry(deliveryCountry)}",
            possibleShippingTypesForAddressType: "{possibleShippingTypes(delivery.shipping.address.type)}",

            giftWrappingShippingOption: null
        },

        inject: {
            paymentTypeManager: PaymentTypeManager
        },

        ctor: function () {
            this.callBase();


            var invoiceToShippingAddress;

            this.bind(["delivery.shipping.address", "change:type"], function () {
                var type = this.get("delivery.shipping.address.type");

                if (!type) {
                    return;
                }

                var delivery = this.$.delivery;

                if (type === "PRIVATE") {
                    delivery.set("invoiceToShippingAddress", invoiceToShippingAddress || true);

                    delivery.$.shipping.set("type", this.$.shippingTypes.at(0));

                } else {
                    invoiceToShippingAddress = delivery.$.invoiceToShippingAddress;
                    delivery.set("invoiceToShippingAddress", false);
                    delivery.$.billing.$.address.set({
                        person: delivery.$.shipping.$.address.$.person.clone()
                    });
                }

            }, this);
        },

        _postConstruct: function () {
            this.set("newsletter", this.newsletterAvailable());

            this.trigger("postConstruct");
        },

        setPaymentTypes: function (paymentTypes, cb) {
            var paymentTypeManager = this.$.paymentTypeManager;
            paymentTypeManager && paymentTypeManager.clear();

            var paymentTypesToSave = _.chain(paymentTypes)
                .filter(function (item) {
                    return item.$.supported !== false;
                }).uniq(function (item) {
                    return item.$.categoryCode;
                }).value();

            if (paymentTypesToSave && paymentTypesToSave.length === 1) {
                this.set('paymentType', paymentTypesToSave[0]);
            } else {
                this.set('paymentType', null);
            }

            this.set('paymentTypes', paymentTypesToSave, {force: true});
            cb(null, paymentTypesToSave);
        },

        _commitPossibleShippingTypesForAddressType: function (shippingTypes) {
            if (shippingTypes && this.$.basket && this.$.basket.$.delivery) {

                var delivery = this.$.basket.$.delivery;
                var shippingType = delivery.get("shipping.type");

                if (!shippingType || _.indexOf(shippingTypes, shippingType) === -1) {
                    // select first shipping type or shipping type not available any more
                    delivery.$.shipping.set("type", shippingTypes[0]);
                }
            }
        },

        _commitDeliveryCountry: function (country) {
            if (country && this.$.giftWrappingShippingOption) {
                var option = this.$.giftWrappingShippingOption.$.optionCosts.find(function (cost) {
                    return cost.get('country.id') == country.get('code');
                });
                if (!option) {
                    this.$.delivery.set('useGiftWrapping', false);
                }
            }

        },

        shippingCountries: function () {
            var ret = [],
                retIds = [],
                shippingTypes = this.$.shippingTypes;

            if (shippingTypes) {
                shippingTypes.each(function (shippingType) {

                    shippingType.$.shippingCountries.each(function (shippingCountry) {
                        if (_.indexOf(retIds, shippingCountry.$.id) === -1) {
                            ret.push(shippingCountry);
                            retIds.push(shippingCountry.$.id);
                        }
                    });
                });
            }

            ret.sort(function (a, b) {
                return a.$.name.localeCompare(b.$.name);
            });

            return ret;

        }.onChange("shippingTypes"),

        getShippingTypesForCountry: function (shippingCountry) {

            var ret = [],
                shippingTypes = this.$.shippingTypes;

            if (shippingCountry && shippingTypes) {
                if (this.$.basket.$.supportsEmailShipping) {
                    return [shippingTypes.find(function (shippingType) {
                        return shippingType.$.id == SHIPPING_TYPE.EMAIL;
                    })];
                } else {
                    shippingTypes.each(function (shippingType) {

                        if (shippingType.$.id != SHIPPING_TYPE.EMAIL && shippingType.supportsShippingTo(shippingCountry)) {
                            ret.push(shippingType);
                        }

                    });

                    ret.sort(function (a, b) {
                        return a.$.weight - b.$.weight;
                    });
                    return ret;
                }
            }

            return ret;

        }.onChange("shippingTypes", "basket.supportsEmailShipping"),

        possibleShippingTypes: function (addressType) {
            var possibleShippingTypes = this.$.possibleShippingTypes;

            if (!possibleShippingTypes) {
                return;
            }

            return _.filter(possibleShippingTypes, function (shippingType) {
                return (addressType === "PRIVATE") ||
                    ((addressType === "PACKSTATION" || addressType === "POSTFILIALE") && shippingType.$.supportsPackstation) ||
                    (addressType === "UPS_PICKUP" && shippingType.$.supportsUpsPickup);
            });

        }.onChange("possibleShippingTypes"),

        newsletterAvailable: function () {

            var stage = this.$stage;
            if (!stage) {
                return false;
            }

            var parameter = this.$stage.$parameter;
            return parameter.mode == "d2c" || parameter.businessUnit === "TEAMSHIRTS";
        }.on("postConstruct")

    });

});
