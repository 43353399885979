define('checkout/view/payment/CreditCardClass',[
    "checkout/view/payment/PaymentView",
    "checkout/manager/AdyenManager"
], function (PaymentView, AdyenManager) {
    return PaymentView.inherit("checkout.view.CreditCardClass", {

        defaults: {
            container: null
        },

        inject: {
            adyenManager: AdyenManager
        },

        _onDomAdded: function () {
            this.$.adyenManager.updateComponent(this.$.payment);
        },

        handleCreditCardCallback: function (component, action) {
            if (action.type === "threeDS2") {
                var callback = function () {
                    component.$.checkout.createFromAction(action).mount('#new-challenge-container');
                };

                this.showChallengeContainer(callback);
            } else {
                component.$.checkout.createFromAction(action).mount('#legacy-challenge-container');
            }
        },

        _renderContainer: function () {
            var self = this;

            var options = {
                positionHolderNameOnTop: true,
                hasHolderName: true,
                holderNameRequired: true,
                onFieldValid: function (event) {
                    event.endDigits && self.$.payment.set("endDigits", event.endDigits);
                },
                onBrand: function (event) {
                    event.brand && self.$.payment.set("brand", event.brand);
                }
            };

            this.$.adyenManager.initializeWebComponent(this.$.payment, {}, options, this.$.container.$el, null, this.handleCreditCardCallback.bind(this));
        },

        isSelectedBrand: function (brand) {
            return this.get("payment.brand") === brand;
        }.onChange("payment.brand")
    });
});
