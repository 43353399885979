define('checkout/manager/UserAddressManager',["js/core/Component", "sprd/entity/Address", "sprd/model/UserAddress", "flow", "js/error/ValidationError", "underscore"], function (Component, Address, UserAddress, flow, ValidationError, _) {

    var MAX_ADDRESSES = 20,
        ADDRESS_TYPE_BILLING = "billing",
        ADDRESS_TYPE_SHIPPING = "shipping";


    return Component.inherit("checkout.manager.UserAddressManager", {

        defaults: {
            user: null,
            delivery: null
        },

        /**
         * If the user is set to null (usually when he is logged out)
         * set back the delivery
         * @param user
         * @private
         */
        _commitUser: function (user) {
            var delivery = this.$.delivery;
            if (!user && delivery) {
                this._clearDeliveryAddress();
            }
        },

        _clearDeliveryAddress: function () {
            var delivery = this.$.delivery;
            if (delivery) {
                this.setUserAddressOnDelivery(new Address({
                    country: delivery.get('shipping.address.country'),
                    state: delivery.get('shipping.address.state')
                }), ADDRESS_TYPE_SHIPPING);

                this.setUserAddressOnDelivery(new Address({
                    country: delivery.get('shipping.address.country'),
                    state: delivery.get('shipping.address.state')
                }), ADDRESS_TYPE_BILLING);

                delivery.set('invoiceToShippingAddress', true);
            }
        },

        /**
         * Loads the user addresses and sets the best addresses for shipping and billing
         * on the delivery object
         *
         * @param callback
         */
        loadUserAddressesAndSetDeliveryAddress: function (callback) {
            var user = this.$.user,
                delivery = this.$.delivery;

            if (user) {

                var self = this;
                user.getCollection("addresses").fetchPage(0, {fullData: true}, function (err, addresses) {
                    if (!err && addresses && addresses.size() > 0) {
                        addresses.sort(function (a, b) {
                            return a.$.created > b.$.created ? -1 : 1;
                        });
                        var shippingAddressId = delivery.get('shipping.address.id'),
                            billingAddressId = delivery.invoiceAddress().get('id');

                        var shippingAddress = addresses.find(function (address) {
                            return shippingAddressId == address.$.id;
                        });
                        // if delivery shipping address is not in address book
                        if (!shippingAddress) {
                            shippingAddress = self.findShippingAddress(addresses, shippingAddressId);
                            // set always an address of the addressbook
                            // and also change the billing address then
                            self.setUserAddressOnDelivery(shippingAddress, ADDRESS_TYPE_SHIPPING);
                        }
                        var billingAddress = addresses.find(function (address) {
                            return billingAddressId == address.$.id;
                        });
                        // if delivery billing address is not in address book
                        if (!billingAddress) {
                            billingAddress = self.findBillingAddress(addresses, billingAddressId);

                            if (billingAddress !== shippingAddress) {
                                self.setUserAddressOnDelivery(billingAddress, ADDRESS_TYPE_BILLING);
                            } else {
                                delivery.set('invoiceToShippingAddress', true);
                            }
                        }
                    } else {
                        self._clearDeliveryAddress();
                    }
                    callback && callback(err);
                });
            } else {
                callback && callback();
            }

        },

        findShippingAddress: function (addresses, shippingAddressId) {
            if (addresses) {
                return addresses.find(function (address) {
                    return shippingAddressId == address.$.id || address.$.defaultShippingAddress;
                }) || addresses.at(0);
            }
        },

        findBillingAddress: function (addresses, billingAddressId) {
            if (addresses) {
                return addresses.find(function (address) {
                    return billingAddressId == address.$.id || address.$.defaultBillingAddress;
                }) || this.findShippingAddress(addresses, billingAddressId);
            }
        },
        /**
         * Converts an user address model to an address entity and
         * sets it on the delivery by the given type
         *
         * @param address - the address model
         * @param type - "billing" or "shipping"
         */
        setUserAddressOnDelivery: function (address, type) {
            var delivery = this.$.delivery;
            if (delivery) {
                var billingShipping = delivery.get(type);
                if (billingShipping) {
                    var nAddress;
                    if (address instanceof UserAddress) {
                        var attributes = _.clone(address.$);
                        nAddress = delivery.createEntity(Address, attributes.id || type);
                        attributes.id = attributes.id || type;
                        nAddress.set(attributes);
                        nAddress.set('addressModel', address);
                    } else {
                        nAddress = delivery.createEntity(Address, type);
                    }


                    // check for invoice to shipping address
                    var currentShippingAddress = this.get('delivery.shipping.address');

                    var isSameAsShipping = true;
                    if (type === ADDRESS_TYPE_BILLING) {
                        isSameAsShipping = nAddress.$.id == currentShippingAddress.$.id;
                    } else if(type === ADDRESS_TYPE_SHIPPING && !delivery.$.invoiceToShippingAddress){
                        var bAddress = delivery.invoiceAddress();
                        isSameAsShipping = nAddress === bAddress || nAddress.$.id == delivery.invoiceAddress().$.id;
                    }
                    delivery.set('invoiceToShippingAddress', isSameAsShipping);
                    billingShipping.set('address', nAddress);
                }
            }
        },

        /**
         * Removes the address from the user addresses and sets new addresses on the delivery if necessary
         * @param address
         * @param [waitForRemove]
         * @param [callback]
         */
        removeAddress: function (address, waitForRemove, callback) {
            if (address) {
                if (waitForRemove instanceof Function) {
                    callback = waitForRemove;
                    waitForRemove = false;
                }
                var delivery = this.$.delivery,
                    userAddresses = this.$.user.getCollection("addresses"),
                    self = this,
                    oldAddressId = address.$.id;

                flow()
                    .seq(function (cb) {
                        if (waitForRemove) {
                            address.remove(cb);
                        } else {
                            // optimistic deletion
                            address.remove();
                            cb();
                        }
                    })
                    .seq(function () {
                        userAddresses.set('$itemsCount', userAddresses.$.$itemsCount - 1);
                        userAddresses.remove(address);
                    })
                    .seq(function () {
                        if (userAddresses.size() > 0) {
                            if (delivery.get('shipping.address.id') == oldAddressId) {
                                var shippingAddress = self.findShippingAddress(userAddresses);
                                self.setUserAddressOnDelivery(shippingAddress, "shipping");
                            }
                            if (delivery.get('billing.address.id') == oldAddressId) {
                                var billingAddress = self.findBillingAddress(userAddresses);
                                self.setUserAddressOnDelivery(billingAddress, "billing");
                            }
                        } else {
                            self._clearDeliveryAddress();
                        }
                    })
                    .exec(callback);

            } else {
                callback && callback();
            }
        },

        /**
         * Saves the address as user address
         * If the user has already too many addresses, the oldest address is deleted
         *
         * @param editAddress
         * @param type
         * @param callback
         */
        saveAddress: function (editAddress, type, callback) {
            var self = this,
                user = this.$.user;

            callback = callback || function () {
            };

            if (editAddress instanceof UserAddress) {
                var isNew = editAddress.isNew();

                editAddress.validate();
                if (!editAddress.isValid()) {
                    callback(new ValidationError("Model is not valid!", "INVALID_MODEL", "", editAddress));
                    return;
                }

                flow()
                    .seq(function (cb) {
                        if (isNew) {
                            // if is new, check delete an address if user addresses are more than 20
                            flow()
                                .seq("userAddresses", function (cb) {
                                    var userAddresses = user.getCollection('addresses');
                                    userAddresses.fetch({fullData: true}, cb);
                                })
                                .seq(function (cb) {
                                    var shippingAddress = self.$.delivery.get('shipping.address'),
                                        billingAddress = self.$.delivery.invoiceAddress(),
                                        userAddresses = this.vars.userAddresses;

                                    if (userAddresses && userAddresses.size() >= MAX_ADDRESSES) {
                                        var oldestAddress;
                                        // find oldest address that is not the current billing or shipping address
                                        userAddresses.each(function (address) {
                                            if ((!oldestAddress || oldestAddress.$.created > address.$.created) && address.$.id !== shippingAddress.$.id && address.$.id !== billingAddress.$.id) {
                                                oldestAddress = address;
                                            }
                                        });

                                        if (oldestAddress) {
                                            self.removeAddress(oldestAddress, true, cb);
                                        } else {
                                            cb();
                                        }
                                    } else {
                                        cb();
                                    }
                                })
                                .seq(function () {
                                    this.vars.userAddresses.invalidatePageCache();
                                })
                                .exec(cb);
                        } else {
                            cb();
                        }
                    })
                    .seq(function (cb) {
                        editAddress.validateAndSave(null, cb);
                    })
                    .exec(function (err, results) {
                        if (!err) {
                            self.setUserAddressOnDelivery(editAddress, type);
                        }
                        callback(err, results);
                    })
            }
        },

        getSelectedAddress: function (type) {
            if (type == ADDRESS_TYPE_BILLING) {
                return this.$.delivery.invoiceAddress();
            } else {
                return this.get("delivery.shipping").get('address');
            }
        }.onChange('delivery.shipping.address', 'delivery.billing.address')
    });
});

