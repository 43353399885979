define('sprd/data/SprdAccountsDataSourceClass',["sprd/data/SprdDataSource"],
    function (SprdDataSource) {
        return SprdDataSource.inherit('sprd.data.SprdAccountsDataSourceClass', {
            defaults: {},

            getQueryParameters: function () {
                var isTeamshirts = this.PARAMETER().businessUnit === "TEAMSHIRTS"
                var context = isTeamshirts ? "checkout-teamshirts" : "checkout"

                if (!isTeamshirts && location.hostname.match(/\bmyspreadshop\b/)) {
                    context = 'checkout-myspreadshop';
                }

                return _.defaults({
                    context: context
                }, this.callBase());

            }
        });
    });
