define('checkout/view/payment/PaymentView',["checkout/view/ViewBase"], function (ViewBase) {
    return ViewBase.inherit("checkout.view.PaymentView", {

        defaults: {
            payment: null,
            selectedPaymentMethod: false
        },

        events: [
            "on:paymentComplete",
            "on:showChallengeContainer"
        ],

        completePayment: function () {
            this.trigger("on:paymentComplete");
        },

        showChallengeContainer: function (cb) {
            this.trigger("on:showChallengeContainer", cb);
        }

    });
});
