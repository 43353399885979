define('checkout/manager/GoogleManager',["js/core/Component", "require", "rAppid"], function(Component, require, rAppid) {

    return Component.inherit("checkout.manager.BrandingManager", {

        defaults: {
            apiKey: null
        },

        getLang: function() {
            var language = this.PARAMETER().language || "en";
            if (language.toLowerCase() === "us") {
                language = "en";
            }

            return language;
        },

        load: function(callback) {

            var callBackName = "__googleCallback" + this.$cid;

            this.synchronizeFunctionCall(function(callback) {

                var query = rAppid.createQueryString({
                    key: this.$.apiKey,
                    callback: callBackName,
                    language: this.getLang(),
                    libraries: "places"
                });

                window[callBackName] = function() {
                    delete window[callBackName];
                    callback(null, window.google);
                };

                var url = "https://maps.googleapis.com/maps/api/js?" + query;

                require([url], function() {
                }, callback);

            }, "google", callback, this);

        }

    });
});
