define('checkout/model/CheckoutOrder',["sprd/model/Order", "js/data/Entity", "sprd/model/Checkout", "sprd/model/Currency", "js/data/Collection", "sprd/model/PaymentType", "sprd/entity/Price", "sprd/model/ShippingOption", "sprd/model/Coupon"], function (Order, Entity, Checkout, Currency, Collection, PaymentType, Price, ShippingOption, Coupon) {

    var SingleDiscount = Entity.inherit("sprd.model.Order.SingleDiscount", {
        schema: {
            price: Price
        }
    });

    var Discounts = Entity.inherit("sprd.model.Order.Discounts", {
        schema: {
            couponGoodsGross: SingleDiscount,
            couponShippingReduction: SingleDiscount,
            volumeDiscount: SingleDiscount
        }
    });

    return Order.inherit("sprd.model.Order", {

        defaults: {
            checkout: Checkout
        },

        schema: {
            coupons: Collection.of(Coupon),
            checkout: Checkout,
            currency: Currency,
            paymentTypes: Collection.of(PaymentType),
            discounts: Discounts,
            handlingGross: Price,
            shippingOptions: [ShippingOption],
            supportsEmailShipping: Boolean
        },

        getHandlingGross: function() {
            var giftOption = this.getShippingOption('gift');
            return giftOption ? giftOption.$.price : null;
        }.onChange("shippingOptions", "shippingOptions.size()"),

        getShippingOption: function(type) {

            if (this.$.shippingOptions) {
                return this.$.shippingOptions.find(function(sOption) {
                    return sOption.$.type == type;
                });
            }

            return null;
        }.onChange("shippingOptions", "shippingOptions.size()"),

        /**
         * Same method as in sprd.model.Basket but returns just null to prevent binding warning
         * @returns {null}
         */
        getDiscountPrice: function(){
            return null;
        },

        getGoodsDiscountPrice: function () {
            return this.get('discounts.couponGoodsGross.price');
        }.onChange('discounts'),

        getShippingDiscountPrice: function () {
            return this.get('discounts.couponShippingReduction.price');
        }.onChange('discounts'),

        discount: function (type) {
            return this.get('discounts.volumeDiscount.price.' + type || "vatIncluded")
        }.onChange('discounts'),

        getTransactionId: function(){
            var transactions = this.get('transactions');
            if(transactions && transactions.length){
                return transactions[0].id;
            }
            return null;
        }
    });

});
