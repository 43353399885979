define('checkout/view/MobileZoomClass',["checkout/dialog/DialogBaseClass", "js/core/List", "underscore", "checkout/lib/Siema"], function (DialogBase, List, _, Siema) {
    return DialogBase.inherit({
        defaults: {
            name: "mobile-zoom",

            basketItems: List,
            selectedItem: null,
            productImages: "{getProductImages()}",
            showComposition: false,

            siema: null,
            currentSlideIndex: null
        },

        _initializationComplete: function () {
            this.callBase();

            this.$.basketItems.each(function (basketItem) {
                var product = basketItem.get("element.getProduct()");

                product.$.configurations.each(function (configuration) {

                    if (configuration.$.type === "design") {
                        var designs = configuration.$.designs;

                        designs.each(function (design) {
                            design.fetch()
                        });
                    }

                    configuration.$.printType.fetch();
                });
            });
        },

        _onDomAdded: function () {
            this.callBase();

            var self = this,
                selectedItem = this.$.selectedItem,
                productImages = this.$.productImages;

            var productImage = productImages.find(function (productImage) {
                var defaultViewId = selectedItem.get("element.getProduct().defaultValues.defaultView.id");
                return selectedItem.$.id === productImage.id && productImage.view.$.id === defaultViewId;
            });

            var startIndex = productImages.indexOf(productImage);


            this.set("currentSlideIndex", startIndex || 0);

            var siema = new Siema({
                selector: '.product-image-list',
                duration: 200,
                easing: 'ease-out',
                perPage: 1,
                startIndex: startIndex,
                draggable: true,
                multipleDrag: true,
                threshold: 20,
                loop: false,
                rtl: false,
                onChange: function () {
                    self.set("currentSlideIndex", siema.currentSlide)
                }
            });

            this.set("siema", siema);
        },

        getProductImages: function () {
            var self = this,
                productImages = new List();

            this.$.basketItems.each(function (basketItem) {
                var basketItemId = basketItem.get("id"),
                    appearance = basketItem.get("element.appearance"),
                    product = basketItem.get("element.getProduct()"),
                    size = basketItem.get("element.size"),
                    productType = product.$.productType,
                    configurations = product.$.configurations;

                var views = _.map(configurations ? configurations.$items : [], function (configuration) {
                    return self.getViewByPrintAreaId(productType.$.views, configuration.$.printArea.id);
                });

                if (!views || !views.length) {
                    var defaultViewId = product.get("defaultValues.defaultView.id");
                    var defaultView = productType.$.views.find(function (view) {
                        return view.$.id === defaultViewId;
                    });
                    views = [defaultView];
                }

                views = _.uniq(views, function (view) {
                    return view.$.id
                });

                _.each(views, function (view) {
                    productImages.push({
                        id: basketItemId,
                        appearance: appearance,
                        product: product,
                        size: size,
                        view: view
                    });
                });
            });

            return productImages;
        }.onChange("basketItems"),


        getViewByPrintAreaId: function (views, printAreaId) {
            return _.find(views.$items, function (view) {
                return _.some(view.$.viewMaps.$items, function(viewMap) {
                    return viewMap.$.printArea.$.id === printAreaId;
                });
            })
        },

        isProductImageVisible: function (index) {
            if (this._isImageVisible(index, 0)) {
                return true;
            }

            return !this.$.showComposition && this._isImageVisible(index);
        }.onChange("currentSlideIndex", "basketItems"),

        isCompositionImageVisible: function (index) {
            if (this._isImageVisible(index, 0)) {
                return true;
            }
            return this.$.showComposition && this._isImageVisible(index);
        }.onChange("currentSlideIndex", "basketItems"),

        _isImageVisible: function (index, threshold) {
            threshold = isNaN(threshold) ? 1 : threshold;

            var currentSlideIndex = this.$.currentSlideIndex;
            return index === currentSlideIndex || index - threshold === currentSlideIndex || index + threshold === currentSlideIndex;
        }.onChange("currentSlideIndex", "basketItems"),

        toggleImageType: function () {
            this.set("showComposition", !this.$.showComposition);
        },

        prev: function () {
            var siema = this.$.siema;
            siema && siema.prev();
        },

        hasPrev: function () {
            return this.$.currentSlideIndex !== 0;
        }.onChange("currentSlideIndex"),

        next: function () {
            var siema = this.$.siema;
            siema && siema.next();
        },

        hasNext: function () {
            var productImages = this.$.productImages;
            if (!productImages || productImages.size() <= 1) {
                return false
            }

            return this.$.currentSlideIndex < productImages.size() - 1
        }.onChange("currentSlideIndex", "productImages")
    })
});
