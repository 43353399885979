define('sprd/model/PaymentType',["sprd/data/SprdModel", "js/data/Entity"], function (SprdModel, Entity) {
    var PaymentProviderData = SprdModel.inherit("sprd.model.PaymentType.PaymentProviderData", {
        defaults: {},
        schema: {
            type: String,
            paymentMethod: Entity
        }
    })

    var PaymentType = SprdModel.inherit("sprd.model.PaymentType", {

        defaults: {},

        schema: {
            weight: Number,
            methodCode: String,
            categoryCode: String,
            grossLowerLimit: Number,
            grossUpperLimit: Number,
            banks: [Entity],
            paymentProviderData: PaymentProviderData
        }

    });

    PaymentType.PaymentProviderData = PaymentProviderData

    return PaymentType;
});
