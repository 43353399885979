define('checkout/manager/FeatureManager',['sprd/manager/FeatureManager', 'underscore'], function(FeatureManager, _) {

    return FeatureManager.inherit('checkout.manager.FeatureManager', {

        defaults: {
            checkoutBuyDelay: 250,
            scrollTime: 500,

            addressAutoCompletion: "google",
            geoLocate: false,

            enableRequestPaymentApi: false,
            showFreeShippingInfo: true,

            enableBamBam: false,

            highContrast: false,

            featureA38: false
        },

        featureSets: {
            d2c: {
                enableBamBam: true
            }
        },

        checkFeatureA38: function() {
            if (!this.$stage.$browser.isMobile) {
                var body = document.querySelector("body");
                if (body) {
                    var zeroMovement = true;
                    var mouseEventCounter = 0;
                    var self = this;
                    var handler = function (event) {
                        zeroMovement = zeroMovement && (event.movementX === 0 && event.movementY === 0);

                        // Analyze 50 mouse events until give result
                        if (mouseEventCounter > 50) {
                            body.removeEventListener("mousemove", handler);

                            if (!zeroMovement) {
                                self.set('featureA38', true);
                            }
                        }

                        mouseEventCounter++;
                    }

                    body.addEventListener("mousemove", handler);
                }
            }
        },

        _initializationComplete: function() {
            var addressAutoCompletion = this.PARAMETER().addressAutoCompletion;
            if(addressAutoCompletion) {
                this.set({addressAutoCompletion: addressAutoCompletion});
            }

            this.checkFeatureA38();

            this.callBase();

            if (!this.PARAMETER().enableTracking) {
                this.set({addressAutoCompletion: null});
            }

            var featureManager = window.featureManager;
            if (_.isObject(featureManager)) {
                this.set(featureManager);
            }
        }
    });
});
