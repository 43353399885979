define('checkout/manager/WindowManager',["js/core/Component"], function (Component) {

    return Component.inherit("checkout.manager.WindowManager", {

        ctor: function () {
            this.$windowCache = {};
            this.callBase();
        },

        showWindow: function (windowClass, parameter, callback) {
            var self = this;

            this.loadWindow(windowClass, parameter, showWindow);

            function showWindow(err, window, callback) {

                if (!err) {
                    self._closeAllModalWindows();

                    window.set(parameter);
                    window.showModal(callback);
                } else {
                    callback && callback(err, window);
                }

            }
        },

        loadWindow: function (windowClass, parameter, callback) {
            var self = this;

            if (!this.$windowCache[windowClass]) {
                require([windowClass], function (componentFactory) {
                    var window = self.createComponent(componentFactory, parameter);
                    window._initialize();

                    self.$windowCache[windowClass] = window;

                    callback && callback(null, window);

                }, function (err) {
                    callback && callback(err);
                });
            } else {
                callback && callback(null, this.$windowCache[windowClass]);
            }
        },

        _closeAllModalWindows: function () {
            var windowManager = this.$stage.$windowManager,
                length = windowManager.$modalWindows.length;

            for (var i = length - 1; i >= 0; i--) {
                windowManager.$modalWindows[i].close();
            }
        }

    });
});

