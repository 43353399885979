define('checkout/view/GiftWrappingViewClass',["checkout/view/RootViewBase"], function(RootViewBase) {

    return RootViewBase.inherit({

        defaults: {
            _giftCosts: "{root.giftWrappingShippingOption.getCostsByShippingCountryCode(delivery.shipping.address.country.code)}",
            visible: "{visible()}",
            componentClass: "gift",
            giftMessageLength: 250
        },

        visible: function() {
            var basket = this.$.basket;

            return this.$._giftCosts && basket && basket.supportsGiftWrapping();
        }.onChange("_giftCosts", "basket.supportsGiftWrapping()"),


        charactersLeftMessage: function() {
            var giftMessageCharactersLeft = this.$.giftMessageLength - (this.get("delivery.giftWrappingMessage") || "").length;
            var i18n = this.$.i18n;

            if (giftMessageCharactersLeft == 0) {
                return i18n.t('gift.noCharactersLeft', giftMessageCharactersLeft)
            } else {
                return i18n.t('gift.charactersLeft', giftMessageCharactersLeft)
            }
        }.onChange("delivery.giftWrappingMessage")

    });


});
